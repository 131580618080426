import { formatCurrency } from "../../../../helpers/utils";
import { actions } from "../../../../helpers/constants";

export default function generateQueuedActions(
  canUserCheck,
  callAmount,
  isInCents
) {
  let queuedActions = [
    {
      actionType: actions.CALL,
      betValue: callAmount,
      keyboardChar: "C",
      displayName: `Call ${formatCurrency(callAmount, isInCents)}`,
      hideIfUserCanCheck: true,
    },
    {
      actionType: actions.CHECKFOLD,
      betValue: -1,
      keyboardChar: "X",
      displayName: "Check/Fold",
      showIfUserCanCheck: true,
    },
    {
      actionType: actions.CHECK,
      betValue: -1,
      keyboardChar: "C",
      displayName: "Check",
      showIfUserCanCheck: true,
    },
    {
      actionType: actions.FOLD,
      betValue: -1,
      keyboardChar: "F",
      displayName: "Fold",
    },
  ];

  return queuedActions.filter((queuedAction) => {
    if (canUserCheck) return !queuedAction.hideIfUserCanCheck;
    else return !queuedAction.showIfUserCanCheck;
  });
}
