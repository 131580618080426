import React, { useState, Fragment } from "react";
import { PrimaryButton, InputText } from "../../../shared/FormElements";
import { SmallHeaderText } from "../../../shared/Text";
import { colors } from "../../../helpers/styles";
import { buyInConfig } from "../../../helpers/config";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../../helpers/constants";
import styled from "styled-components";
import { formatCurrency } from "../../../helpers/utils";

const BuyInActions = ({ smallBlindCents, emitSocketMessage, isInCents }) => {
  const [buyInValueDollars, setBuyInValueDollars] = useState("");
  const [actionDone, setActionDone] = useState(false);
  const [showInvalidBuyIn, setShowInvalidBuyIn] = useState(false);

  const onEnterPressedForBuyIn = (event) => {
    if (!isInCents && !/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
    var code = event.keyCode || event.which;
    if (code === 13) {
      onBuyInClick();
    }
  };

  const onBuyInChange = (e) => {
    setBuyInValueDollars(e.target.value);
  };

  const sendSocketBuyIn = (buyInCents) => {
    emitSocketMessage("request_buy_in", { amount: parseInt(buyInCents) });
    setBuyInValueDollars("");
    setActionDone(true);
  };

  const onBuyInClick = (e) => {
    let buyInCents = parseFloat(parseFloat(buyInValueDollars).toFixed(2));
    if (isInCents) {
      buyInCents *= 100;
    }
    if (isNaN(buyInCents)) {
      buyInCents = 0;
    }

    const bigBlindCents = smallBlindCents * 2;
    if (parseInt(buyInCents) === 0) {
      buyInCents = bigBlindCents * buyInConfig.DEFAULT_BB;
    }
    if (
      (buyInCents >= _getLowerBound() && buyInCents <= _getUpperBound()) ||
      showInvalidBuyIn
    ) {
      sendSocketBuyIn(buyInCents);
    } else {
      setShowInvalidBuyIn(true);
    }
  };

  const _getUpperBound = (inDollars) => {
    const bigBlindCents = smallBlindCents * 2;
    return bigBlindCents * buyInConfig.UPPER_BB;
  };

  const _getLowerBound = (inDollars) => {
    const bigBlindCents = smallBlindCents * 2;
    return bigBlindCents * buyInConfig.LOWER_BB;
  };

  if (actionDone) {
    return <Fragment />;
  }
  return (
    <BuyInWrapper>
      <InstructionsText>
        {showInvalidBuyIn ? (
          `Typical buy in range is between (${formatCurrency(
            _getLowerBound(isInCents),
            isInCents
          )}, ${formatCurrency(
            _getUpperBound(isInCents),
            isInCents
          )}). Are you sure?`
        ) : (
          <>
            <span>Tap &quot;B&quot; on your keyboard</span>
            <br />
            <span>to buy in</span>
          </>
        )}
      </InstructionsText>
      <BuyInTextField
        noBack
        placeholder={formatCurrency(smallBlindCents * 200, isInCents)}
        type="number"
        data-testid="buyin-field"
        min={formatCurrency(smallBlindCents * 2 * 50, isInCents)}
        max={formatCurrency(smallBlindCents * 2 * 300, isInCents)}
        onChange={onBuyInChange}
        value={buyInValueDollars}
        onKeyPress={onEnterPressedForBuyIn}
      />
      <PrimaryButton
        secondaryColor
        dataTestId="buyin-button"
        onClick={onBuyInClick}
        keyboardChar={showInvalidBuyIn ? null : "B"}
      >
        Buy In
      </PrimaryButton>
      {showInvalidBuyIn && (
        <PrimaryButton
          secondaryColor
          keyboardChar="B"
          onClick={() => setShowInvalidBuyIn(false)}
        >
          Back
        </PrimaryButton>
      )}
    </BuyInWrapper>
  );
};

const BuyInWrapper = styled.div`
  display: flex;
`;

const BuyInTextField = styled(InputText)`
  width: 100px;
  height: 60px;
  font-weight: bold;
  text-align: center;
  margin-right: 5px;

  &::placeholder {
    color: ${colors.grey};
  }
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    height: 40px;
  }
`;

const InstructionsText = styled(SmallHeaderText)`
  position: absolute;
  top: -100%;
  text-align: right;
  color: ${colors.grey};
  padding-right: 10px;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    display: none;
  }
`;

export default BuyInActions;
