import React, { useContext, useState } from "react";
import styled from "styled-components";
import { formatCurrency } from "../../../helpers/utils";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../../helpers/constants";
import { colors } from "../../../helpers/styles";
import { GameContext } from "../../../context/GameContext";
import { MediumBodyText } from "../../../shared/Text";
import { useStateWithLocalStorageString } from "../../../hooks/useStateWithLocalStorage";

const CentralPot = () => {
  const { mainPotCents, betsOnTableSumCents, isInCents } = useContext(
    GameContext
  );
  let [potHovered, setPotHovered] = useState(false);
  let [justSwitchedPot, setJustSwitchedPot] = useState(false);
  const [potType, setPotType] = useStateWithLocalStorageString(
    "centralPotType",
    "defaultPot"
  );

  const potOptions = {
    defaultPot: {
      value: formatCurrency(mainPotCents, isInCents)
        ? formatCurrency(mainPotCents, isInCents)
        : 0,
      hoverText: "Switch to Total Pot",
      caption: "Pot: ",
      switchTo: "totalPot",
    },
    totalPot: {
      value: formatCurrency(mainPotCents + betsOnTableSumCents, isInCents)
        ? formatCurrency(mainPotCents + betsOnTableSumCents, isInCents)
        : 0,
      hoverText: "Switch to Pot",
      caption: "Total Pot: ",
      switchTo: "defaultPot",
    },
  };

  const switchPot = () => {
    setJustSwitchedPot(true);
    setPotType(potOptions[potType].switchTo);
  };

  let allPots = (
    <MainPot
      potType={potType}
      onClick={switchPot}
      onMouseEnter={() => setPotHovered(true)}
      onMouseLeave={() => {
        setPotHovered(false);
        setJustSwitchedPot(false);
      }}
    >
      {!potHovered
        ? `${potOptions[potType].caption}${potOptions[potType].value}`
        : !justSwitchedPot
        ? `${potOptions[potType].hoverText}`
        : `${potOptions[potType].caption}${potOptions[potType].value}`}
    </MainPot>
  );
  return <> {allPots} </>;
};

const MainPot = styled(MediumBodyText)`
  position: relative;
  cursor: pointer;
  color: white;
  cursor: pointer;
  width: 150px;
  height: 30px;
  font-size: 18px;
  background: ${({ theme }) => theme.primary};
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  opacity: 1;
  z-index: -1;
  transform: rotateX(0deg);
  ${(props) =>
    props.potType === "totalPot" &&
    `
    transform: rotateX(360deg);
  `}

  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    font-size: 0.7em;
    height: 18px;
    width: 100px;
  }
`;

// const InvalidPot = styled(MainPot)`
//   background: #aaaaaa;
//   color: #555555;
// `;

// const PotToggleExplanation = styled(MainPot)`
//   // color: ${colors.white};
//   position: absolute;
//   cursor: pointer;
//   // transform: translate(0px, -1.5em);
//   transform: rotateX(180deg);
//   ${(props) =>
//     props.activated
//       ? `opacity: 1;
//         transform: rotateX(0deg);`
//       : `opacity: 0;`}
//   transition: all 300ms ease-in-out;
//   -moz-transition: all 300ms ease-in-out;
//   -webkit-transition: all 300ms ease-in-out;
//   z-index: -1;
// `;

export default CentralPot;
