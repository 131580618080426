import React, { useRef, useEffect, useState } from "react";
import { SmallBodyText } from "../../shared/Text";
import Linkify from "react-linkify";
import styled from "styled-components";

const linkDecorator = (
  href,
  text,
  key // for links in chat in new tab
) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

const ChatMain = ({ chats, updateScroll }) => {
  const chatMessageContainerRef = useRef(null);
  const [isScrollBottom, setIsScrollBottom] = useState(true);

  useEffect(() => {
    if (isScrollBottom) {
      scrollToBottom();
    }
  }, [updateScroll, chats]);

  const scrollToBottom = () => {
    if (chatMessageContainerRef.current) {
      chatMessageContainerRef.current.scrollTop =
        chatMessageContainerRef.current.scrollHeight;
    }
  };

  const onScroll = () => {
    setIsScrollBottom(
      chatMessageContainerRef.current.scrollTop +
        chatMessageContainerRef.current.clientHeight ===
        chatMessageContainerRef.current.scrollHeight
    );
  };

  return (
    <ChatMessageContainer
      onScroll={onScroll}
      ref={chatMessageContainerRef}
      id="chat-msg-container"
    >
      {chats.map((chat, i) => (
        <ChatMessage
          key={i}
          align="left"
          isGameMsg={!chat.username}
          id={`chat-${i}`}
        >
          <strong>{chat.username ? `${chat.username}: ` : ""}</strong>
          <Linkify componentDecorator={linkDecorator}>{chat.message}</Linkify>
        </ChatMessage>
      ))}
    </ChatMessageContainer>
  );
};

const ChatMessageContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-wrap: break-word;
  flex: auto;
  color: ${({ theme }) => theme.text.mainBody};
`;

const ChatMessage = styled(SmallBodyText)`
  padding: 0 20px;
  margin: 8px 0;
  display: block;
  ${(props) =>
    props.isGameMsg &&
    props.theme &&
    `
    color: ${props.theme.chat.gameText};
    font-weight: bold;
  `}
  a, a:hover {
    color: ${({ theme }) => theme.chat.linkText};
    text-decoration: underline;
  }
`;

export default ChatMain;
