import React, { Fragment } from "react";
import styled from "styled-components";
import KeyboardChar from "../Button/KeyboardChar";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../../helpers/constants";
import { colors } from "../../../helpers/styles";

const Slider = ({
  onChange,
  value,
  smallBlindCents,
  min,
  max,
  style,
  setValue,
  betFocus,
  potFocus,
}) => {
  const smallStepCents = smallBlindCents ? smallBlindCents / 100.0 : 0.01;
  const bigStepCents = smallBlindCents
    ? parseFloat((smallBlindCents * 2).toFixed(2))
    : 5;

  const increment = () => {
    if (betFocus || potFocus) {
      return;
    }
    let newVal = parseFloat(value) + bigStepCents;
    if (newVal > max) {
      newVal = max;
    }
    setValue(newVal.toFixed(2));
  };

  const decrement = () => {
    if (betFocus || potFocus) {
      return;
    }
    let newVal = parseFloat(value) - bigStepCents;
    if (newVal < min) {
      newVal = min;
    }
    setValue(newVal.toFixed(2));
  };

  if (min >= max) {
    return <Fragment />;
  }

  let validatedValue = value;
  if (validatedValue > max) {
    validatedValue = max;
  } else if (validatedValue < min || !validatedValue) {
    validatedValue = min;
  }

  return (
    <InputWrapper>
      <KeyboardChar
        char="-"
        color={colors.grey}
        style={{ marginRight: "5px" }}
        onClick={decrement}
      />
      <KeyboardChar
        char="<LEFT>"
        color={colors.grey}
        style={{ display: "none" }}
        onClick={decrement}
      />
      <KeyboardChar
        char="<DOWN>"
        color={colors.grey}
        style={{ display: "none" }}
        onClick={decrement}
      />
      <RangeWrapper>
        <InputRange
          onChange={onChange}
          type="range"
          min={min}
          max={max}
          step={smallStepCents}
          value={validatedValue}
          list="bets"
          style={{ ...style, cursor: "pointer" }}
        />
        <InputLineColor
          style={{
            width: `${
              validatedValue && max - min
                ? ((validatedValue - min) / (max - min)) * 100
                : 0
            }%`,
          }}
        />
      </RangeWrapper>
      <KeyboardChar
        char="+"
        color={colors.grey}
        style={{ marginLeft: "5px" }}
        onClick={increment}
      />
      <KeyboardChar
        char="<UP>"
        color={colors.grey}
        style={{ display: "none" }}
        onClick={increment}
      />
      <KeyboardChar
        char="<RIGHT>"
        color={colors.grey}
        style={{ display: "none" }}
        onClick={increment}
      />
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  position: relative;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    width: 40vw;
    margin-right: 20px;
  }
`;

const RangeWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const InputRange = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background: ${colors.white};
  outline: none;
  border-radius: 5px;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    background: ${({ theme }) => theme.primary};
    height: 20px;
    width: 15px;
    border-radius: 5px;
  }

  ${(props) =>
    props.disable &&
    `
    pointer-events: none;
    background: ${colors.grey};
  `};
`;

const InputLineColor = styled.div`
  position: absolute;
  bottom: 7px;
  width: 100%;
  height: 10px;
  background: ${({ theme }) => theme.primary};
  pointer-events: none;
  border-radius: 5px 0 0 5px;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    bottom: 8px;
  }
`;

export default Slider;
