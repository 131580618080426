export const optionsConfig = {
  interfaceConfigOverwrite: {
    SHOW_JITSI_WATERMARK: false,
    SHOW_WATERMARK_FOR_GUESTS: false,
    SHOW_CHROME_EXTENSION_BANNER: false,
    VERTICAL_FILMSTRIP: true,
    SETTINGS_SECTIONS: ["devices"],
    filmStripOnly: false,
    FILM_STRIP_MAX_HEIGHT: 80,
    DEFAULT_BACKGROUND: "transparent",
    JITSI_WATERMARK_LINK: "https://lipoker.io",
    MOBILE_APP_PROMO: false,
    DISABLE_FOCUS_INDICATOR: true,
    DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
    DISPLAY_WELCOME_PAGE_CONTENT: false,
    TILE_VIEW_MAX_COLUMNS: 1,
    DEFAULT_REMOTE_DISPLAY_NAME: "Poker Player",
    DISABLE_VIDEO_BACKGROUND: true, // reduces CPU by 25%: https://community.jitsi.org/t/high-cpu-utilization-on-client-end/25764/25
    VIDEO_QUALITY_LABEL_DISABLED: true,
    CONNECTION_INDICATOR_DISABLED: true,
    HIDE_KICK_BUTTON_FOR_GUESTS: true,
    HIDE_INVITE_MORE_HEADER: true, // their "Invite" button doesn't fit screen/has bad UI
  },
  configOverwrite: {
    enableNoisyMicDetection: false,
    enableTalkWhileMuted: false,
    enableNoAudioDetection: false,
    enableWelcomePage: false,
    prejoinPageEnabled: false,
    resolution: 480,
    startWithVideoMuted: true,
    startWithAudioMuted: true,
    disableInviteFunctions: true,
    toolbarButtons: ["microphone", "camera", "tileview"],
  },
};
