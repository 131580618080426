import styled from "styled-components";

export const ThemeCircle = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  ${(props) =>
    props.theme &&
    `
    background-image: linear-gradient(to bottom right, ${props.theme.primary}, ${props.theme.background}, ${props.theme.tertiary});
  `}
  ${(props) =>
    props.color1 &&
    props.color2 &&
    props.color3 &&
    `
    background-image: linear-gradient(to bottom right, ${props.color1}, ${props.color2}, ${props.color3});
  `}
`;

export const dropdownPositioning = {
  position: "absolute",
  zIndex: 3,
  top: "70px",
  left: "20px",
};
export const mobileDropdownPositioning = {
  position: "absolute",
  zIndex: 3,
  top: "60px",
  left: "10px",
};
