import React from "react";
import { SmallHeaderText } from "../Text";
import { colors, font } from "../../helpers/styles"; // ${font}
import { Icon } from ".";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../helpers/constants";
import styled from "styled-components";

const CheckboxField = ({ onClick, isOn, text, style, dataTestId }) => {
  return (
    <GameMessageToggle
      onClick={onClick}
      disable={!isOn}
      style={style}
      data-testid={dataTestId}
    >
      <Checkbox>
        <Icon
          icon={isOn ? faCheckSquare : faSquare}
          color={isOn ? ({ theme }) => theme.primary : colors.white}
          isLeft
        />
      </Checkbox>
      <SmallHeaderText style={{ marginTop: "5px" }}>{text}</SmallHeaderText>
    </GameMessageToggle>
  );
};

const Checkbox = styled.div`
  background: white;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const GameMessageToggle = styled.div`
  background: white;
  border-radius: 5px;
  font-family: ${font.header};
  font-size: 16px;
  padding: 8px;
  background: none;
  letter-spacing: 1px;
  color: ${colors.white};
  cursor: pointer;
  display: flex;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    padding: 4px;
  }
`;

export default CheckboxField;
