import React, { useState, createContext } from "react";
import {
  useStateWithLocalStorageString,
  useStateWithLocalStorageBoolean,
} from "../hooks/useStateWithLocalStorage";
import { themes } from "../helpers/constants";
import { logError } from "../helpers/logger";

// Browser Specific Context
// E.g sound settings, darkmode, and anything else that would be stored in local storage
// Manages browser specific actions like playing sounds, and in the future maybe WebRTC
export const BrowserContext = createContext();

export const BrowserProvider = ({ children }) => {
  // user preferences in local storage
  const [sound, setSound] = useStateWithLocalStorageBoolean("sound", true);
  const [theme, setTheme] = useStateWithLocalStorageString(
    "theme",
    themes.THEME_LIGHT_DEFAULT
  );
  const [showVideoChat, setShowVideoChat] = useStateWithLocalStorageBoolean(
    "showVideoChat",
    true
  );
  const [videoIsOnRight, setVideoIsOnRight] = useStateWithLocalStorageBoolean(
    "videoIsOnRight",
    true
  );
  const [isFourColorDeck, setIsFourColorDeck] = useStateWithLocalStorageBoolean(
    "isFourColorDeck",
    false
  );
  const [
    betButtonsOnRight,
    setBetButtonsOnRight,
  ] = useStateWithLocalStorageBoolean("betButtonsOnRight", true);

  const [isMobile, setIsMobile] = useState(true);
  const [allowKeyboardShortcuts, setAllowKeyboardShortcuts] = useState(false);
  const [showChat, setShowChat] = useState(true);
  const [audioToSoundMap, setAudioToSoundMap] = useState({});
  const [joinCall, setJoinCall] = useState(false);
  const [currentModal, setCurrentModal] = useState(null);

  // TODO: once all modals migrated to use ModalConductor,
  // existsModal won't need to exist because of currentModal
  const [existsModal, setExistsModal] = useState(true);

  const playSound = (soundName) => {
    if (sound) {
      let audio = audioToSoundMap[soundName];
      if (audio) {
        audio.play().catch((error) => {
          logError(error);
          // Browser stopped the audio by default
          // Reflect in the user settings that audio is disabled
          setSound(false);
        });
      }
    }
  };

  const playSoundDelay = (soundName, delay) => {
    setTimeout(playSound, delay, soundName);
  };

  const value = {
    sound,
    setSound,
    playSound,
    playSoundDelay,
    isMobile,
    setIsMobile,
    allowKeyboardShortcuts,
    setAllowKeyboardShortcuts,
    showChat,
    setShowChat,
    existsModal,
    setExistsModal,
    audioToSoundMap,
    setAudioToSoundMap,
    showVideoChat,
    setShowVideoChat,
    videoIsOnRight,
    setVideoIsOnRight,
    joinCall,
    setJoinCall,
    betButtonsOnRight,
    setBetButtonsOnRight,
    currentModal,
    setCurrentModal,
    theme,
    setTheme,
    isFourColorDeck,
    setIsFourColorDeck,
  };

  return (
    <BrowserContext.Provider value={value}>{children}</BrowserContext.Provider>
  );
};

export const BrowserConsumer = BrowserContext.Consumer;
