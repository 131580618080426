import React from "react";
import { font, colors } from "../../helpers/styles";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../helpers/constants";
import styled from "styled-components";

const CardBack = ({ style }) => {
  return (
    <CardBackContent style={style}>
      <LogoWrapper style={{ marginTop: "-10px" }}>
        <span>LP</span>
      </LogoWrapper>
    </CardBackContent>
  );
};

const CardBackContent = styled.div`
  background: ${({ theme }) => theme.tertiary};
  border: 5px solid ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    border-width: 3px;
  }
`;

const LogoWrapper = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${font.header};
  font-size: 12px;
  color: ${colors.white};
  span {
    margin: 2px 0 0 1px;
    letter-spacing: 0.6px;
  }
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    border: none;
  }
`;

export default CardBack;
