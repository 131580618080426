import React, { useState, useEffect } from "react";
import Modal, { transitionTime } from "../../../shared/Modal";
import BlindStructureScroll from "../../../shared/BlindStructureScroll";

const BlindStructureModal = ({
  show,
  hideModal,
  initialBlindCents,
  blindIncreaseMinutes,
}) => {
  const [startTransition, setStartTransition] = useState(true);

  useEffect(() => {
    // if showing again, reset
    if (show) {
      setStartTransition(false);
    }
  }, [show]);

  const close = () => {
    setStartTransition(true);
    setTimeout(() => {
      hideModal(false);
    }, transitionTime);
  };

  return (
    <Modal
      title="Blind Structure"
      show={true}
      onOverlayClick={close}
      isInvalid={false}
      transitionOut={startTransition}
    >
      <BlindStructureScroll
        initialBlindCents={initialBlindCents}
        blindIncreaseMinutes={blindIncreaseMinutes}
        showTitle={false}
        showCurrentBlinds={true}
      />
    </Modal>
  );
};

export default BlindStructureModal;
