import React, { useState, useContext, Fragment } from "react";
import styled from "styled-components";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../helpers/constants";
import { colors } from "../../helpers/styles";
import { GameContext } from "../../context/GameContext";
import { BrowserContext } from "../../context/BrowserContext";
import { Icon } from "../../shared/FormElements";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { CHAT_STATE } from "./utils";
import ChatTopBar from "./ChatTopBar";
import ChatMain from "./ChatMain";
import ChatInput from "./ChatInput";

const Chat = ({ gameId, MTTId, isMTTLobby }) => {
  const { username, sessionKey, chats } = useContext(GameContext);
  const {
    setAllowKeyboardShortcuts,
    showChat,
    existsModal,
    isMobile,
    betButtonsOnRight,
  } = useContext(BrowserContext);

  const [chatIsFocused, setChatIsFocused] = useState(false);
  const [chatState, _setChatState] = useState(CHAT_STATE.NORMAL);
  const [showGameLog, setShowGameLog] = useState(true);
  const [updateScroll, setUpdateScroll] = useState(false);
  const [numReadMessages, setNumReadMessages] = useState(0);
  const [numReadPlayerMessages, setNumReadPlayerMessages] = useState(0);

  const getPlayerChats = () => {
    return chats.filter((chat) => !!chat.username);
  };

  const setChatState = (newState) => {
    if (newState === CHAT_STATE.COLLAPSED) {
      setNumReadMessages(chats.length);
      setNumReadPlayerMessages(getPlayerChats().length);
    }
    _setChatState(newState);
    setTimeout(() => {
      setUpdateScroll(!updateScroll);
    }, 400); // animation is 0.3s, so scroll after animation done
  };

  const toggleShowGameLog = () => {
    setShowGameLog(!showGameLog);
    setUpdateScroll(!updateScroll);
  };

  if (!showChat) {
    return <Fragment />;
  }

  if (
    isMobile &&
    (chatState === CHAT_STATE.NORMAL || chatState === CHAT_STATE.COLLAPSED)
  ) {
    return (
      <ChatButton onClick={() => setChatState(CHAT_STATE.EXPANDED)}>
        <Icon icon={faComment} color={colors.white} />
      </ChatButton>
    );
  }

  return (
    <Fragment>
      {isMobile && (
        <WhiteBack
          onClick={() => {
            setChatState(CHAT_STATE.COLLAPSED);
          }}
        />
      )}
      <ChatWrapper
        expanded={chatState === CHAT_STATE.EXPANDED}
        collapsed={chatState === CHAT_STATE.COLLAPSED}
        isFocused={chatIsFocused}
        betButtonsOnRight={betButtonsOnRight}
        id="chat-wrapper"
        isMTTLobby={isMTTLobby}
      >
        <InnerChatWrapper id="chat-inner-wrapper">
          <ChatTopBar
            chatState={chatState}
            setChatState={setChatState}
            toggleShowGameLog={toggleShowGameLog}
            showGameLog={showGameLog}
            numUnreadMessages={
              showGameLog
                ? chats.length - numReadMessages
                : getPlayerChats().length - numReadPlayerMessages
            }
            isMobile={isMobile}
            isMTTLobby={isMTTLobby}
          />
          <ChatMain
            chats={showGameLog ? chats : getPlayerChats()}
            updateScroll={updateScroll}
          />
          <ChatInput
            username={username}
            gameId={gameId}
            sessionKey={sessionKey}
            chatIsFocused={chatIsFocused}
            setAllowKeyboardShortcuts={setAllowKeyboardShortcuts}
            setChatIsFocused={setChatIsFocused}
            existsModal={existsModal}
            chatState={chatState}
          />
        </InnerChatWrapper>
      </ChatWrapper>
    </Fragment>
  );
};

const ChatWrapper = styled.div`
  margin-top: 20px;
  border: 2px solid ${({ theme }) => theme.foreground};
  border-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
  position: absolute;
  z-index: 3;
  bottom: 0;
  ${(props) => (props.betButtonsOnRight ? `left: 20px` : `right: 20px`)}
  ${(props) =>
    props.isMTTLobby ? `width: 20%; height: 80%;` : `width: 40%; height: 25%;`}
  transition: 0.3s ease all;
  resize: horizontal;

  ${(props) =>
    props.isFocused &&
    `
    height: 200px;
  `}
  ${(props) =>
    props.expanded &&
    `
    height: 80vh;
  `}
  ${(props) =>
    props.collapsed &&
    `
    height: 40px;
  `}
  @media screen and (max-width: ${mobileBreakpoint}), screen and  (max-height: ${mobileHeightBreakpoint}) {
    height: 80%;
    width: 100%;
    bottom: 0px;
    left: 0;
    z-index: 4;
  }
`;

const InnerChatWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.chat.background};
`;

/**Mobile styles */
const ChatButton = styled.div`
  position: absolute;
  z-index: 6;
  background: ${({ theme }) => theme.primary};
  color: white;
  height: 50px;
  width: 50px;
  border-radius: 5px;
  bottom: 20px;
  left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    bottom: 15px;
    position: fixed;
  }
`;

const WhiteBack = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
`;

export default Chat;
