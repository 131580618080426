import React from "react";
import Switch from "../../../shared/FormElements/Switch";
import { Wrapper, SettingsOption, SettingsText } from "./shared";

const UserPreferencesView = ({
  sound,
  setSound,
  showVideoChat,
  setShowVideoChat,
  videoIsOnRight,
  setVideoIsOnRight,
  betButtonsOnRight,
  setBetButtonsOnRight,
  setJoinCall,
  isMobile,
  isFourColorDeck,
  setIsFourColorDeck,
}) => {
  return (
    <Wrapper>
      <SettingsOption>
        <SettingsText align="left">Gameplay Sound</SettingsText>
        <Switch isOn={sound} handleToggle={() => setSound(!sound)} />
      </SettingsOption>
      <SettingsOption>
        <SettingsText align="left">Four Color Deck</SettingsText>
        <Switch
          isOn={isFourColorDeck}
          handleToggle={() => setIsFourColorDeck(!isFourColorDeck)}
        />
      </SettingsOption>
      <SettingsOption>
        <SettingsText align="left">Enable Video Call</SettingsText>
        <Switch
          isOn={showVideoChat}
          handleToggle={() => {
            if (showVideoChat) {
              // we want to disable
              setShowVideoChat(false);
              setJoinCall(false);
            } else {
              // we want to show view, but not join yet
              setShowVideoChat(true);
            }
          }}
        />
      </SettingsOption>
      <SettingsOption disabled={!showVideoChat}>
        <SettingsText align="left">Video On Right</SettingsText>
        <Switch
          isOn={videoIsOnRight}
          handleToggle={() => setVideoIsOnRight(!videoIsOnRight)}
        />
      </SettingsOption>
      <SettingsOption>
        <SettingsText align="left">Bet Buttons On Right</SettingsText>
        <Switch
          isOn={betButtonsOnRight}
          handleToggle={() => setBetButtonsOnRight(!betButtonsOnRight)}
        />
      </SettingsOption>
    </Wrapper>
  );
};

export default UserPreferencesView;
