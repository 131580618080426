import React, { Fragment, useEffect } from "react";

const MouseEvents = ({
  setAllowKeyboardShortcuts,
  existsModal,
  focusElement,
  setChatIsFocused,
  onEscPress,
}) => {
  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
      document.removeEventListener("keydown", handleKeydown);
    };
  });

  const handleDocumentClick = (_) => {
    const activeId = document.activeElement.id;
    if (!existsModal) {
      const chatIsFocused = activeId === focusElement;
      setAllowKeyboardShortcuts(!chatIsFocused);
      setChatIsFocused(chatIsFocused);
    }
  };

  const handleKeydown = (e) => {
    const code = e.keyCode || e.which;
    if (code === 27) {
      onEscPress(e);
    }
  };

  return <Fragment />;
};
export default MouseEvents;
