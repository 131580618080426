export const arraysEqual = (_arr1, _arr2) => {
  if (
    !Array.isArray(_arr1) ||
    !Array.isArray(_arr2) ||
    _arr1.length !== _arr2.length
  )
    return false;
  var arr1 = _arr1.concat().sort();
  var arr2 = _arr2.concat().sort();

  for (var i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};

export const removeNumberCommas = (str) => {
  if (str === null) return null;
  return str.replace(/,/g, "");
};

export const insertNumberCommas = (str) => {
  if (str === "") return "";
  else if (str.charAt(0) === "-") return "-" + insertNumberCommas(str.slice(1));
  const reversedStr = str.split("").reverse().join("");
  const splitStr = reversedStr.match(/.{1,3}/g);
  const joinedStr = splitStr.join(",");
  return joinedStr.split("").reverse().join("");
};

export const insertFloatCommas = (str) => {
  const commaIndex = str.search("[.]");
  return (
    insertNumberCommas(str.slice(0, commaIndex)) +
    str.slice(commaIndex, str.length)
  );
};
