import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../helpers/styles";
import { MediumHeaderText, SmallBodyText } from "../../shared/Text";
import { Icon, PrimaryButton } from "../../shared/FormElements";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { optionsConfig } from "./options";
import { logError } from "../../helpers/logger";
import { textWithLoadingDots, MediumBodyText } from "../../shared/Text";
import { useStateWithLocalStorageBoolean } from "../../hooks/useStateWithLocalStorage";

const VideoChat = ({
  roomName,
  password,
  displayName,
  showVideoChat,
  setShowVideoChat,
  isMobile,
  isOnRight,
  joinCall,
  setJoinCall,
  iframeClientName,
}) => {
  const [loading, setLoading] = useState(true);
  const [tileViewEnabled, setTileViewEnabled] = useStateWithLocalStorageBoolean(
    "tileViewEnabled",
    false
  );

  // Most of the magic happens here
  // for reference: https://github.com/jitsi/jitsi-meet/blob/master/interface_config.js
  // https://github.com/jitsi/jitsi-meet/blob/master/config.js
  // for overwrites see https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe
  const options = Object.assign(
    {
      roomName,
      parentNode: "jitsi-container",
    },
    optionsConfig
  );

  const handleJoinCallClick = (e) => {
    setJoinCall(true);
    setLoading(true);
  };

  useEffect(() => {
    let jitsi = null;

    if (isMobile) {
      if (jitsi) {
        jitsi.dispose();
      }
      setJoinCall(false);
      setShowVideoChat(false);
    } else if (iframeClientName !== "game") {
      if (jitsi) {
        jitsi.dispose();
      }
      setJoinCall(false);
      setShowVideoChat(false);
    } else if (joinCall) {
      try {
        if (window.JitsiMeetExternalAPI) {
          options.parentNode = document.getElementById(options.parentNode);
          // eslint-disable-next-line no-undef
          jitsi = new JitsiMeetExternalAPI("jitsi.member.fsf.org", options);
        } else {
          jitsi = { error: true };
        }

        if (!jitsi.error) {
          jitsi.executeCommand("subject", " ");
          jitsi.addListener("videoConferenceJoined", () => {
            if (password) jitsi.executeCommand("password", password);
            setLoading(false);
            // Hack the tile view. Runs only once per user. If they clear their cookies, then it will have the opposite effect and disable their tile view.
            if (!tileViewEnabled) {
              console.error("Going to toggle the tile view");
              jitsi.executeCommand("toggleTileView");
              setTileViewEnabled(true);
            }
            jitsi.executeCommand("displayName", displayName);
          });

          jitsi.addListener("passwordRequired", () => {
            if (password) {
              jitsi.executeCommand("password", password);
            }
            setLoading(false);
          });

          // this event closes container to avoid seeing jitsi advertisement
          jitsi.addListener("videoConferenceLeft", () => {
            setShowVideoChat(false);
            setJoinCall(false);
          });
        }
      } catch (error) {
        logError(error);
        console.error(error);
      }
    } else if (jitsi) {
      jitsi.dispose();
    }

    return () => jitsi && jitsi.dispose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    displayName,
    joinCall,
    options,
    password,
    isMobile,
    setJoinCall,
    setShowVideoChat,
  ]);

  const jitsiContainerWithLoading = (
    <>
      {loading && <VideochatLoadingWrapper>Loading</VideochatLoadingWrapper>}
      <JitsiContainer id="jitsi-container" loading={loading} />
    </>
  );

  const joinCallDisplay = (
    <JoinCallWrapper>
      <MediumHeaderText>
        <Icon icon={faVideo} color={colors.darkGrey2} isLeft={1} />
      </MediumHeaderText>
      <PrimaryButton
        style={{ margin: "0 auto" }}
        color={colors.darkGrey2}
        onClick={handleJoinCallClick}
      >
        join call
      </PrimaryButton>
      <PrimaryButton
        style={{ margin: "0 auto" }}
        color={colors.grey}
        onClick={(e) => setShowVideoChat(false)}
      >
        Dismiss
      </PrimaryButton>
      <SmallBodyText style={{ marginTop: "20px", padding: "0 10px" }}>
        Not stable on Safari or mobile yet.
      </SmallBodyText>
    </JoinCallWrapper>
  );

  const videoChatContainer = joinCall
    ? jitsiContainerWithLoading
    : joinCallDisplay;

  if (iframeClientName !== "game" || isMobile) {
    return <></>;
  }

  return (
    <>
      {!showVideoChat && (
        <WidgetWrapper onClick={(e) => setShowVideoChat(true)}>
          <WidgetText>
            {"<<"}
            <Icon icon={faVideo} />
          </WidgetText>
        </WidgetWrapper>
      )}
      {showVideoChat && (
        <VideoChatWrapper visible={!isMobile} isOnRight={isOnRight}>
          {videoChatContainer}
        </VideoChatWrapper>
      )}
    </>
  );
};

const VideoChatWrapper = styled.div`
  width: 15%;
  border-bottom-left-radius: 5px;
  height: 100%;
  z-index: 3;
  position: absolute;

  right: 0;
  color: ${colors.darkGrey2};
  background: ${({ theme }) => theme.foreground};
  display: ${(props) => (props.visible ? "block" : "none")};
  ${(props) => !props.isOnRight && `left: 0;`}
`;

const JoinCallWrapper = styled.div`
  padding: 10px;
  margin-top: 50px;
`;

const JitsiContainer = styled.div`
  width: 100%;
  height: 100%;
  display: ${(props) => (props.loading ? "none" : "block")};
`;

const VideochatLoadingWrapper = styled(MediumHeaderText)`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${textWithLoadingDots}
`;

const WidgetText = styled(MediumBodyText)`
  color: ${({ theme }) => theme.text.widget};
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const WidgetWrapper = styled.div`
  position: absolute;
  z-index: 3;
  cursor: pointer;
  transition: 0.3s ease padding;
  background: ${({ theme }) => theme.foreground};
  top: 40%;
  right: 0;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
  padding: 5px 10px;
  overflow: hidden;
  &:hover {
    padding-right: 20px;
    opacity: 0.7;
  }
`;

export default React.memo(VideoChat);
