import React, { useState, useEffect, useContext } from "react";
import styled, { css } from "styled-components";
import Modal from "../../../shared/Modal";
import GameSettingsChoice from "../GameSettingsModal/GameSettingsChoice";
import BlindStructureScroll from "../../../shared/BlindStructureScroll";
import { MediumHeaderText, SmallBodyText } from "../../../shared/Text";
import {
  InputText,
  InputField,
  InputLabel,
} from "../../../shared/FormElements";
import { isInvalidOrEmpty, formatCurrency } from "../../../helpers/utils";
import {
  removeNumberCommas,
  insertNumberCommas,
} from "../../../helpers/calculations";
import { colors } from "../../../helpers/styles";
import { blinds, timer, buyin } from "../../../helpers/constants";
import { GameContext } from "../../../context/GameContext";
import { BrowserContext } from "../../../context/BrowserContext";

const MTTSettingsModal = (props) => {
  const { updateDisplay } = useContext(GameContext);
  const { isMobile } = useContext(BrowserContext);

  const [smallBlindCents, setSmallBlindCents] = useState(
    blinds.DEFAULT_SMALL_BLIND_CENTS
  );
  const [bigBlindCents, setBigBlindCents] = useState(
    blinds.DEFAULT_BIG_BLIND_CENTS
  );
  const [startingStack, setStartingStack] = useState(
    buyin.DEFAULT_AUTO_BUYIN_CENTS
  );
  const [blindIncreaseMinutes, setBlindIncreaseMinutes] = useState(
    blinds.DEFAULT_BLIND_INCREASE_MINUTES
  );
  const [timerLengthSeconds, setTimerLengthSeconds] = useState(
    timer.DEFAULT_TIMER_SECONDS
  );
  const [MTTPassword, setMTTPassword] = useState("");
  const [isInCents, setIsInCents] = useState(true);
  const [startingStackFocus, setStartingStackFocus] = useState(false);

  useEffect(() => {
    if (!startingStackFocus) {
      // round betValueCents
      if (startingStack) {
        const startingStackInt = parseInt(startingStack);
        setStartingStack(startingStackInt);
      }
    }
  }, [startingStackFocus]);

  const getStartingStackValue = () => {
    const startingStackDollarsFloat = parseFloat(startingStack) / 100;
    if (
      isNaN(startingStackDollarsFloat) ||
      startingStackDollarsFloat === null
    ) {
      return "";
    }
    if (!isInCents) {
      return Math.round(startingStack, 0);
    }
    return startingStackFocus
      ? parseFloat(startingStack) / 100
      : startingStackDollarsFloat.toFixed(2);
  };

  const enterPressedForBet = (event) => {
    if (!isInCents && !/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const onSaveButtonClick = () => {
    if (props.title === "Create MTT") {
      props._createMTT(
        smallBlindCents,
        MTTPassword,
        timerLengthSeconds,
        startingStack,
        blindIncreaseMinutes * 60,
        isInCents
      );
    } else {
      // TODO, this is where you would make settings save during the game
      // this block represents the "Game Settings" modal as opposed to the "Create New Game" modal
      // for now just close it
      updateDisplay("mtt");
    }
    return new Promise(() => true);
  };

  const onNumberInputChange = (e, cb) => {
    const val = removeNumberCommas(e.target.value);
    if (!isInvalidOrEmpty(val)) {
      cb(val);
    }
  };

  const password = (
    <PasswordElement fullWidth disableCustomStyles={props.disableModalStyles}>
      <InputLabel
        htmlFor="mtt-password"
        value={MTTPassword}
        id="mtt-password-label"
      >
        MTT Password (optional)
      </InputLabel>
      <InputText
        id="mtt-password"
        onChange={(e) => setMTTPassword(e.target.value)}
        type="password"
        placeholder="MTT password (optional)"
        contrast
      />
    </PasswordElement>
  );

  return (
    <Modal
      show
      onOverlayClick={props._cancelPopup}
      title={
        props.title ? props.title : props.disableTitle ? null : "MTT Settings"
      }
      btnText={props.buttonTitle ? props.buttonTitle : "Save"}
      onBtnClick={onSaveButtonClick}
      dataTestId="mtt-settings-save-button"
      disableModalStyles={props.disableModalStyles}
      fullWidthInput={props.fullWidthInput}
      align={props.align}
      ctaContent={password}
      margin
      scrollMiddle={!isMobile}
    >
      <SettingsWrapper disableModalStyles={props.disableModalStyles}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <SettingsText align={props.align ? props.align : "left"} themeable>
            Blinds
          </SettingsText>
          <SettingsSubText color={colors.grey}>
            Set your initial small/big blinds
            <br />
            Blind structure shown below
          </SettingsSubText>
        </div>
        <GameSettingsChoice
          id={"5c10c"}
          smallBlindCents={smallBlindCents}
          bigBlindCents={bigBlindCents}
          setSmallBlindCents={(val) => setSmallBlindCents(val)}
          setBigBlindCents={(val) => setBigBlindCents(val)}
          isInCents={isInCents}
          setIsInCents={setIsInCents}
        />
      </SettingsWrapper>
      <SettingsWrapper>
        <SettingsLabel>
          <SettingsText align="left">Timer (seconds)</SettingsText>
          <SettingsSubText color={colors.grey} style={{ width: "100%" }}>
            Set amount of time per turn
          </SettingsSubText>
        </SettingsLabel>
        <BigBlindInputText
          value={insertNumberCommas(timerLengthSeconds.toString())}
          onChange={(e) => onNumberInputChange(e, setTimerLengthSeconds)}
          placeholder={timer.DEFAULT_TIMER_SECONDS}
          modal
        />
      </SettingsWrapper>
      <SettingsWrapper>
        <SettingsLabel>
          {isInCents && (
            <SettingsText align="left">Starting Stack (Dollars)</SettingsText>
          )}
          {!isInCents && (
            <SettingsText align="left">Starting Stack (chips)</SettingsText>
          )}
          <SettingsSubText color={colors.grey}>
            Set stack players start with
          </SettingsSubText>
        </SettingsLabel>
        <AutoBuyInInputText
          type="number"
          onWheel={(e) => e.target.blur()}
          value={getStartingStackValue()}
          onChange={(e) =>
            setStartingStack(
              isInCents ? parseFloat(e.target.value) * 100 : e.target.value
            )
          }
          onKeyPress={enterPressedForBet}
          placeholder={formatCurrency(
            buyin.DEFAULT_AUTO_BUYIN_CENTS,
            isInCents
          )}
          onFocus={() => setStartingStackFocus(true)}
          onBlur={() => setStartingStackFocus(false)}
          modal
        />
      </SettingsWrapper>
      <SettingsWrapper>
        <SettingsLabel>
          <SettingsText align="left">Increasing Blinds (minutes)</SettingsText>
          <SettingsSubText color={colors.grey}>
            Set increasing blinds time interval
          </SettingsSubText>
        </SettingsLabel>
        <BigBlindInputText
          value={insertNumberCommas(blindIncreaseMinutes.toString())}
          onChange={(e) => onNumberInputChange(e, setBlindIncreaseMinutes)}
          placeholder={buyin.DEFAULT_BLIND_INCREASE_MINUTES}
          modal
        />
      </SettingsWrapper>
      <SettingsWrapper>
        <BlindStructureScroll
          initialBlindCents={smallBlindCents}
          blindIncreaseMinutes={blindIncreaseMinutes}
          currentGameTimeSeconds={-1}
          showTitle={true}
          showCurrentBlinds={false}
        />
      </SettingsWrapper>
      {props.disableModalStyles && password}
    </Modal>
  );
};

const SettingsWrapper = styled.div`
  margin: 5px 0 10px 0;
  width: 100%;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.disabled &&
    `
  opacity: 0.2;
  pointer-events: none;
  `}
`;

const SettingsText = styled(MediumHeaderText)`
  width: auto;
  display: inline-block;
`;

const SettingsLabel = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 20px;
  width: 100%;
  color: ${({ theme }) => theme.text.label};
`;

const SettingsSubText = styled(SmallBodyText)`
  width: 100%;
`;

const BigBlindInputText = styled(InputText)`
  height: 50px;
  text-align: right;
  font-size: 22px;
  width: 30%;
`;

export const PasswordElement = styled(InputField)`
  input {
    transition: border 0.3s ease;
  }
  ${(props) =>
    !props.disableCustomStyles &&
    css`
      display: flex;
      input {
        width: 90%;
      }
    `}

  ${(props) =>
    props.invalid &&
    css`
      input {
        border: 1px solid ${colors.red};
      }
    `}
  ${(props) =>
    !props.disablePadding &&
    `
    padding-right: 10px;
  `}
`;

const AutoBuyInInputText = styled(BigBlindInputText)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export default MTTSettingsModal;
