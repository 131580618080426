import React, { useState, useEffect, useCallback } from "react";
import Modal, { transitionTime } from "../../../shared/Modal";
import TabView from "../../../shared/TabView";
import GameSettingsView from "./GameSettingsView";
import UserPreferencesView from "./UserPreferencesView";
import { emit } from "../../../helpers/socket";

const BrowserSettingsModal = ({
  gameId,
  username,
  sound,
  setSound,
  showVideoChat,
  setShowVideoChat,
  videoIsOnRight,
  setVideoIsOnRight,
  betButtonsOnRight,
  setBetButtonsOnRight,
  setJoinCall,
  hideModal,
  show,
  isMobile,
  smallBlindCents,
  isTimerEnabled,
  timerLengthSeconds, //    timer.DEFAULT_TIMER_SECONDS
  handTimerLengthSeconds, //    timer.MEDIUM_HAND_TIMER_SECONDS
  isFourColorDeck,
  setIsFourColorDeck,
  isSpectating,
  isTournament,
  isAdmin,
  isInCents,
  adminPlayers,
  startTransition,
  setStartTransition,
}) => {
  const [btnText, setBtnText] = useState("Save");
  const [disableSave, setDisableSave] = useState(false);
  const [smallBlindSettings, _setSmallBlindSettings] = useState(
    smallBlindCents
  );
  const [isTimerEnabledSettings, _setIsTimerEnabledSettings] = useState(
    isTimerEnabled
  );
  const [timerLengthSecondsSettings, _setTimerLengthSecondsSettings] = useState(
    timerLengthSeconds
  );
  const [
    handTimerLengthSecondsSettings,
    _setHandTimerLengthSecondsSettings,
  ] = useState(handTimerLengthSeconds);

  const [useCentsSettings, _setUseCentsSettings] = useState(isInCents);

  const close = () => {
    setStartTransition(true);
    setTimeout(() => {
      hideModal(true);
    }, transitionTime);
  };

  const setSmallBlindSettings = useCallback(
    (newSmallBlindCentsSettings) => {
      if (newSmallBlindCentsSettings) {
        _setSmallBlindSettings(parseInt(newSmallBlindCentsSettings));
      } else {
        _setSmallBlindSettings(newSmallBlindCentsSettings);
      }
      setDisableSave(false);
    },
    [smallBlindCents]
  );

  const setIsTimerEnabledSettings = useCallback(
    (newIsTimerEnabledSettings) => {
      setDisableSave(false); // is there a reason the order of these two is switched above? or are state updates gauranteed to wait till fn call ends
      _setIsTimerEnabledSettings(newIsTimerEnabledSettings);
    },
    [isTimerEnabled]
  );

  const setTimerLengthSecondsSettings = useCallback(
    (newTimerLengthSecondsSettings) => {
      if (newTimerLengthSecondsSettings) {
        _setTimerLengthSecondsSettings(parseInt(newTimerLengthSecondsSettings));
      } else {
        _setTimerLengthSecondsSettings(newTimerLengthSecondsSettings);
      }
      setDisableSave(false);
      // setDisableSave logic is broken af -- it starts enabled, and then if 2 features are changed
      // then 1 is unchanged it stays disabled. maybe just set it to always be enabled
    },
    [timerLengthSeconds]
  );

  const setHandTimerLengthSecondsSettings = useCallback(
    (newHandTimerLengthSecondsSettings) => {
      _setHandTimerLengthSecondsSettings(newHandTimerLengthSecondsSettings);
      setDisableSave(false);
    },
    [handTimerLengthSeconds]
  );

  const setUseCentsSettings = useCallback(
    (newUseCentsSettings) => {
      _setUseCentsSettings(newUseCentsSettings);
      setDisableSave(false);
    },
    [isInCents]
  );

  useEffect(() => {
    setSmallBlindSettings(smallBlindCents);
    setIsTimerEnabledSettings(isTimerEnabled);
    setTimerLengthSecondsSettings(timerLengthSeconds);
    setHandTimerLengthSecondsSettings(handTimerLengthSeconds);
    setUseCentsSettings(isInCents);
  }, [
    setSmallBlindSettings,
    smallBlindCents,
    setIsTimerEnabledSettings,
    isTimerEnabled,
    setTimerLengthSecondsSettings,
    timerLengthSeconds,
    setHandTimerLengthSecondsSettings,
    handTimerLengthSeconds,
    setUseCentsSettings,
    isInCents,
  ]);
  const onSaveButtonClick = (e) => {
    emit("set_pending_settings_change", {
      username: username,
      game_id: gameId,
      small_blind_amount: smallBlindSettings,
      is_timer_enabled: isTimerEnabledSettings,
      timer_length_seconds: timerLengthSecondsSettings,
      hand_timer_length_seconds: handTimerLengthSecondsSettings,
      is_in_cents: useCentsSettings,
    });
    close();
    return Promise.resolve(false);
  };

  let tabNamesToViews;

  if (!isSpectating) {
    tabNamesToViews = {
      Settings: (
        <GameSettingsView
          smallBlindSettings={smallBlindSettings}
          setSmallBlindSettings={setSmallBlindSettings}
          isTimerEnabledSettings={isTimerEnabledSettings}
          setIsTimerEnabledSettings={setIsTimerEnabledSettings}
          timerLengthSecondsSettings={timerLengthSecondsSettings}
          setTimerLengthSecondsSettings={setTimerLengthSecondsSettings}
          isTournament={isTournament}
          isAdmin={isAdmin}
          handTimerLengthSecondsSettings={handTimerLengthSecondsSettings}
          setHandTimerLengthSecondsSettings={setHandTimerLengthSecondsSettings}
          useCentsSettings={useCentsSettings}
          setUseCentsSettings={setUseCentsSettings}
          adminPlayers={adminPlayers}
        />
      ),
      Preferences: (
        <UserPreferencesView
          sound={sound}
          setSound={setSound}
          showVideoChat={showVideoChat}
          setShowVideoChat={setShowVideoChat}
          videoIsOnRight={videoIsOnRight}
          setVideoIsOnRight={setVideoIsOnRight}
          betButtonsOnRight={betButtonsOnRight}
          setBetButtonsOnRight={setBetButtonsOnRight}
          setJoinCall={setJoinCall}
          isMobile={isMobile}
          isFourColorDeck={isFourColorDeck}
          setIsFourColorDeck={setIsFourColorDeck}
        />
      ),
    };
  } else {
    tabNamesToViews = {
      Preferences: (
        <UserPreferencesView
          sound={sound}
          setSound={setSound}
          showVideoChat={showVideoChat}
          setShowVideoChat={setShowVideoChat}
          videoIsOnRight={videoIsOnRight}
          setVideoIsOnRight={setVideoIsOnRight}
          betButtonsOnRight={betButtonsOnRight}
          setBetButtonsOnRight={setBetButtonsOnRight}
          setJoinCall={setJoinCall}
          isMobile={isMobile}
          isFourColorDeck={isFourColorDeck}
          setIsFourColorDeck={setIsFourColorDeck}
        />
      ),
    };
  }

  const handleTabChange = (newTab) => {
    if (newTab === "Settings") {
      setBtnText("Save");
    } else {
      setBtnText(null);
    }
  };

  let defaultView = !isSpectating ? "Settings" : "Preferences";

  return (
    <Modal
      show={true}
      onOverlayClick={close}
      isInvalid={disableSave}
      transitionOut={startTransition}
      margin={false}
      onBtnClick={onSaveButtonClick}
      btnText={!isSpectating && btnText}
      dataTestId={"save-game-settings-button"}
    >
      <TabView
        tabNamesToViews={tabNamesToViews}
        defaultView={defaultView}
        onTabChange={handleTabChange}
      />
    </Modal>
  );
};

export default BrowserSettingsModal;
