import React, { useState, Fragment } from "react";
import { colors } from "../../../helpers/styles";
import { PrimaryButton } from "../../../shared/FormElements";
import { LoadingWrapper, BoldText } from "../../../shared/Text";
import { roles } from "../../../helpers/constants";
import styled from "styled-components";
import { emit } from "../../../helpers/socket";

const StartMTTRoundAction = ({ clientRole, MTTId }) => {
  const [actionDone, setActionDone] = useState(false);

  const onStartRoundClick = () => {
    emit("start_mtt_round", {
      mtt_id: MTTId,
    });
    setActionDone(true);
  };

  let startButton;

  if (actionDone) {
    startButton = <Fragment />;
  } else {
    startButton = (
      <PrimaryButton
        dataTestId="mtt-start-button"
        onClick={onStartRoundClick}
        color={colors.darkBlue}
      >
        Start Round
      </PrimaryButton>
    );
  }
  return (
    <StartMTTRoundWrapper>
      {clientRole === roles.ROLE_MTT_ADMIN && startButton}
      {clientRole !== roles.ROLE_MTT_ADMIN && (
        <LoadingWrapper>
          Waiting for <BoldText>MTT Admin</BoldText> to {"start round"}
        </LoadingWrapper>
      )}
    </StartMTTRoundWrapper>
  );
};

const StartMTTRoundWrapper = styled.div`
  text-align: right;
`;

export default StartMTTRoundAction;
