import React, { useContext } from "react";
import AllAudio from "./AllAudio";
import ModalConductor from "./ModalConductor";
import { GlobalStyle } from "../../helpers/styles";
import { BrowserContext } from "../../context/BrowserContext";
import { connectEmitSocketMessage } from "../../helpers/socket";
import { GameContext } from "../../context/GameContext";
import FullStory from "react-fullstory";
import Heap from "react-heap";
import "./polyfills";

const FULLSTORY_ORG_ID = "TFSSC"; // Fill this in here
const FULLSTORY_NAMESPACE = "FS";

const Global = ({ gameId, provider }) => {
  const {
    sessionKey,
    username,
    ownStackCents,
    isSpectating,
    isInCents,
  } = useContext(GameContext);
  const {
    setAudioToSoundMap,
    currentModal,
    setCurrentModal,
    isMobile,
    setExistsModal,
  } = useContext(BrowserContext);
  const emitSocketMessage = connectEmitSocketMessage(
    sessionKey,
    gameId,
    username
  );
  return (
    <>
      <GlobalStyle />
      <AllAudio setAudioToSoundMap={setAudioToSoundMap} />
      <FullStory org={FULLSTORY_ORG_ID} namespace={FULLSTORY_NAMESPACE} />
      <Heap
        appId="lipoker"
        userId={username}
        userData={{
          isMobile: { isMobile },
          gameId: { gameId },
          isSpectating: { isSpectating },
        }}
      />
      <ModalConductor
        currentModal={currentModal}
        setCurrentModal={setCurrentModal}
        emitSocketMessage={emitSocketMessage}
        ownStackCents={ownStackCents}
        gameId={gameId}
        username={username}
        isMobile={isMobile}
        setExistsModal={setExistsModal}
        isSpectating={isSpectating}
        provider={provider}
        isInCents={isInCents}
      />
    </>
  );
};

export default Global;
