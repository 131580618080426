import React, { Fragment } from "react";
import styled from "styled-components";
import Card, {
  cardHeight,
  mobileCardHeight,
  cardHeightRaw,
  mobileCardHeightRaw,
} from "../../../shared/Card";
import { playerStyles } from "./player_styles";
import { SmallBodyText, SmallHeaderTextEllipsis } from "../../../shared/Text";
import { Icon } from "../../../shared/FormElements";
import BuyInActionBar from "./BuyInActionBar";
import { formatCurrency } from "../../../helpers/utils";
import { colors, font } from "../../../helpers/styles";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
  roles,
} from "../../../helpers/constants";
import {
  convertCardStringToValues,
  posFromClientPOV,
} from "../../../helpers/utils";
import { emitSocketMessage } from "../../../helpers/socket";
import {
  faUserCog,
  faStar,
  faEye,
  faEyeSlash,
  faCoins,
} from "@fortawesome/free-solid-svg-icons";
// import { GameContext } from "../../context/GameContext";

const Player = ({
  name,
  cards,
  balance,
  isClient,
  clientRole,
  playerRole,
  isInHand,
  isTurn,
  isWinner,
  isDealer,
  bestHand,
  pos,
  clientPos,
  pot,
  connected,
  requestedBuyIn,
  pendingBuyIn,
  isMobile,
  isFourColorDeck,
  emitSocketMessage,
  isSpectating,
  maxPlayerNumber,
  isGodModeEnabled,
  isGod,
  showCardsToGod,
  isInCents,
}) => {
  let playerHand = <Fragment />;
  let posStyle =
    playerStyles.playerPos[posFromClientPOV(pos, clientPos, maxPlayerNumber)];
  let betPosStyle =
    playerStyles.playerBetPos[
      posFromClientPOV(pos, clientPos, maxPlayerNumber)
    ];
  let nameLabel = (
    <NameWrapper>
      {playerRole === roles.ROLE_ADMIN && (
        <Icon icon={faUserCog} color={colors.white} />
      )}
      <NameText>{isTurn && isClient ? "YOUR TURN" : name}</NameText>
    </NameWrapper>
  );

  if (cards) {
    playerHand = (
      <CardsWrapper>
        {Object.keys(cards).map((key) => {
          const cardDisplay = cards[key].card;
          const cardObject = convertCardStringToValues(cardDisplay);
          return (
            <Card
              size={isMobile ? "mobile" : "md"}
              key={cardDisplay}
              suit={cardObject.suit}
              number={cardObject.number}
              handCardNumber={key}
              isHighlighted={cards[key].isHighlighted}
              isFourColorDeck={isFourColorDeck}
              isFolded={!isInHand}
            />
          );
        })}
      </CardsWrapper>
    );
  } else if (!isClient && isInHand) {
    playerHand = (
      <Fragment>
        <Card isBack handCardNumber="0" size="md" isFolded={!isInHand} />
        <Card isBack handCardNumber="1" size="md" isFolded={!isInHand} />
      </Fragment>
    );
  }
  return (
    <Fragment>
      <PlayerWrapper style={posStyle}>
        <PlayerHeadingContent>
          <IconWrapper>
            {isWinner && (
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/crown.png`}
                alt="winner-crown"
              />
            )}
          </IconWrapper>
          {playerHand}
        </PlayerHeadingContent>
        <MainContent disable={!isInHand || !connected} highlight={isTurn}>
          {isDealer && (
            <DealerButton>
              <span>D</span>
            </DealerButton>
          )}

          {/* God Mode enabled -- display Player icons*/}
          {isGodModeEnabled &&
            /* Normal player showing cards to god */
            ((!isGod && showCardsToGod && (
              <RoleButton>
                <Icon icon={faEye} color={colors.black} />
                <RoleLabel>{"Showing cards to God"}</RoleLabel>
              </RoleButton>
            )) ||
              /* Normal player not showing cards to god */
              (!isGod && !showCardsToGod && (
                <RoleButton>
                  <Icon icon={faEyeSlash} color={colors.black} />
                  <RoleLabel>{"Not showing cards to God"}</RoleLabel>
                </RoleButton>
              )) ||
              /* Player is god of game */
              (isGod && (
                <RoleButton>
                  <Icon icon={faStar} color={colors.black} />
                  <RoleLabel>
                    {"God - can see certain player's cards"}
                  </RoleLabel>
                </RoleButton>
              )))}

          {!isSpectating &&
            clientRole === roles.ROLE_ADMIN &&
            !(requestedBuyIn === null || requestedBuyIn === 0) && (
              <BuyInActionBar
                emitSocketMessage={emitSocketMessage}
                name={name}
                pos={pos}
                requestedBuyIn={requestedBuyIn}
                pendingBuyIn={pendingBuyIn}
                isInCents={isInCents}
              />
            )}
          {!(pendingBuyIn === null || pendingBuyIn === 0) && (
            <AddMoney>+{formatCurrency(pendingBuyIn, isInCents)}</AddMoney>
          )}
          {nameLabel}
          <SmallBodyText>
            {isMobile && `/`}
            {connected
              ? `${formatCurrency(balance, isInCents)} `
              : "DISCONNECTED"}
            {!isInCents && (
              <Icon icon={faCoins} color={colors.white} size="xs" />
            )}
            {!isMobile && !!bestHand && isInHand && ` / ${bestHand}`}
          </SmallBodyText>
        </MainContent>
        {!isTurn && <MainContentShadow />}
      </PlayerWrapper>
      {pot !== 0 && (
        <PlayerPot style={betPosStyle}>
          {`${formatCurrency(pot, isInCents)} `}
          {!isInCents && <Icon icon={faCoins} color={colors.white} size="xs" />}
        </PlayerPot>
      )}
    </Fragment>
  );
};

const iconHeight = 30;
const mobileIconHeight = 12;

const PlayerWrapper = styled.div`
  position: absolute;
  display: block;
  width: 145px;
  height: 160px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    width: 90px;
    height: 80px;
  }
`;

const PlayerHeadingContent = styled.div`
  display: block;
  height: ${cardHeightRaw + iconHeight}px;

  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    height: ${mobileCardHeightRaw + mobileIconHeight}px;
  }
`;

const IconWrapper = styled.div`
  height: ${iconHeight}px;
  img {
    height: ${iconHeight}px;
  }
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    height: ${mobileIconHeight}px;
    img {
      height: ${mobileIconHeight}px;
      margin-top: -10px;
    }
  }
`;

const CardsWrapper = styled.div`
  height: ${cardHeight};
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    height: ${mobileCardHeight};
  }
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MainContent = styled.div`
  position: absolute;
  z-index: 3;
  bottom: 0;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0 8px;
  background: ${({ theme }) => theme.secondary};
  border: 1px solid ${({ theme }) => theme.player.borderColor};
  ${(props) =>
    props.highlight &&
    props.theme &&
    `
    background: ${props.theme.primary};
    border: 1px solid ${props.theme.player.borderHighlightedColor};
  `}
  ${(props) =>
    props.disable &&
    props.theme &&
    `background: ${props.theme.player.disabledBackground};`}
  span {
    margin-top: 2px;
  }
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    display: flex;
    flex-direction: row;
    height: 27px;
    width: 90px;
    padding: 0 6px;
  }
`;

const MainContentShadow = styled(MainContent)`
  background: ${({ theme }) => theme.flatShadow};
  border: none;
  transform: translate(4px, 4px);
  z-index: 0;
  pointer-events: none;
`;

const PlayerText = styled(SmallBodyText)`
  color: ${colors.white};
  text-align: center;
  background: none;
  line-height: 20px;
  margin: 0 auto;
  width: 60px; //making this 100% makes it off center
  font-weight: bold;
`;

const PlayerPot = styled(PlayerText)`
  position: absolute;
  z-index: 2;
  background: ${({ theme }) => theme.player.pot};
  border-radius: 5px;
  color: white;
  transform: translate(-50%, -50%);
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    background: ${colors.grey};
  }
`;

const DealerButton = styled.div`
  width: 20px;
  height: 20px;
  font-family: ${font.header};
  font-size: 14px;
  border-radius: 50%;
  background-color: ${colors.white};
  color: ${({ theme }) => theme.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(650%, -50%);
  font-weight: bold;
  border: 1px solid ${({ theme }) => theme.foreground};
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    width: 10px;
    height: 10px;
    font-size: 0.5em;
  }
`;

const RoleLabel = styled.div`
  display: none;
  margin-left: 10px;
  pointer-events: none;
  background: ${({ theme }) => theme.foreground};
  color: ${({ theme }) => theme.text.label};
  font-weight: bold;
  font-family: ${font.body};
  font-size: 12px;
  border-radius: 5px;
  height: fit-content;
  min-width: max-content;
  padding: 2px 5px;
`;

const RoleButton = styled.div`
  width: 20px;
  height: 20px;
  font-family: ${font.header};
  font-size: 14px;
  border-radius: 50%;
  background-color: ${colors.white};
  color: ${({ theme }) => theme.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  font-weight: bold;
  border: 1px solid ${({ theme }) => theme.foreground};
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.primary};
    ${RoleLabel} {
      display: block;
    }
  }
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    width: 10px;
    height: 10px;
    font-size: 0.5em;
  }
`;

const AddMoney = styled.div`
  font-family: ${font.body};
  font-size: 14px;
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(50%, -50%);
  background: ${({ theme }) => theme.primary};
  border: 1px solid ${({ theme }) => theme.foreground};
  padding: 2px 6px;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    font-size: 8px;
  }
`;

const NameText = styled(SmallHeaderTextEllipsis)`
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    text-align: left;
  }
  margin-left: 2px;
`;

export default Player;
