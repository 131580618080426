import React, { useState } from "react";
import styled from "styled-components";
import {
  InputText,
  InputField,
  InputLabel,
} from "../../../shared/FormElements";
import Modal from "../../../shared/Modal";
import { PasswordElement } from "../GameSettingsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "../../../shared/FormElements";
import { faTwitter, faPatreon } from "@fortawesome/free-brands-svg-icons";

const NewPlayerModal = (props) => {
  const [username, setUsername] = useState(
    props.prefilledUsername ? props.prefilledGamePassword : ""
  );
  const [userPassword, setUserPassword] = useState("");
  const [gamePassword, setGamePassword] = useState("");
  const [invalidGamePassword, setIsInvalidGamePassword] = useState(false);
  const [invalidUserPassword, setIsInvalidUserPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleUserPasswordChange = (e) => {
    setUserPassword(e.target.value);
    setIsInvalidUserPassword(false);
  };

  const handleGamePasswordChange = (e) => {
    setGamePassword(e.target.value);
    setIsInvalidGamePassword(false);
  };
  const onJoinClick = (e) => {
    if (username && username !== "") {
      return props
        ._logIn(
          props.prefilledUsername
            ? props.prefilledUsername.toLowerCase()
            : username.toLowerCase(),
          userPassword,
          props.prefilledGamePassword
            ? props.prefilledGamePassword
            : gamePassword,
          props.isGod
        )
        .then((response) => {
          if (response.error) {
            if (response.field && response.field.includes("Game")) {
              setIsInvalidGamePassword(true);
            }
            if (response.field && response.field.includes("User")) {
              setIsInvalidUserPassword(true);
            }
            return false;
          }
          return true;
        });
    }
    return new Promise(() => false);
  };

  const onSpectateClick = (e) => {
    if (username && username !== "") {
      return props
        ._spectateGame(
          props.prefilledUsername
            ? props.prefilledUsername.toLowerCase()
            : username.toLowerCase(),
          userPassword,
          props.prefilledGamePassword
            ? props.prefilledGamePassword
            : gamePassword,
          props.isGod
        )
        .then((response) => {
          if (response.error) {
            if (response.field && response.field.includes("Game")) {
              setIsInvalidGamePassword(true);
            }
            if (response.field && response.field.includes("User")) {
              setIsInvalidUserPassword(true);
            }
            return false;
          }
          return true;
        });
    }
    return new Promise(() => false);
  };

  const checkOnEnter = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      setIsLoading(true);
      onJoinClick(event);
    }
  };

  let gamePassField = ``;
  if (props.hasGamePassword) {
    gamePassField = (
      <PasswordElement fullWidth invalid={invalidGamePassword}>
        <InputLabel
          htmlFor="input-game-password"
          id="input-game-password-label"
        >
          Game Password
        </InputLabel>
        <FullWidthInput
          id="input-game-password"
          onChange={handleGamePasswordChange}
          type="password"
          value={
            props.prefilledGamePassword
              ? props.prefilledGamePassword
              : gamePassword
          }
          placeholder="Game Password"
        />
        <InvalidIcon icon={faTimes} hide={!invalidGamePassword ? 1 : 0} />
      </PasswordElement>
    );
  }

  return (
    <Modal
      show
      title={`Enter ${props.MTTId !== "" ? "MTT" : "Game"} ${
        props.isGod ? "As God" : ""
      }`}
      subtitle="Choose your in game name"
      btnText={props.spectateOnly ? "Spectate" : "Play"}
      onBtnClick={props.spectateOnly ? onSpectateClick : onJoinClick}
      btnTextAlt={props.spectateOnly ? null : "Spectate"}
      onBtnAltClick={props.spectateOnly ? null : onSpectateClick}
      dataTestId={props.spectateOnly ? "spectate-button" : "join-button"}
      dataTestAltId={props.spectateOnly ? null : "spectate-button"}
      isInvalid={
        username.length <= 0 ||
        (props.hasGamePassword && !props.prefilledGamePassword && !gamePassword)
      }
      ctaContent={gamePassField}
      isLoading={isLoading}
    >
      <InputField top={10}>
        <InputLabel htmlFor="input-username" id="input-username-label">
          Username
        </InputLabel>
        <FullWidthInput
          id="input-username"
          value={props.prefilledUsername ? props.prefilledUsername : username}
          onChange={handleUsernameChange}
          type="text"
          placeholder="Username"
          onKeyPress={checkOnEnter}
          modal
        />
      </InputField>
      <PasswordElement
        top={10}
        disablePadding
        disableCustomStyles
        invalid={invalidUserPassword}
      >
        <InputLabel htmlFor="input-password" id="input-password-label">
          User Password (optional)
        </InputLabel>
        <FullWidthInput
          id="input-password"
          onChange={handleUserPasswordChange}
          type="password"
          placeholder="User Password (optional)"
          modal
        />
        <InvalidIcon
          icon={faTimes}
          is_user_password_field={1}
          hide={!invalidUserPassword ? 1 : 0}
        />
      </PasswordElement>
      <MediaContainer>
        <a
          href="https://twitter.com/lipoker_io"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MediaIcon icon={faTwitter} color={"#1E9DEA"} size={"lg"}></MediaIcon>
        </a>
        <a
          href="https://www.patreon.com/lipoker"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MediaIcon icon={faPatreon} color={"#EF6551"} size={"lg"}></MediaIcon>
        </a>
        <a
          href="https://www.notion.so/Lipoker-Blog-5ba5c59a84234bd1a4343f5880780ea0"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MediaIcon
            icon={faPencilAlt}
            color={"#FFFFFF"}
            size={"lg"}
          ></MediaIcon>
        </a>
      </MediaContainer>
    </Modal>
  );
};

const FullWidthInput = styled(InputText)`
  width: 100%;
`;

const InvalidIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 30px;
  ${(props) => props.is_user_password_field && `right: 20px;`}
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.tertiary};
  height: 10px;
  transition: opacity 0.3s ease;
  ${(props) =>
    props.hide &&
    `
    opacity: 0;
    cursor-events: none;
  `}
`;

const MediaContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MediaIcon = styled(Icon)`
  margin: 0 10px;
`;

export default NewPlayerModal;
