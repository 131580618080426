import React, { Fragment, useState } from "react";
import Widget from "../../shared/Widget";
import { BlindStructureModal } from "../Modals";

const BlindStructureWidget = ({
  isMobile,
  setAllowKeyboardShortcuts,
  setExistsModal,
  initialBlindCents,
  blindIncreaseMinutes,
}) => {
  const [showBlindStructureModal, _setShowBlindStructureModal] = useState(
    false
  );

  const setShowBlindStructureModal = (show) => {
    _setShowBlindStructureModal(show);
    setAllowKeyboardShortcuts(!show);
    setExistsModal(show);
  };

  return (
    <Fragment>
      <Widget
        onClick={() => {
          setShowBlindStructureModal(true);
        }}
        text={"Blinds"}
        showNotif={false}
        left={isMobile ? "2px" : "6px"}
        //right={isMobile ? "85px" : "135px"}
      />
      <BlindStructureModal
        show={showBlindStructureModal}
        hideModal={() => {
          setShowBlindStructureModal(false);
        }}
        initialBlindCents={initialBlindCents}
        blindIncreaseMinutes={blindIncreaseMinutes}
      />
    </Fragment>
  );
};

export default BlindStructureWidget;
