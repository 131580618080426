import styled, { css } from "styled-components";
import { Tooltip } from "react-bootstrap";
import { SmallBodyText, LargeBodyText } from "./Text";

export const MainOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  ${(props) => props.theme && `background: ${props.theme.modal.overlay};`}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  opacity: ${(props) => (props.opacity == null ? 0.8 : props.opacity)};
  z-index: ${(props) => (props.zIndex == null ? 2 : props.zIndex)};
  transition: ${(props) =>
      props.transitionTimeSeconds == null
        ? "0.4"
        : props.transitionTimeSeconds}s
    ease all;
  ${(props) =>
    props.hide &&
    `
    opacity: 0;
    pointer-events: none;
  `}
`;

export const removeScrollbars = css`
  overflow-y: hidden;
  overflow-x: hidden;
  &:hover {
    overflow-y: scroll;
    overflow-x: scroll;
  }
  &::-webkit-scrollbar {
    // Chrome scrollbar hiding
    width: 0 !important;
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none; /* For Firefox and IE */
  overflow: -moz-scrollbars-none; /* this will hide the scrollbar in mozilla based browsers */
  -ms-overflow-style: none; /* this will hide the scrollbar in internet explorers */
`;

export const OverlayWrapper = styled(Tooltip)`
  margin-left: 10px !important;
  .tooltip-inner {
    max-width: ${(props) => props.maxWidth || "400px"}
    padding: 15px;
    border-radius: 10px;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  margin: 5px;
  justify-content: center;
`;

export const ListBodyText = styled(SmallBodyText)`
  font-size: 16px !important;
`;

export const InfoIcon = styled(LargeBodyText)`
  margin-left: 10px;
  margin-bottom: 4px;
  margin-top: 2px;
  padding: 0 10px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: bold;
  background-color: ${({ theme }) => theme.text.label};
  color: ${({ theme }) => theme.modal.background};
`;
