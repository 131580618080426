import styled, { css } from "styled-components";
import { font, colors } from "../../helpers/styles";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../helpers/constants";

const BaseHeaderText = styled.div`
  width: 100%;
  ${({ align }) => (align ? `text-align: ${align}` : "text-align: center;")};
  ${(props) =>
    props.themeable && props.theme && `color: ${props.theme.text.mainBody};`}
`;

const BaseBodyText = styled.div`
  ${({ align }) => (align ? `text-align: ${align}` : "text-align: left;")};
  margin-bottom: 0;
`;

export const LargeHeaderText = styled(BaseHeaderText)`
  font-family: ${font.header};
  font-weight: bold;
  font-size: 2em;
  line-height: 25px;
  font-weight: bold;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    font-size: 1.6em;
  }
`;

export const MediumHeaderText = styled(BaseHeaderText)`
  font-family: ${font.header};
  font-size: 28px;
  letter-spacing: 1px;
  font-weight: bold;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    font-size: 24px;
    letter-spacing: 0.8px;
  }
`;

export const SmallHeaderText = styled(BaseHeaderText)`
  font-family: ${font.header};
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1px;
  font-weight: bold;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    font-size: 12px;
    line-height: 12px;
  }
`;

export const SmallBodyText = styled(BaseBodyText)`
  font-family: ${font.body};
  font-size: 14px;
  margin: 0;
  ${(props) => props.color && `color: ${props.color}`};
  ${(props) =>
    props.themeable && props.theme && `color: ${props.theme.text.mainBody};`}

  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    font-size: 10px;
  }
`;

export const SmallHeaderTextEllipsis = styled(SmallHeaderText)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MediumBodyText = styled(BaseBodyText)`
  font-family: ${font.body};
  font-size: 18px;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    font-size: 12px;
  }
`;

export const LargeBodyText = styled(BaseBodyText)`
  font-family: ${font.body};
  font-size: 22px;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    font-size: 20px;
  }
`;

export const HighlightedText = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.tertiary};
`;

export const textWithLoadingDots = css`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: ${colors.darkGrey2};

  &:after {
    content: " .";
    animation: dots 1s steps(5, end) infinite;
  }
  @keyframes dots {
    0%,
    20% {
      color: ${colors.darkGrey2};
      text-shadow: 0.25em 0 0 ${colors.darkGrey2}, 0.5em 0 0 ${colors.darkGrey2};
    }
    40% {
      color: ${colors.lightGrey2};
      text-shadow: 0.25em 0 0 ${colors.darkGrey2}, 0.5em 0 0 ${colors.darkGrey2};
    }
    60% {
      text-shadow: 0.25em 0 0 ${colors.lightGrey2},
        0.5em 0 0 ${colors.darkGrey2};
    }
    80%,
    100% {
      text-shadow: 0.25em 0 0 ${colors.lightGrey2},
        0.5em 0 0 ${colors.lightGrey2};
    }
  }
`;

export const LoadingWrapper = styled(SmallHeaderText)`
  font-size: 20px;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    font-size: 18px;
  }
  ${textWithLoadingDots}
`;

export const BoldText = styled.div`
  margin-left: 5px;
  margin-right: 3px;
  color: ${({ theme }) => theme.primary};
  text-decoration: underline;
`;
