import React, { Fragment, useState, useEffect } from "react";
import { PrimaryButton } from "../../../../shared/FormElements";
import { formatCurrency } from "../../../../helpers/utils";
import RaiseView from "./RaiseView";
import UnnecessaryFoldView from "./UnnecessaryFoldView";
import { ActionButtonWrapper } from "../shared";
import { actions } from "../../../../helpers/constants";

const MainPlayActions = ({
  isMobile,
  smallBlindCents,
  betOnTableCents,
  ownBetOnTableCents,
  ownStackCents,
  minRaiseByCents,
  emitSocketAction,
  setShowChat,
  faceUpHoleCards,
  activePlayers,
  username,
  betsOnTableSumCents,
  mainPotCents,
  canUserCheck,
  callAmount,
  betButtonsOnRight,
  setCurrentModal,
  isInCents,
}) => {
  const [betValueCents, setBetValueCents] = useState(null);
  const [actionDone, setActionDone] = useState(false);
  const [showRaiseView, _setShowRaiseView] = useState(false);
  const [showUnnecessaryFoldView, setShowUnnecessaryFoldView] = useState(false);

  const maxBetSliderCents = betOnTableCents
    ? ownBetOnTableCents + ownStackCents
    : ownStackCents;
  const minBetSliderCents = betOnTableCents
    ? betOnTableCents + minRaiseByCents
    : 2 * smallBlindCents;

  const minBetCents = betOnTableCents
    ? Math.min(
        ownStackCents + ownBetOnTableCents,
        betOnTableCents + minRaiseByCents
      )
    : Math.min(ownStackCents, betOnTableCents + minRaiseByCents);

  /**
   * If other players all have zero balance, don't show ability to re-raise
   */
  const otherPlayersHaveZeroBalance = () => {
    let allZeroBalance = true;
    activePlayers
      .filter((player) => player.isAtTable && player.playerName !== username)
      .forEach((player) => {
        if (player.playerBalance !== 0) {
          allZeroBalance = false;
        }
      });
    return allZeroBalance;
  };

  const canUserBet =
    betOnTableCents < ownStackCents + ownBetOnTableCents &&
    !otherPlayersHaveZeroBalance();

  const canUserRaiseAllIn = minBetSliderCents >= maxBetSliderCents;

  useEffect(() => {
    // reset action done when cards are dealt
    setActionDone(false);
  }, [faceUpHoleCards]);

  const onCallClick = (e) => {
    emitSocketAction(actions.CALL, -1);
    setActionDone(true);
  };

  const onBetClick = () => {
    const bet = betValueCents || minBetCents;

    const isBetInRange = bet <= maxBetSliderCents && bet >= minBetSliderCents;

    const isRaiseAllIn = minBetSliderCents > maxBetSliderCents;

    if (isBetInRange || isRaiseAllIn) {
      const cents = parseInt(bet);
      emitSocketAction(actions.RAISE, cents);
      setActionDone(true);
      setShowRaiseView(false);
    }
  };

  const setShowRaiseView = (show) => {
    _setShowRaiseView(show);
    setShowChat(!show);
  };

  const onFoldClick = (e) => {
    emitSocketAction(actions.FOLD, -1);
    setActionDone(true);
  };

  if (actionDone) {
    return <Fragment />;
  }

  if (showRaiseView) {
    return (
      <ActionButtonWrapper betButtonsOnRight={betButtonsOnRight}>
        <RaiseView
          isMobile={isMobile}
          betOnTableCents={betOnTableCents}
          smallBlindCents={smallBlindCents}
          minBetCents={minBetCents}
          minBetSliderCents={minBetSliderCents}
          maxBetSliderCents={maxBetSliderCents}
          onBetClick={onBetClick}
          setShowRaiseView={setShowRaiseView}
          callAmountCents={callAmount}
          ownBetOnTableCents={ownBetOnTableCents}
          betValueCents={betValueCents}
          setBetValueCents={setBetValueCents}
          betsOnTableSumCents={betsOnTableSumCents}
          mainPotCents={mainPotCents}
          bigBlindCents={smallBlindCents * 2}
          isPreFlop={faceUpHoleCards.length === 0}
          setCurrentModal={setCurrentModal}
          isInCents={isInCents}
        />
      </ActionButtonWrapper>
    );
  }

  if (showUnnecessaryFoldView) {
    return (
      <ActionButtonWrapper betButtonsOnRight={betButtonsOnRight}>
        <UnnecessaryFoldView
          isMobile={isMobile}
          onFoldClick={onFoldClick}
          setShowUnnecessaryFoldView={setShowUnnecessaryFoldView}
        />
      </ActionButtonWrapper>
    );
  }

  return (
    <ActionButtonWrapper betButtonsOnRight={betButtonsOnRight}>
      {canUserBet && (
        <PrimaryButton
          type="button"
          dataTestId={
            canUserRaiseAllIn
              ? "bet-raise-all-in-view-button"
              : "bet-raise-view-button"
          }
          keyboardChar="R"
          onClick={() => {
            if (canUserRaiseAllIn) {
              // Raise all-in
              onBetClick();
            } else {
              setShowRaiseView(true);
            }
          }}
        >
          {canUserRaiseAllIn
            ? "Raise all-in"
            : betOnTableCents
            ? `Raise to`
            : `Bet`}
        </PrimaryButton>
      )}

      <PrimaryButton
        dataTestId="call-check-button"
        onClick={onCallClick}
        keyboardChar="C"
      >
        {canUserCheck
          ? `Check`
          : `Call ${formatCurrency(callAmount, isInCents)}`}
      </PrimaryButton>

      <PrimaryButton
        onClick={(e) => {
          if (canUserCheck) {
            setShowUnnecessaryFoldView(true);
          } else {
            onFoldClick(e);
          }
        }}
        secondaryColor
        keyboardChar="F"
        dataTestId="fold-button"
      >
        Fold
      </PrimaryButton>
    </ActionButtonWrapper>
  );
};

export default MainPlayActions;
