import React from "react";
import Modal from "../../../shared/Modal";
import { SmallBodyText } from "../../../shared/Text";
import styled from "styled-components";

const DisconnectedModal = ({ show, onBtnClick, hasJoinedGame }) => {
  let innerText = hasJoinedGame ? (
    <Label>
      Our server is updating! Don&apos;t worry, your game will resume
      <br />
      exactly where you left off.
      <br />
      <br />
      You should automatically reconnect in a minute. If not, try refreshing the
      <br />
      page in a minute to resume playing!
    </Label>
  ) : (
    <Label>
      Our server is updating! It should only take a minute.
      <br />
      <br />
      Refresh the page soon to try again.
    </Label>
  );
  return (
    <Modal
      show={show}
      title="Server updating!"
      btnText="Refresh"
      onBtnClick={onBtnClick}
      dataTestId="refresh-button"
    >
      <div>{innerText}</div>
    </Modal>
  );
};

const Label = styled(SmallBodyText)`
  color: ${({ theme }) => theme.text.label};
`;

export default DisconnectedModal;
