export const spectatorReducer = (state, action) => {
  const spectators = action.payload.spectators;
  let newActiveSpectators = [];
  let newIsGod = false;
  for (let pos in spectators) {
    let newSpectator = {
      playerName: spectators[pos].name,
      connected: spectators[pos].connected,
    };
    // if is client spectator
    // if (spectators[pos].name === state.username) {
    //   newIsGod = spectators[pos].is_god;
    // }
    // newActiveSpectators.push(newSpectator);
    // console.log(newActiveSpectators);
  }
  return {
    activeSpectators: newActiveSpectators,
    isGod: newIsGod,
  };
};
