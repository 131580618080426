import React, { useState, useEffect } from "react";
import Modal, { transitionTime } from "../../../shared/Modal";
import styled from "styled-components";

const RaisePercentInfoModal = ({ hideModal, show }) => {
  const [startTransition, setStartTransition] = useState(true);

  const close = () => {
    setStartTransition(true);
    setTimeout(() => {
      hideModal(false);
    }, transitionTime);
  };

  useEffect(() => {
    // if showing again, reset
    if (show) {
      setStartTransition(false);
    }
  }, [show]);

  return (
    <Modal
      title="How is percent pot to raise calculated?"
      show={true}
      onOverlayClick={close}
      dataTestId=""
      isInvalid={false}
      transitionOut={startTransition}
      margin
    >
      <Wrapper>
        <FullWidthImage
          src={`${process.env.PUBLIC_URL}/assets/explanation.png`}
          alt="explanation for raise % calculation"
        />
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  padding: 0 20px;
`;

const FullWidthImage = styled.img`
  width: 100%;
`;

export default RaisePercentInfoModal;
