import React, { useContext, useState } from "react";
import styled from "styled-components";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
  roles,
  modals,
} from "../../../helpers/constants";
import { GameContext } from "../../../context/GameContext";
import { BrowserContext } from "../../../context/BrowserContext";
import * as Actions from "./actions";
import { LoadingWrapper, BoldText } from "../../../shared/Text";

const ActionButtons = ({ emitSocketMessage, emitSocketAction, numWinners }) => {
  const {
    smallBlindCents,
    maxPotCents,
    stillInHand,
    stillInGame,
    ownPotCents,
    showBuyIn,
    minRaise,
    ownStackCents,
    faceUpHoleCards,
    activePlayers,
    username,
    mainPotCents,
    betsOnTableSumCents,
    updateQueuedAction,
    queuedAction,
    willExecuteQueuedAction,
    ongoing,
    isSpectating,
    isGod,
    isTournament,
    handTimerLengthSeconds,
    paused,
    isInCents,
  } = useContext(GameContext);

  const {
    isMobile,
    setShowChat,
    betButtonsOnRight,
    setCurrentModal,
  } = useContext(BrowserContext);

  const clientPlayer = activePlayers.filter(
    (player) => player.playerName === username
  )[0];
  const adminPlayer = activePlayers.filter(
    (player) => player.playerRole === roles.ROLE_ADMIN
  )[0];
  const numPlayersInGame = activePlayers.filter(
    (player) => player.playerIsInGame
  ).length;
  const numValidPlayers = activePlayers.filter(
    (player) => player.playerBalance > 0
  ).length;
  const numRequestedBuyIns = activePlayers.filter(
    (player) => player.requestedBuyIn > 0
  ).length;
  const numPlayersSittingIn = activePlayers.filter(
    (player) => player.queuedAction !== "ACTION_STAND"
  ).length;
  const numPlayersConnected = activePlayers.filter((player) => player.connected)
    .length;
  const isMyTurn = clientPlayer ? clientPlayer.playerIsTurn : false;
  const canUserCheck = !(maxPotCents && ownPotCents < maxPotCents);
  const callAmount = maxPotCents - ownPotCents;
  const [patreonShown, setPatreonShown] = useState(false);

  let actionButtons;

  if (isSpectating) {
    if (numWinners > 0) {
      actionButtons = (
        <Actions.ShowHandAction
          numWinners={numWinners}
          isSpectating={isSpectating}
        />
      );
    } else {
      if (isGod) {
        actionButtons = (
          <LoadingWrapper>You are currently spectating as god</LoadingWrapper>
        );
      } else {
        actionButtons = (
          <LoadingWrapper>You are currently spectating</LoadingWrapper>
        );
      }
    }
  } else if (showBuyIn && !isTournament) {
    actionButtons = (
      <Actions.BuyInActions
        smallBlindCents={smallBlindCents}
        emitSocketMessage={emitSocketMessage}
        isSpectating={isSpectating}
        isInCents={isInCents}
      />
    );
  } else if (
    stillInHand &&
    isMyTurn &&
    !willExecuteQueuedAction &&
    numWinners === 0
  ) {
    actionButtons = (
      <Actions.MainPlayActions
        isMobile={isMobile}
        smallBlindCents={smallBlindCents}
        canUserCheck={canUserCheck}
        callAmount={callAmount}
        betOnTableCents={maxPotCents}
        betsOnTableSumCents={betsOnTableSumCents}
        ownBetOnTableCents={ownPotCents}
        ownStackCents={ownStackCents}
        minRaiseByCents={minRaise}
        emitSocketAction={emitSocketAction}
        setShowChat={setShowChat}
        faceUpHoleCards={faceUpHoleCards}
        activePlayers={activePlayers}
        username={username}
        mainPotCents={mainPotCents}
        betButtonsOnRight={betButtonsOnRight}
        setCurrentModal={setCurrentModal}
        isInCents={isInCents}
      />
    );
  } else if (stillInHand && !isMyTurn && numWinners === 0) {
    actionButtons = (
      <Actions.PreTurnActions
        updateQueuedAction={updateQueuedAction}
        selectedAction={queuedAction}
        callAmount={callAmount}
        canUserCheck={canUserCheck}
        betButtonsOnRight={betButtonsOnRight}
        isInCents={isInCents}
      />
    );
  } else if (clientPlayer && !stillInGame && ownStackCents > 0) {
    actionButtons = (
      <LoadingWrapper>You are currently sitting out</LoadingWrapper>
    );
  } else if (ongoing && numWinners > 0 && stillInGame) {
    if (
      clientPlayer.isWinner &&
      clientPlayer.playerBalance > 0 &&
      // on 5th win and then every 10th win
      (clientPlayer.handsWon === 5 || clientPlayer.handsWon % 10 === 0)
    ) {
      setCurrentModal(modals.PATREON);
    }

    actionButtons = (
      <Actions.ShowHandAction
        emitSocketMessage={emitSocketMessage}
        clientPlayer={clientPlayer}
        numWinners={numWinners}
        stillInHand={stillInHand}
        showingHand={clientPlayer.showingHand}
        handTimerLengthSeconds={handTimerLengthSeconds}
      />
    );
  } else if (
    !ongoing &&
    numPlayersSittingIn >= 2 &&
    numPlayersInGame >= 2 &&
    numValidPlayers >= 2 &&
    numPlayersConnected >= 2 &&
    adminPlayer
  ) {
    actionButtons = (
      <Actions.StartGameAction
        emitSocketMessage={emitSocketMessage}
        adminName={adminPlayer.playerName}
        clientRole={clientPlayer.playerRole}
        restart={paused}
      />
    );
  } else if (!ongoing && clientPlayer) {
    if (clientPlayer.playerRole === roles.ROLE_ADMIN) {
      if (numRequestedBuyIns > 0) {
        actionButtons = (
          <LoadingWrapper>Waiting for you to approve buy ins</LoadingWrapper>
        );
      } else if (numValidPlayers < 2) {
        actionButtons = (
          <LoadingWrapper>Waiting for others to buy in</LoadingWrapper>
        );
      }
    } else {
      if (clientPlayer.requestedBuyIn > 0 && adminPlayer) {
        actionButtons = (
          <LoadingWrapper>
            Waiting for <BoldText>{adminPlayer.playerName}</BoldText> to approve
            buy in
          </LoadingWrapper>
        );
      }
    }
  } else if (!stillInHand) {
    actionButtons = null;
    if (clientPlayer && clientPlayer.requestedBuyIn > 0 && adminPlayer) {
      actionButtons = (
        <LoadingWrapper>
          Waiting for <BoldText>{adminPlayer.playerName}</BoldText> to approve
          buy in
        </LoadingWrapper>
      );
    }
  }

  return (
    <>
      <ActionWrapper betButtonsOnRight={betButtonsOnRight}>
        {actionButtons}
      </ActionWrapper>
    </>
  );
};

const ActionWrapper = styled.div`
  position: absolute;
  z-index: 2;
  ${(props) => (props.betButtonsOnRight ? `right: 20px` : `left: 20px`)}
  bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: right;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    right: 5px;
    bottom: 15px;
    position: fixed;
  }
`;

export default ActionButtons;
