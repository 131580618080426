import styled, { css } from "styled-components";
import { font, colors } from "../../helpers/styles";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../helpers/constants";

const BaseInput = styled.input`
  font-family: ${font.body};
  border: none;
  font-size: 1em;
  background-color: none;
  background: none;
  ${(props) => props.fullWidth && `width: 100%;`}
  &:hover,
  &:focus {
    outline: 0;
  }
`;

export const InputLine = styled(BaseInput)`
  color: ${colors.darkGrey};
  padding: 0.4em 0.4em 0.1em 0.4em;
  transition: all 0.2s ease-out;
  width: 100%;
  border-bottom: 1px solid black;
`;

export const InputText = styled(BaseInput)`
  background: ${({ theme }) => theme.background};
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 16px;
  color: ${({ theme }) => theme.text.mainBody};
  border: 1px solid ${({ theme }) => theme.foreground};
  ${(props) =>
    props.contrast &&
    `
    background: ${props.theme.input.contrast};
  `}
  ${(props) =>
    props.noBack &&
    `
    background: ${props.theme.input.noBack};
  `}
  ${(props) =>
    props.modal &&
    `
    background: ${props.theme.input.modal};
  `}
`;

export const InputField = styled.span`
  font-family: ${font.body};
  display: block;
  position: relative;
  margin-top: ${(props) => props.top}px;
  text-align: center;
  width: 250px;
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
      ${InputText} {
        width: 100%;
      }
    `}
`;

export const InputLabel = styled.label`
  font-family: ${font.body};
  color: #919293;
  box-sizing: border-box;
  display: block;
  font-size: 0.7em;
  line-height: 1;
  opacity: 0;
  overflow: hidden;
  padding: 0.5em 1em;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  top: 0;
  text-align: left;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  z-index: 16;
  ${(props) => props.value && `opacity: 0;`};
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    font-size: 0.7em;
  }
`;

export const TextAreaInput = styled.textarea`
  resize: none;
  margin-top: 20px;
  padding: 8px;
  font-size: 16px;
  font-family: ${font.body};
  border: 1px solid ${({ theme }) => theme.foreground};
  background: ${({ theme }) => theme.input.contrast};
  border-radius: 5px;
  color: ${({ theme }) => theme.text.label};

  &:focus {
    outline: none;
  }
  &::placeholder {
    font-weight: 500;
  }
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    font-size: 0.7em;
  }
`;
