import React from "react";

export function useStateWithLocalStorageString(
  localStorageKey,
  fallBackDefault
) {
  const [value, setValue] = React.useState(
    getLocalStorageItem(localStorageKey) || fallBackDefault
  );

  React.useEffect(() => {
    setLocalStorageItem(localStorageKey, value);
  }, [value]);

  return [value, setValue];
}

export function useStateWithLocalStorageBoolean(
  localStorageKey,
  fallBackDefault
) {
  const [value, setValue] = React.useState(
    getLocalStorageItem(localStorageKey) == null
      ? fallBackDefault
      : getLocalStorageItem(localStorageKey) === "true"
  );

  React.useEffect(() => {
    setLocalStorageItem(localStorageKey, value);
  }, [value]);

  return [value, setValue];
}

function getLocalStorageItem(localStorageKey) {
  try {
    let value = localStorage.getItem(localStorageKey);
    return value;
  } catch (e) {
    console.error("No local storage access!");
    return null;
  }
}

function setLocalStorageItem(localStorageKey, value) {
  try {
    localStorage.setItem(localStorageKey, value);
  } catch (e) {
    console.error("No local storage access!");
  }
}
