import { colors } from "../styles";

export function generateDarkTheme(primary, secondary, tertiary, background) {
  return {
    // general
    primary: primary,
    secondary: colors.mediumGrey,
    secondaryLighter: colors.darkBlueLighter,
    tertiary: tertiary,
    background: background,
    foreground: "#525252",
    disabled: "#8a8a8a",
    profit: colors.green,
    loss: tertiary,
    // components
    card: {
      heart: tertiary,
      diamond: primary,
      spade: colors.black,
      club: colors.green,
      dark: colors.black,
      light: tertiary,
    },
    modal: {
      background: background,
      subtitle: colors.grey,
      overlay: colors.black,
      divider: "#525252",
      cta: secondary,
    },
    chat: {
      background: "#525252",
      divider: background,
      gameText: colors.darkGrey2,
      linkText: colors.lightGrey,
    },
    table: {
      outer: "#525252",
      inner: secondary,
    },
    openSeat: {
      background: secondary,
      borderColor: "#525252",
    },
    player: {
      disabledBackground: background,
      borderColor: "#525252",
      borderHighlightedColor: colors.lightGrey,
      pot: background,
    },
    keyboardChar: {
      background: background,
      text: colors.white,
    },
    input: {
      modal: secondary,
      contrast: background,
      noBack: "#525252",
    },
    switch: {
      background: secondary,
    },
    flatShadow: "#585858",
    // text
    text: {
      mainBody: colors.lightGrey,
      widget: colors.white,
      label: colors.white,
      placeholder: "#525252",
    },
  };
}

const darkTheme = generateDarkTheme(
  colors.blue,
  colors.darkBlue,
  colors.red,
  colors.darkGrey
);

export default darkTheme;
