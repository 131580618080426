import React, { useContext } from "react";
import styled from "styled-components";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
  roles,
} from "../../../helpers/constants";
import * as Actions from "./actions";
import { GameContext } from "../../../context/GameContext";
import { BrowserContext } from "../../../context/BrowserContext";
import { LoadingWrapper, BoldText } from "../../../shared/Text";

const ActionButtons = ({ clientUser }) => {
  const { ownStackCents, username, MTTOngoing, isInCents, MTTId } = useContext(
    GameContext
  );

  let actionButtons;

  if (!MTTOngoing) {
    actionButtons = (
      <Actions.StartMTTRoundAction
        // adminName={adminPlayer.playerName}
        clientRole={clientUser && clientUser.userRole}
        MTTId={MTTId}
      />
    );
  } else {
    actionButtons = <LoadingWrapper>Waiting for Round to End</LoadingWrapper>;
  }

  return (
    <>
      <ActionWrapper>{actionButtons}</ActionWrapper>
    </>
  );
};

const ActionWrapper = styled.div`
  position: absolute;
  z-index: 2;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: right;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    right: 5px;
    bottom: 15px;
    position: fixed;
  }
`;

export default ActionButtons;
