import { colors } from "../styles";
import { themes } from "../constants";
import { generateLightTheme } from "./light";
import { generateDarkTheme } from "./dark";

const themeColors = {
  [themes.THEME_DARK_DEFAULT]: {
    isDark: true,
    colors: {
      blue: colors.blue,
      darkBlue: colors.darkBlue,
      red: colors.red,
      darkGrey: colors.darkGrey,
    },
  },
  [themes.THEME_SALOON]: {
    isDark: true,
    colors: {
      lightGreen: "#654321",
      green: "#006400",
      gold: "#C3976A",
      brown: "#2a1b0e",
    },
  },
  [themes.THEME_LIGHT_DEFAULT]: {
    isDark: false,
    colors: {
      blue: colors.blue,
      darkBlue: colors.darkBlue,
      red: colors.red,
      lightGrey: colors.lightGrey,
    },
  },
  [themes.THEME_DARK_PURPLE]: {
    isDark: true,
    colors: {
      red: "#ff6363",
      purple: "#543864",
      yellow: "#ffa531",
      blueBlack: "#202040",
    },
  },
  [themes.THEME_DARK_GREEN]: {
    isDark: true,
    colors: {
      green: "#3c6562",
      darkGreen: "#00454a",
      lightRed: "#ed6363",
      darkBlue: "#003545",
    },
  },
  [themes.THEME_DARK_BLUE_PINK]: {
    isDark: true,
    colors: {
      blue: "#4d5ade",
      purple: "#4d2b80",
      pink: "#e14594",
      darkBlue: "#182952",
    },
  },
};

const generateSwatch = (themeName) => {
  if (!(themeName in themeColors)) {
    return [];
  }
  return Object.keys(themeColors[themeName]["colors"]).map(
    (key) => themeColors[themeName]["colors"][key]
  );
};

const themesDict = {};
Object.keys(themeColors).forEach((themeName) => {
  const swatch = generateSwatch(themeName);
  const generatedTheme = themeColors[themeName].isDark
    ? generateDarkTheme(...swatch)
    : generateLightTheme(...swatch);
  themesDict[themeName] = {
    theme: generatedTheme,
    swatch: swatch,
  };
});

const defaultTheme = themesDict[themes.THEME_DARK_DEFAULT].theme;
export { defaultTheme };
export default themesDict;
