import React, { useState, Fragment } from "react";
import { colors } from "../../../helpers/styles";
import { PrimaryButton } from "../../../shared/FormElements";
import { LoadingWrapper, BoldText } from "../../../shared/Text";
import { roles } from "../../../helpers/constants";
import styled from "styled-components";

const StartGameAction = ({
  emitSocketMessage,
  adminName,
  clientRole,
  restart = false,
}) => {
  const [actionDone, setActionDone] = useState(false);

  const sendSocketStartGame = () => {
    emitSocketMessage("start_game", {});
    setActionDone(true);
  };

  const onStartGameClick = (e) => {
    sendSocketStartGame();
  };

  let startButton;

  if (actionDone) {
    startButton = <Fragment />;
  } else {
    startButton = (
      <PrimaryButton
        keyboardChar="S"
        dataTestId="start-game-btn"
        onClick={onStartGameClick}
        color={colors.darkBlue}
      >
        {restart ? "Next hand" : "Start Game!"}
      </PrimaryButton>
    );
  }
  return (
    <StartGameWrapper>
      {clientRole === roles.ROLE_ADMIN && startButton}
      {clientRole !== roles.ROLE_ADMIN && (
        <LoadingWrapper>
          Waiting for <BoldText>{adminName}</BoldText> to{" "}
          {restart ? "go to next hand" : "start game"}
        </LoadingWrapper>
      )}
    </StartGameWrapper>
  );
};

const StartGameWrapper = styled.div`
  text-align: right;
`;

export default StartGameAction;
