import React from "react";
import styled, { withTheme } from "styled-components";
import { colors } from "../../../helpers/styles";
import { formatCurrency } from "../../../helpers/utils";
import { MediumBodyText } from "../../../shared/Text";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../../helpers/constants";

const LeaderboardRow = (props) => {
  let color = props.balance >= 0 ? props.theme.profit : props.theme.loss;

  let totalBuyin = formatCurrency(props.buyin, props.isInCents);

  let winAmount = `${Math.sign(props.balance) >= 0 ? "+" : "-"}${formatCurrency(
    Math.abs(props.balance),
    props.isInCents
  )}`;
  return (
    <RowElem
      highlight={props.highlight}
      height={props.height}
      strong={props.strong}
      first={props.first}
      last={props.last}
      data-testid="leaderboard-row"
    >
      <Container>
        <div style={{ display: "flex" }}>
          {props.rank && (
            <Rank>
              <MediumBodyText>{props.rank}</MediumBodyText>
            </Rank>
          )}
          <PlayerName key={props.name}>{props.name}</PlayerName>
        </div>
        <BuyIn>{totalBuyin}</BuyIn>
        <Winnings style={{ color }}>{winAmount}</Winnings>
      </Container>
    </RowElem>
  );
};

export default withTheme(LeaderboardRow);
export const padding = "10px";

const RowElem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 ${padding};
  background: ${({ theme }) => theme.modal.background};

  ${(props) =>
    props.height &&
    `
    ${Container} {
      height: ${props.height}px;
      border: none;
    }
  `};
  ${(props) =>
    props.highlight &&
    props.theme &&
    `
    ${Container} {
      background: ${props.theme.modal.divider};
    }
  `}
  ${(props) =>
    props.strong &&
    `
    ${PlayerName} {
      font-weight: bold;
      font-size: 1.2em;
    }
  `}
  ${(props) =>
    props.first &&
    `
    ${Container} {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  `}
  ${(props) =>
    props.last &&
    props.theme &&
    `
    ${Container} {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom: 2px solid ${props.theme.modal.divider};
    }
  `}
`;

const Rank = styled.div`
  background: ${colors.grey};
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-left: 10px;
  font-weight: bold;
`;

const PlayerName = styled(MediumBodyText)`
  display: flex;
  align-items: center;
  font-size: 1em;
  margin: 0;
  justify-self: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  margin-left: 10px;
  color: ${({ theme }) => theme.text.label};
`;

const BuyIn = styled(MediumBodyText)`
  //text-align: center;
  color: grey;
  font-weight: bold;
  font-size: 1em;
  //margin: 0 10px 0 0px;
`;

const Winnings = styled(MediumBodyText)`
  font-weight: bold;
  font-size: 1em;
  margin: 0 10px 0 0px;
`;

const Container = styled(MediumBodyText)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border: 2px solid ${({ theme }) => theme.modal.divider};
  border-bottom: none;
  padding: 20px 0;

  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    height: auto;
    padding: 5px;
  }
`;
