import React, { useState, useEffect, useContext } from "react";
import { GameContext } from "../../../context/GameContext";
import DropdownOptionsPicker from "../../../shared/DropdownOptionsPicker";
import { Icon } from "../../../shared/FormElements";
import {
  faSignInAlt,
  faSignOutAlt,
  faUndo,
  faCommentAlt,
  faSpinner,
  faEdit,
  faGift,
  faPause,
  faPlay,
  faCoins,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { colors } from "../../../helpers/styles";
import { modals, tableActions, roles } from "../../../helpers/constants";
import {
  ThemeCircle,
  dropdownPositioning,
  mobileDropdownPositioning,
} from "./shared";

const PlayerActions = ({
  show,
  queuedAction,
  emitSocketMessage,
  isStanding,
  setCurrentModal,
  isMobile,
  setShowThemePicker,
  setShowSocialMedia,
  isInCents,
}) => {
  const { activePlayers, username, paused, ongoing } = useContext(GameContext);

  const clientPlayer = activePlayers.filter(
    (player) => player.playerName === username
  )[0];
  const numWinners = activePlayers.filter((player) => player.isWinner).length;
  const hasTurn =
    activePlayers.filter((player) => player.playerIsTurn).length > 0;

  const [isLoadingStand, setIsLoadingStand] = useState(false);

  useEffect(() => {
    setIsLoadingStand(false);
  }, [queuedAction]);

  const toggleAction = () => {
    const actionToDo = isStanding
      ? tableActions.ACTION_SIT
      : tableActions.ACTION_STAND;
    emitSocketMessage("queue_action", {
      action:
        queuedAction !== tableActions.ACTION_NONE
          ? tableActions.ACTION_NONE
          : actionToDo,
    });
    setIsLoadingStand(true);
  };

  const togglePause = () => {
    emitSocketMessage("pause_game", {});
  };

  let buyIn;
  if (
    emitSocketMessage && // player, not spectator
    clientPlayer.requestedBuyIn > 0 &&
    clientPlayer.playerRole !== roles.ROLE_ADMIN
  ) {
    buyIn = {
      name: "Update buy in request to admin",
      inner: <Icon icon={faSpinner} color={colors.white} />,
      onClick: () => setCurrentModal(modals.BUY_IN),
      selected: true,
      showLabel: true,
    };
  } else if (emitSocketMessage && clientPlayer.pendingBuyIn > 0) {
    buyIn = {
      name: "Change pending buy in",
      inner: <Icon icon={faEdit} color={colors.white} />,
      onClick: () => setCurrentModal(modals.BUY_IN),
      showLabel: true,
    };
  } else {
    buyIn = {
      name: "Buy in for more",
      inner: (
        <>
          <>+ </>
          {isInCents && <Icon icon={faDollarSign} color={colors.white} />}
          {!isInCents && <Icon icon={faCoins} color={colors.white} />}
        </>
      ),
      onClick: () => setCurrentModal(modals.BUY_IN),
      showLabel: true,
    };
  }

  let pauseNextHand;
  if (emitSocketMessage && clientPlayer.playerRole === roles.ROLE_ADMIN) {
    if ((ongoing && numWinners > 0) || !ongoing || !hasTurn) {
      pauseNextHand = {
        name: "Cannot pause",
        inner: paused ? (
          <Icon icon={faPlay} color={colors.white} disabled={true} />
        ) : (
          <Icon icon={faPause} color={colors.white} disabled={true} />
        ),
        showLabel: true,
        disabled: true,
      };
    } else {
      pauseNextHand = {
        name: paused ? "Unpause game" : "Pause game after this hand",
        inner: paused ? (
          <Icon icon={faPlay} color={colors.white} />
        ) : (
          <Icon icon={faPause} color={colors.white} />
        ),
        onClick: togglePause,
        showLabel: true,
      };
    }
  }

  let sitOut;
  if (queuedAction !== tableActions.ACTION_NONE) {
    sitOut = {
      name: `Undo ${isStanding ? "join game" : "sit out"}`,
      inner: <Icon icon={faUndo} color={colors.white} />,
      onClick: toggleAction,
      selected: true,
      isLoading: isLoadingStand,
      dataTestId: "cancel-sit-out-action",
      showLabel: true,
    };
  } else {
    sitOut = {
      name: `${isStanding ? "Join game" : "Sit out"} next hand`,
      inner: (
        <Icon
          icon={isStanding ? faSignInAlt : faSignOutAlt}
          color={colors.white}
        />
      ),
      onClick: toggleAction,
      selected: false,
      isLoading: isLoadingStand,
      dataTestId: `${isStanding ? "join-game" : "sit-out"}`,
      showLabel: true,
    };
  }

  const feedback = {
    name: "Share your feedback",
    inner: (
      <>
        <Icon icon={faCommentAlt} color={colors.white} />
      </>
    ),
    onClick: () => setCurrentModal(modals.FEEDBACK),
    showLabel: true,
  };
  const changeTheme = {
    name: "Change theme",
    inner: <ThemeCircle />,
    onClick: () => setShowThemePicker(true),
    showLabel: true,
  };

  const socialMedia = {
    name: "Check out what we're up to! 👀",
    inner: (
      <>
        <Icon icon={faGift} color={colors.white} />
      </>
    ),
    onClick: () => setShowSocialMedia(true),
    showLabel: true,
  };

  const actionsList = emitSocketMessage
    ? pauseNextHand
      ? [buyIn, sitOut, pauseNextHand, feedback, changeTheme, socialMedia]
      : [buyIn, sitOut, feedback, changeTheme, socialMedia]
    : [feedback, changeTheme, socialMedia];

  return (
    <DropdownOptionsPicker
      show={show}
      dataTestId="player-action-dropdown"
      optionsList={actionsList}
      style={isMobile ? mobileDropdownPositioning : dropdownPositioning}
      includeOverlay={isMobile}
      initialExpanded={!isMobile}
    />
  );
};

export default PlayerActions;
