import React from "react";
import { SmallBodyText, HighlightedText } from "../../../shared/Text";
import Modal from "../../../shared/Modal";

const SiteIntroModal = (props) => {
  return (
    <Modal
      show
      title="Lipoker"
      subtitle="Welcome to our beta launch"
      btnText="Start playing"
      onBtnClick={props.continue}
      dataTestId="start-button"
      onBtnAltClick={props.continueAlt}
      // btnTextAlt="Create MTT"
    >
      <div style={{ width: "100%", padding: "0 40px" }}>
        <SmallBodyText align="center" themeable>
          lipoker is the simplest, fastest way to play poker in browser. <br />
          Just share the link to invite your friends to play! <br />
          <b>{props.numGames}</b> games played!
          <br />
          <br />
          <HighlightedText>No downloads. No signup. No hassle.</HighlightedText>
        </SmallBodyText>
      </div>
    </Modal>
  );
};

export default SiteIntroModal;
