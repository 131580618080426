import {
  faSmileBeam,
  faSmile,
  faMeh,
  faFrown,
  faFrownOpen,
} from "@fortawesome/free-solid-svg-icons";

export const optionsOrderedByRating = [
  {
    icon: faFrownOpen,
    textResponse: "Oh no :( How can we make your experience better?",
  },
  {
    icon: faFrown,
    textResponse: "Oh no :( How can we make your experience better?",
  },
  {
    icon: faMeh,
    textResponse: "How can we make your experience better?",
  },
  {
    icon: faSmile,
    textResponse: "Great! What do you like or want to see more of?",
  },
  {
    icon: faSmileBeam,
    textResponse: "Great! What do you like or want to see more of?",
  },
];
