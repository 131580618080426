const offsets = {
  xCenterRight: "75.9%",
  xCenterLeft: "24.1%",
  xEdgeRight: "94%",
  xEdgeLeft: "6%",
  yTop: "-18%",
  yCenterTop: "4%",
  yCenterBottom: "58%",
  yBottom: "80%",
};

const xBetEdge = 21;
const xBetCenterOff = 24.1;

const betOffsets = {
  yTop: "11%",
  yCenterTop: "24%",
  yCenterBottom: "44%",
  yBottom: `60%`,
  xCenterRight: `${100 - xBetCenterOff}%`,
  xFarRight: `${100 - xBetEdge}%`,
  xCenter: "50%",
  xCenterLeft: `${xBetCenterOff}%`,
  xFarLeft: `${xBetEdge}%`,
};

export const playerStyles = {
  playerPos: {
    "0": {
      left: offsets.xCenterRight,
      top: offsets.yTop,
    },
    "1": {
      left: offsets.xEdgeRight,
      top: offsets.yCenterTop,
    },
    "2": {
      left: offsets.xEdgeRight,
      top: offsets.yCenterBottom,
    },
    "3": {
      left: offsets.xCenterRight,
      top: offsets.yBottom,
    },
    "4": {
      left: "50%",
      top: offsets.yBottom,
    },
    "5": {
      left: offsets.xCenterLeft,
      top: offsets.yBottom,
    },
    "6": {
      left: offsets.xEdgeLeft,
      top: offsets.yCenterBottom,
    },
    "7": {
      left: offsets.xEdgeLeft,
      top: offsets.yCenterTop,
    },
    "8": {
      left: offsets.xCenterLeft,
      top: offsets.yTop,
    },
  },
  centralPotPos: {
    left: "50%",
    top: "25.5%",
    transform: "translate(-50%, -50%)",
    msTransform: "translate(-50%, -50%)",
    WebkitTransform: "translate(-50%, -50%)",
  },
  upperPotPos: {
    left: "50%",
    top: "22.5%",
    transform: "translate(-50%, -50%)",
    msTransform: "translate(-50%, -50%)",
    WebkitTransform: "translate(-50%, -50%)",
  },
  lowerPotPos: {
    left: "50%",
    top: "29.5%",
    transform: "translate(-50%, -50%)",
    msTransform: "translate(-50%, -50%)",
    WebkitTransform: "translate(-50%, -50%)",
  },
  playerBetPos: {
    "0": {
      left: betOffsets.xCenterRight,
      top: betOffsets.yTop,
      transform: "translate(-50%, -50%)",
      msTransform: "translate(-50%, -50%)",
      WebkitTransform: "translate(-50%, -50%)",
    },
    "1": {
      left: betOffsets.xFarRight,
      top: betOffsets.yCenterTop,
      transform: "translate(-50%, -50%)",
      msTransform: "translate(-50%, -50%)",
      WebkitTransform: "translate(-50%, -50%)",
    },
    "2": {
      left: betOffsets.xFarRight,
      bottom: betOffsets.yCenterBottom,
      transform: "translate(-50%, -50%)",
      msTransform: "translate(-50%, -50%)",
      WebkitTransform: "translate(-50%, -50%)",
    },
    "3": {
      left: betOffsets.xCenterRight,
      top: betOffsets.yBottom,
      transform: "translate(-50%, -50%)",
      msTransform: "translate(-50%, -50%)",
      WebkitTransform: "translate(-50%, -50%)",
    },
    "4": {
      left: betOffsets.xCenter,
      top: betOffsets.yBottom,
      transform: "translate(-50%, -50%)",
      msTransform: "translate(-50%, -50%)",
      WebkitTransform: "translate(-50%, -50%)",
    },
    "5": {
      left: betOffsets.xCenterLeft,
      top: betOffsets.yBottom,
      transform: "translate(-50%, -50%)",
      msTransform: "translate(-50%, -50%)",
      WebkitTransform: "translate(-50%, -50%)",
    },
    "6": {
      left: betOffsets.xFarLeft,
      bottom: betOffsets.yCenterBottom,
      transform: "translate(-50%, -50%)",
      msTransform: "translate(-50%, -50%)",
      WebkitTransform: "translate(-50%, -50%)",
    },
    "7": {
      left: betOffsets.xFarLeft,
      top: betOffsets.yCenterTop,
      transform: "translate(-50%, -50%)",
      msTransform: "translate(-50%, -50%)",
      WebkitTransform: "translate(-50%, -50%)",
    },
    "8": {
      left: betOffsets.xCenterLeft,
      top: betOffsets.yTop,
      transform: "translate(-50%, -50%)",
      msTransform: "translate(-50%, -50%)",
      WebkitTransform: "translate(-50%, -50%)",
    },
  },
};
