import React, { useState, useEffect } from "react";
import Card from "./";
import CardBack from "./CardBack";
import sizes from "./sizes";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../helpers/constants";
import styled from "styled-components";

const FlipCard = (props) => {
  const { isFlat, ...rest } = props;
  const [isFaceUp, setIsFaceUp] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsFaceUp(true);
    }, 400);
  }, []);

  return (
    <FlipCardOuter size={props.size} isFaceUp={isFaceUp}>
      <FlipCardInner>
        <FlipCardBack>
          <CardBack />
        </FlipCardBack>
        <FlipCardFront>
          <Card {...rest} />
        </FlipCardFront>
      </FlipCardInner>
    </FlipCardOuter>
  );
};

const FlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.2s cubic-bezier(0.47, 0, 0.75, 0.72);
  transform-style: preserve-3d;
`;

const FlipCardOuter = styled.div`
  background-color: transparent;
  ${(props) =>
    props.size &&
    `
    height: ${sizes[props.size]["height"]};
    width: ${sizes[props.size]["width"]};
  `}
  perspective: 1000px;
  margin: 5px;
  ${(props) =>
    props.isFaceUp &&
    `
    ${FlipCardInner} {
      transform: rotateY(180deg);
    }
  `}
  @media screen and (max-width: ${mobileBreakpoint}), screen and (max-height: ${mobileHeightBreakpoint}) {
    margin: 2px;
  }
`;

const FlipCardInnerChild = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  margin: 0;
  border-radius: 5px;
  overflow: hidden;
`;

const FlipCardFront = styled(FlipCardInnerChild)`
  transform: rotateY(180deg);
`;

const FlipCardBack = styled(FlipCardInnerChild)``;

export default FlipCard;
