import React, { useState, useRef } from "react";
import MouseEvents from "./MouseEvents";
import styled from "styled-components";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../helpers/constants";
import { InputText } from "../../shared/FormElements";
import { SmallBodyText } from "../../shared/Text";
import { emit } from "../../helpers/socket";
import { CHAT_STATE } from "./utils";
import KeyboardChar from "../../shared/FormElements/Button/KeyboardChar";

const ChatInput = ({
  username,
  gameId,
  sessionKey,
  chatIsFocused,
  setAllowKeyboardShortcuts,
  setChatIsFocused,
  existsModal,
  chatState,
}) => {
  const [inputMessage, setInputMessage] = useState("");
  const chatInput = useRef(null);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (inputMessage !== "") {
      emit("send_message", {
        username: username,
        game_id: gameId,
        key: sessionKey,
        msg: inputMessage,
      });
      setInputMessage("");
    }
  };

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  const handleEscPress = (e) => {
    chatInput.current.blur();
    setChatIsFocused(false);
    setAllowKeyboardShortcuts(true);
  };

  return (
    <>
      <MouseEvents
        setAllowKeyboardShortcuts={setAllowKeyboardShortcuts}
        existsModal={existsModal}
        setChatIsFocused={setChatIsFocused}
        onEscPress={handleEscPress}
        focusElement="send-msg-input"
      />
      <SendMessageForm
        onSubmit={handleSendMessage}
        isFocused={chatIsFocused}
        id="chat-send-msg"
      >
        <SendMessageInput
          id="send-msg-input"
          onChange={handleInputChange}
          value={inputMessage}
          ref={chatInput}
          autoComplete="off"
        />
        <MessageHelperText show={!chatIsFocused && inputMessage.length === 0}>
          <KeyboardChar
            char="m"
            foregroundColor
            style={{ marginLeft: "5px", marginTop: "3px" }}
            onClick={() => {
              if (chatState !== CHAT_STATE.COLLAPSED) {
                chatInput.current.focus();
                setAllowKeyboardShortcuts(false);
                setChatIsFocused(true);
              }
            }}
          />
          <SmallBodyText style={{ marginLeft: "10px", fontWeight: "bold" }}>
            Send your message here!
          </SmallBodyText>
        </MessageHelperText>
      </SendMessageForm>
    </>
  );
};

const SendMessageForm = styled.form`
  width: 100%;
  margin-top: 5px;
  height: 30px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 30px;
  display: flex;
  transition: 0.3s ease all;
  border-top: 1px solid ${({ theme }) => theme.chat.divider};
  ${(props) =>
    props.isFocused &&
    `
    height: 70px;
    flex-basis: 70px;
  `}
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    padding: 0 5px 5px 5px;
    height: auto;
  }
`;

const SendMessageInput = styled(InputText)`
  width: 100%;
  margin: 0;
`;

const MessageHelperText = styled.div`
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.text.placeholder};
  padding: 0 10px;
  ${(props) =>
    !props.show &&
    `
    opacity: 0;
  `}
`;

export default ChatInput;
