import React, { useHistory, useContext } from "react";
import Modal from "../../../shared/Modal";
import { SmallBodyText } from "../../../shared/Text";
import styled from "styled-components";
import { GameContext } from "../../../context/GameContext";

const MTTGameEndModal = () => {
  // const history = useHistory();
  const { ownStackCents, MTTId, updateDisplay } = useContext(GameContext);
  const roundEndText = (
    <Label>
      This table has ended!
      <br />
      {ownStackCents == 0
        ? "You have been eliminated, click to go back to the lobby."
        : "You have qualified for the next round! click to go back to the lobby and wait for your next game."}
    </Label>
  );

  const goToLobby = () => {
    updateDisplay("mtt");
  };

  return (
    <Modal
      show={true}
      title="Round over"
      btnText="Lobby"
      onBtnClick={goToLobby}
      dataTestId="mtt-next-round-button"
    >
      <div>{roundEndText}</div>
    </Modal>
  );
};

const Label = styled(SmallBodyText)`
  color: ${({ theme }) => theme.text.label};
`;

export default MTTGameEndModal;
