import React from "react";
import { colors, font } from "../../helpers/styles";
import styled from "styled-components";

const ActionBar = ({
  show,
  actionsList,
  style,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  return (
    <ActionBarContent
      style={style}
      show={show}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {actionsList.map((action) => (
        <ActionBarItemWrapper key={action.name}>
          <ActionBarItemLabel>{action.name}</ActionBarItemLabel>
          <ActionBarItem
            data-testid={`${action.dataTestId}-btn`}
            onClick={action.onClick}
            selected={action.selected}
            isLoading={action.isLoading}
          >
            {action.inner}
          </ActionBarItem>
        </ActionBarItemWrapper>
      ))}
    </ActionBarContent>
  );
};

const ActionBarContent = styled.div`
  background: ${colors.lightGrey2};
  border: 1px solid ${({ theme }) => theme.foreground};
  border-radius: 30px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  ${(props) =>
    !props.show &&
    `
    opacity: 0;
    pointer-events: none;
  `}
  user-select: none;
`;

const ActionBarItemLabel = styled.div`
  position: absolute;
  pointer-events: none;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -100%);
  background: ${({ theme }) => theme.foreground};
  color: ${colors.black};
  font-weight: bold;
  font-family: ${font.body};
  font-size: 12px;
  border-radius: 5px;
  min-width: max-content;
  padding: 2px 5px;
  opacity: 0;
`;

const ActionBarItem = styled.div`
  background: ${(props) =>
    props.selected
      ? ({ theme }) => theme.secondary
      : ({ theme }) => theme.primary};
  cursor: pointer;
  font-family: ${font.body};
  ${(props) =>
    props.isLoading &&
    `
    pointer-events: none;
    background: ${({ theme }) => theme.foreground};
  `}
  height: 30px;
  border-radius: 30px;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease transform;
  transform-origin: bottom;
  padding: 4px;
`;

const ActionBarItemWrapper = styled.div`
  position: relative;
  margin-left: 10px;
  &:first-child {
    margin: 0;
  }
  &:hover {
    ${ActionBarItem} {
      transform: scale(1.2);
      background: ${({ theme }) => theme.secondary};
    }
    ${ActionBarItemLabel} {
      opacity: 1;
    }
  }
`;

export default ActionBar;
