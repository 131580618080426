import React, { useState, useEffect } from "react";
import Modal, { transitionTime } from "../../../shared/Modal";
import Card from "../../../shared/Card";
import { handsList, nameMap } from "./handsList";
import { MediumBodyText } from "../../../shared/Text";
import styled from "styled-components";

const HandRankingsModal = ({
  hideModal,
  show,
  isFourColorDeck,
  isMobile,
  playerHand,
}) => {
  const [startTransition, setStartTransition] = useState(true);

  const close = () => {
    setStartTransition(true);
    setTimeout(() => {
      hideModal(false);
    }, transitionTime);
  };

  useEffect(() => {
    // if showing again, reset
    if (show) {
      setStartTransition(false);
    }
  }, [show]);

  return (
    <Modal
      show={true}
      onOverlayClick={close}
      dataTestId="info-submit-button"
      isInvalid={false}
      transitionOut={startTransition}
      margin
      scrollMiddle={!isMobile}
    >
      {handsList.map((row, i) => {
        const cardsList = row.cards;
        const highlight =
          playerHand in nameMap ? nameMap[playerHand] === row.name : false;
        return (
          <HandRow key={`hand-row-${i}`}>
            <HandText align="left">{row.name}</HandText>
            <CardsRow highlight={highlight}>
              {cardsList.map((card, j) => (
                <Card
                  key={`${row.name}-${j}`}
                  suit={card.suit}
                  number={card.number}
                  isEmpty={card.isEmpty}
                  size="mobile"
                  isFourColorDeck={isFourColorDeck}
                  enforceCornerSuit={!isMobile}
                  isFlat
                  border
                />
              ))}
            </CardsRow>
          </HandRow>
        );
      })}
    </Modal>
  );
};
const HandRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0 60px;
`;
const CardsRow = styled.div`
  display: flex;
  ${(props) =>
    props.highlight &&
    props.theme &&
    `
    border: 2px solid ${props.theme.foreground};
    border-radius: 5px;
    background: ${props.theme.foreground};
  `}
`;

const HandText = styled(MediumBodyText)`
  width: 100%;
  font-weight: bold;
  color: ${({ theme }) => theme.text.widget};
`;

export default HandRankingsModal;
