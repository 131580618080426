import styled from "styled-components";
import { font, colors } from "../../../helpers/styles";
import * as Base from "./Base";
import Icon from "./Icon";
import PrimaryButton from "./PrimaryButton";
export { PrimaryButton, Icon };

export const OutlineButton = styled(Base.Button)`
  font-weight: 600;
  border: 2px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
  padding: 2px 8px;
  color: ${({ theme }) => theme.tertiary};
  letter-spacing: 0.1px;
  background: none;
  width: auto;
  &:hover {
    background: ${({ theme }) => theme.tertiary};
    color: ${colors.white};
  }
`;

export const ModalButton = styled(Base.Button)`
  height: 40px;
  min-height: 40px;
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
  border: 1px solid ${({ theme }) => theme.modal.background};
  margin-left: 10px;
  &:hover {
    opacity: 0.9;
  }
`;

export const HeaderButton = styled(Base.Button)`
  margin-left: 5px;
  margin-right: 5px;
`;

export const IconButton = styled(Base.BaseButton)`
  width: 30px;
  border: none;
  background: none;
  color: ${({ theme }) => theme.tertiary};
`;

export const BackgroundButton = styled(Base.Button)`
  font-family: ${font.header};
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 15px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  height: auto;
`;

export const SecondaryButton = styled(Base.Button)`
  width: auto;
  height: auto;
  background: ${({ theme }) => theme.foreground};
  border-radius: 5px;
  padding: 8px;
  border: none;
  color: ${colors.darkGrey2};
  font-size: 18px;
  width: 100%;
  margin: 0 5px;
  ${(props) => props.firstInList && `margin-left: 0;`}
  ${(props) => props.lastInList && `margin-right: 0;`}
`;
