import React, { Fragment, useState, useContext, useEffect } from "react";
import { OverlayTrigger } from "react-bootstrap";
import LeaderboardRow, { padding } from "./LeaderboardRow";
import {
  MediumHeaderText,
  SmallBodyText,
  SmallHeaderText,
} from "../../../shared/Text";
import { MainOverlay, removeScrollbars } from "../../../shared/Overlay";
import { Icon } from "../../../shared/FormElements";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import { colors, styles } from "../../../helpers/styles";
import styled, { css } from "styled-components";
import { GameContext } from "../../../context/GameContext";
import { BrowserContext } from "../../../context/BrowserContext";
import { MediumBodyText } from "../../../shared/Text";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../../helpers/constants";
import { emit } from "../../../helpers/socket";
import { PrimaryButton } from "../../../shared/FormElements";
import { OverlayWrapper } from "../../../shared/Overlay";
import CheckboxField from "../../../shared/FormElements/CheckboxField";

const Leaderboard = ({ isAdmin }) => {
  const {
    gameId,
    sessionReset,
    sessionLeaderboard: sessionUsers,
    leaderboard: users,
    username,
    isInCents,
  } = useContext(GameContext);
  const { isMobile } = useContext(BrowserContext);
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [showAllTimePlayers, setShowAllTimePlayers] = useState(false);
  const [leaderboardUsers, setLeaderboardUsers] = useState(sessionUsers);
  const [sessionResetClicked, setSessionResetClicked] = useState(sessionReset);
  const clientUser = users.filter((user) => user.name === username);

  useEffect(() => {
    if (showAllTimePlayers) {
      setLeaderboardUsers(users);
    } else {
      setLeaderboardUsers(sessionUsers);
    }
    setSessionResetClicked(sessionReset);
  }, [sessionReset, showAllTimePlayers, sessionUsers, users]);

  const onSessionResetClicked = () => {
    emit("update_session_reset", {
      game_id: gameId,
      username: username,
      name: username,
      session_reset: !sessionResetClicked,
    });
    setSessionResetClicked(!sessionResetClicked);
  };

  return (
    <Fragment>
      <LeaderboardTab
        hide={!showLeaderboard}
        onClick={() => setShowLeaderboard(true)}
        horizontalPadding={isMobile ? "15px" : "40px"}
        data-testid={"leaderboard-widget"}
      >
        <SmallHeaderText>
          <Icon icon={faCrown} isLeft={1} widget={1} />
          LEADERBOARD
        </SmallHeaderText>
      </LeaderboardTab>
      <Wrapper hide={!showLeaderboard}>
        <LeaderboardWrapper hide={!showLeaderboard}>
          <LeaderboardBody onClick={() => setShowLeaderboard(false)}>
            <LeaderboardBodyInner>
              <LeaderboardHeader>
                <LeaderboardHeaderText data-testid={"leaderboard-header"}>
                  {" "}
                  Leaderboard{" "}
                </LeaderboardHeaderText>
                <CheckboxField
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowAllTimePlayers(!showAllTimePlayers);
                  }}
                  isOn={showAllTimePlayers}
                  text="Show all time players"
                  style={{ padding: 0 }}
                  dataTestId={"show-all-time-players"}
                />
              </LeaderboardHeader>

              <TitlesWrapper>
                <PlayerTitle>{"Player"}</PlayerTitle>
                <BuyInTitle>{"Buy In"}</BuyInTitle>
                <WinningsTitle>{"Profit"}</WinningsTitle>
              </TitlesWrapper>

              {/** Temp disable this user header until we decide if we want */}
              {false && clientUser.length === 1 && (
                <UserRow>
                  <UserRowInner>
                    <LeaderboardRow
                      name={username}
                      balance={clientUser[0].balance}
                      height={60}
                      strong
                      isInCents={isInCents}
                    />
                  </UserRowInner>
                </UserRow>
              )}
              <MainRows>
                {!!leaderboardUsers &&
                  leaderboardUsers.map((user, i) => (
                    <LeaderboardRow
                      highlight={username === user.name}
                      rank={i + 1}
                      key={user.name}
                      name={user.name}
                      balance={user.balance}
                      buyin={user.buyin}
                      first={i === 0}
                      last={i === users.length - 1}
                      isInCents={isInCents}
                    />
                  ))}
              </MainRows>
              {isAdmin && (
                <SessionResetWrapper>
                  <OverlayTrigger
                    placement={isMobile ? "bottom" : "right"}
                    overlay={SessionResetOverlay}
                    style={{ float: "left" }}
                  >
                    <div>
                      <PrimaryButton
                        secondaryColor
                        isPressed={sessionResetClicked}
                        onClick={(e) => {
                          e.stopPropagation();
                          onSessionResetClicked();
                        }}
                        dataTestId={"session-reset-button"}
                      >
                        {sessionResetClicked ? "Undo Reset" : "Reset Session"}
                      </PrimaryButton>
                    </div>
                  </OverlayTrigger>
                </SessionResetWrapper>
              )}
            </LeaderboardBodyInner>
          </LeaderboardBody>
        </LeaderboardWrapper>
      </Wrapper>
      <MainOverlay hide={!showLeaderboard} zIndex={7} />
    </Fragment>
  );
};
const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: scroll; // overriden by removeScrollbars
  top: 0;
  left: 0;
  z-index: 8;
  ${(props) => props.hide && `pointer-events: none;`}
  ${removeScrollbars}
`;
const LeaderboardBody = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  transition: 0.7s cubic-bezier(0.55, -0.09, 0.43, 1.36) all;
`;
const LeaderboardBodyInner = styled.div`
  display: table;
  width: 30vw;
  min-width: 250px;
  box-shadow: ${styles.largeBoxShadow};
  border-radius: 5px;
  overflow: hidden;
  background: ${({ theme }) => theme.modal.background};
  margin: 40px 0;
`;

const LeaderboardTab = styled.div`
  position: absolute;
  pointer-events: all;
  cursor: pointer;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: ${({ theme }) => theme.foreground};
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 10px
    ${(props) => (props.horizontalPadding ? props.horizontalPadding : "40px")};
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    padding: 10px
      ${(props) => (props.horizontalPadding ? props.horizontalPadding : "15px")};
  }
  z-index: 3;
  transition: padding 0.4s ease, top 1s ease-out, transform 1s ease-out;
  color: ${({ theme }) => theme.text.widget};
  &:hover {
    padding-top: 20px;
    opacity: 0.7;
  }
  ${(props) =>
    !props.hide &&
    `
    top: -100%;
  `}
`;

const LeaderboardWrapper = styled.div`
  ${(props) =>
    props.hide &&
    css`
      ${LeaderboardBody} {
        top: -50px;
        transform: translate(0, -100%);
        pointer-events: none;
      }
    `}
`;

export const LeaderboardHeaderText = styled(MediumHeaderText)`
  text-align: center;
  display: block;
  color: ${colors.white};
  font-weight: bold;
`;

export const LeaderboardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  width: 100%;
  min-width: 100px;
  background: ${({ theme }) => theme.tertiary};
  padding: 15px;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    padding: 8px;
  }
`;

const PlayerTitle = styled(MediumBodyText)`
  font-weight: bold;
  font-size: 1em;
  justify-self: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  margin-left: 50px;
  color: ${({ theme }) => theme.text.label};
`;

const BuyInTitle = styled(MediumBodyText)`
  font-weight: bold;
  font-size: 1em;
  margin-left: 5px;
  color: ${({ theme }) => theme.text.label};
`;

const WinningsTitle = styled(MediumBodyText)`
  font-weight: bold;
  font-size: 1em;
  margin-right: 25px;
  color: ${({ theme }) => theme.text.label};
`;

const TitlesWrapper = styled(MediumBodyText)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
  background: ${({ theme }) => theme.modal.background};

  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    height: 15px;
    margin-top: 8px;
  }
`;

const MainRows = styled.div`
  padding: ${padding} 0;
`;

const UserRow = styled.div`
  background: ${({ theme }) => theme.background};
  padding: 10px;
  width: 100%;
`;

const UserRowInner = styled.div`
  background: ${({ theme }) => theme.background};
  border-radius: 5px;
  overflow: hidden;
`;

const SessionResetWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 15px;
`;

const SessionResetOverlay = (props) => {
  return (
    <OverlayWrapper {...props} maxWidth={300}>
      <SmallBodyText>
        Resets all player profits next hand. Full stats will still be available
        in the all time leaderboard.
      </SmallBodyText>
    </OverlayWrapper>
  );
};

export default Leaderboard;
