// most mobile phones
const mobileBreakpointRaw = 840;
const mobileBreakpoint = `${mobileBreakpointRaw}px`;
const mobileHeightBreakpointRaw = 500;
const mobileHeightBreakpoint = `${mobileHeightBreakpointRaw}px`;

// older phones e.g. iPhone 5 / SE
const mobileBreakpointSmRaw = 600;
const mobileBreakpointSm = `${mobileBreakpointSmRaw}px`;

const mobileScreenMargin = "15px";

const roles = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_PLAYER: "ROLE_PLAYER",
  ROLE_SPECTATOR: "ROLE_SPECTATOR",
  ROLE_MTT_ADMIN: "ROLE_MTT_ADMIN",
};

const blinds = {
  DEFAULT_SMALL_BLIND_CENTS: 5,
  DEFAULT_BIG_BLIND_CENTS: 10,
  DEFAULT_BLIND_INCREASE_MINUTES: 10,
};

// Peers is for all non-current turn people to go (in case current disconnects), self is for self.
// The difference should encapsulate the max difference between differnt clients both playing lipoker
const timer = {
  DEFAULT_TIMER_SECONDS: 30,
  FAST_HAND_TIMER_SECONDS: 2,
  MEDIUM_HAND_TIMER_SECONDS: 8,
  SLOW_HAND_TIMER_SECONDS: 14,
  TIMER_END_GRACE_PERIOD_PEERS_SECONDS: 5,
  TIMER_END_GRACE_PERIOD_SELF_SECONDS: 1,
  INITIAL_TIME_MILLISECONDS: 1585638535452,
};

const buyin = {
  DEFAULT_AUTO_BUYIN_CENTS: 1000,
};

const sounds = {
  CALL: "CALL",
  CHECK: "CHECK",
  DING: "DING",
  FOLD: "FOLD",
  RAISE: "RAISE",
  WIN: "WIN",
};

const actions = {
  CHECKFOLD: "CHECK/FOLD",
  CHECK: "CHECK",
  FOLD: "FOLD",
  CALL: "CALL",
  RAISE: "RAISE",
  SHOW_HAND: "SHOW_HAND",
};

// TODO add all modal types here
const modals = {
  BUY_IN: "BUY_IN",
  FEEDBACK: "FEEDBACK",
  RAISE_INFO: "RAISE_INFO",
  DISCONNECTED_SERVER_IN_GAME: "DISCONNECTED_SERVER_IN_GAME",
  DISCONNECTED_SERVER_NOT_IN_GAME: "DISCONNECTED_SERVER_NOT_IN_GAME",
  DIFFERENT_LOG_IN: "DIFFERENT_LOG_IN",
  PATREON: "PATREON",
};

const stateReasons = {
  REASON_ACTION: "REASON_ACTION",
  REASON_DEAL: "REASON_DEAL",
  REASON_FLOP: "REASON_FLOP",
  REASON_NEW_HAND: "REASON_NEW_HAND",
  REASON_SHOWDOWN: "REASON_SHOWDOWN",
};

const logEventCategories = {
  IN_GAME_ACTION: "in-game-action",
  PRE_GAME_ACTION: "pre-game-action",
  STATE_UPDATE: "state-update",
};

const amplitudeEvents = {
  ACTION: "action",
  CREATE_GAME: "Create Game",
  JOIN_GAME: "Join Game",
  CLICK_HAND_RANKING: "click hand ranking",
};

const tableActions = {
  ACTION_NONE: "ACTION_NONE",
  ACTION_SIT: "ACTION_SIT",
  ACTION_STAND: "ACTION_STAND",
};

const themes = {
  THEME_LIGHT_DEFAULT: "THEME_LIGHT_DEFAULT",
  THEME_SALOON: "THEME_SALOON",
  THEME_DARK_DEFAULT: "THEME_DARK_DEFAULT",
  THEME_DARK_PURPLE: "THEME_DARK_PURPLE",
  THEME_DARK_GREEN: "THEME_DARK_GREEN",
  THEME_DARK_BLUE_PINK: "THEME_DARK_BLUE_PINK",
};

export {
  mobileBreakpoint,
  mobileBreakpointRaw,
  mobileScreenMargin,
  mobileBreakpointSm,
  mobileBreakpointSmRaw,
  mobileHeightBreakpointRaw,
  mobileHeightBreakpoint,
  roles,
  sounds,
  actions,
  stateReasons,
  logEventCategories,
  amplitudeEvents,
  tableActions,
  modals,
  themes,
  blinds,
  timer,
  buyin,
};
