import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "../../../shared/Modal";
import { MediumBodyText } from "../../../shared/Text";

const LandscapeSwitchModal = () => {
  const [isLandscape, setIsLandscape] = useState(true);
  const [overrideHide, setIsOverrideHide] = useState(false);

  useEffect(() => {
    checkDimensions();
    window.addEventListener("resize", setScreenOrientation);
    // TODO @dmitri cleanup event listener memory leak, fix hook deps, this is being called too much
    return () => {
      window.removeEventListener("resize", setScreenOrientation);
    };
  });

  const checkDimensions = (e) => {
    let width;
    let height;
    if (e) {
      width = e.target.innerWidth;
      height = e.target.innerHeight;
    } else {
      width = window.innerWidth;
      height = window.innerHeight;
    }
    setIsLandscape(width > height);
  };

  const setScreenOrientation = (e) => {
    checkDimensions(e);
  };
  return (
    <Modal
      show={!isLandscape && !overrideHide}
      title="Switch to landscape!"
      btnText="Keep playing"
      onBtnClick={(_) => {
        setIsOverrideHide(true);
      }}
    >
      <Wrapper>
        <Label>
          {
            "You're on portrait! Rotate your phone into landscape for a better experience."
          }
        </Label>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  padding: 0 20px;
`;

const Label = styled(MediumBodyText)`
  color: ${({ theme }) => theme.text.label};
`;

export default LandscapeSwitchModal;
