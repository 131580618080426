import React, { useState } from "react";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "../FormElements";
import styled from "styled-components";

const RefreshButton = ({ refreshAction }) => {
  const [refreshOnChange, setRefreshOnChange] = useState(false);
  const [disableTransition, setDisableTransition] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const refresh = () => {
    refreshAction();
    setRefreshOnChange(true);
    setDisableButton(true);

    setTimeout(() => {
      setDisableTransition(true);
      setRefreshOnChange(false);
      setTimeout(() => {
        setDisableTransition(false);
        setDisableButton(false);
      }, 50);
    }, 700);
  };

  return (
    <RefreshOuter
      onClick={refresh}
      refreshOnChange={refreshOnChange}
      disableTransition={disableTransition}
      disableButton={disableButton}
      data-testid={"refresh-table-button"}
    >
      <Icon icon={faSyncAlt} color="white" />
    </RefreshOuter>
  );
};

const RefreshOuter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  height: 25px;
  width: 25px;
  margin: 10px;
  justify-content: center;
  border-radius: 5px;
  background: ${({ theme }) => theme.secondary};
  cursor: pointer;
  svg {
    transition: 0.7s ease all;
  }
  &:hover {
    background: ${({ theme }) => theme.background};
  }
  ${(props) =>
    props.refreshOnChange &&
    `
    svg { transform: rotate(360deg); }
  `}
  ${(props) =>
    props.disableTransition &&
    `
    svg { transition: none; }
  `}
  ${(props) =>
    props.disableButton &&
    `
    pointer-events: none;
    background: ${({ theme }) => theme.secondaryLighter};
  `}
`;

export default RefreshButton;
