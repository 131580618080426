import styled from "styled-components";
import { colors, font } from "../../helpers/styles";

import { RadioGroup, Radio } from "react-radio-group";

export const StyledDiv = styled.div`
  float: right;
  margin-top: 10px;
  width: 30%;
`;

export const StyledRadioLabel = styled.div`
  margin-right: 20px;
  width: 100%;
  color: ${({ theme }) => theme.text.label};
  font-family: ${font.body};
`;

export const StyledRadioGroup = styled(RadioGroup)`
  margin-right: 0px;
`;

export const StyledRadio = styled(Radio)`
  vertical-align: middle;
  margin-right: 10px;
  width: 18px;
  height: 18px;
  color: colors.lightRed;
  background: ${colors.white};
`;
