/**
 *
 * @param {str} cardDisplay e.g. '2h'
 */
const suitCharToWordMap = {
  h: "heart",
  s: "spade",
  d: "diamond",
  c: "club",
};
const convertCardStringToValues = (cardDisplay) => {
  const number = cardDisplay[0] === "T" ? "10" : cardDisplay[0];
  const suit = suitCharToWordMap[cardDisplay[1].toLowerCase()];
  return { number, suit };
};

const fallbackCopyTextToClipboard = (text) => {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  let success;
  try {
    success = document.execCommand("copy");
  } catch (err) {
    success = false;
  }

  document.body.removeChild(textArea);
  return success;
};
const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    return Promise.resolve(fallbackCopyTextToClipboard(text));
  }
  return navigator.clipboard.writeText(text).then(
    () => {
      return true;
    },
    (err) => {
      return false;
    }
  );
};

const generateCardHighlights = (indices_to_highlight, card_list) => {
  if (!card_list) {
    return null;
  }
  return card_list.map((card, i) => {
    return {
      card: card,
      isHighlighted: indices_to_highlight && indices_to_highlight.includes(i),
    };
  });
};

const refreshPage = () => {
  window.location.reload();
};

/**
 *
 * @param {*} actualPos: Player X's position
 * @param {*} clientPos: Client's position
 * @param {*} maxPlayerNumber: # of seats; 9 (by default)
 * @returns position that player X is displayed in, if Client is displayed in seat 4 (at bottom)
 */
const posFromClientPOV = (actualPos, clientPos, maxPlayerNumber) => {
  return (
    (actualPos - clientPos + (maxPlayerNumber - 1) / 2 + maxPlayerNumber) %
    maxPlayerNumber
  );
};

const isInvalidOrEmpty = (val) => {
  return (
    isNaN(val) ||
    val.includes(".") ||
    val.includes("-") ||
    val.includes(" ") ||
    val.length > 10
  );
};

const formatCurrency = (amount, isInCents) => {
  return isInCents
    ? (amount / 100).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })
    : amount;
};

export {
  convertCardStringToValues,
  copyTextToClipboard,
  generateCardHighlights,
  refreshPage,
  posFromClientPOV,
  isInvalidOrEmpty,
  formatCurrency,
};
