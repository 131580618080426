import { LargeBodyText } from "../../../shared/Text";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../../helpers/constants";
import styled from "styled-components";

export const SettingsOption = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  align-items: center;
  margin: 12px 0;
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.4;
    pointer-events: none;
    `}
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 0;
    margin: 0;
  }
`;

export const SettingsText = styled(LargeBodyText)`
  margin-top: 2px;
  font-weight: bold;
  color: ${({ theme }) => theme.text.label};
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 50px;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    padding: 10px;
  }
`;
