/* Log Events and Metrics 
  Can easily be extended to add more services
*/
import amplitude from "amplitude-js";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/browser";
import { logEventCategories, amplitudeEvents } from "./constants";

export function initLogger() {
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY);
  ReactGA.initialize("UA-163804685-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
}

export function setLoggerIdentity(username, gameId, provider) {
  const identity = new amplitude.Identify()
    .set("gameId", gameId)
    .set("username", username)
    .set("numClickShowHands", 0)
    .set("provider", provider);

  amplitude.getInstance().identify(identity);

  Sentry.configureScope(function (scope) {
    scope.setTag("gameId", gameId);
    scope.setUser({
      username: username,
    });
  });
}

// takes a type and value
export function logActionEvent(type, value) {
  const category = logEventCategories.IN_GAME_ACTION;
  amplitude.getInstance().logEvent(amplitudeEvents.ACTION, {
    type,
    value,
  });

  ReactGA.event({
    category,
    action: type,
    value: value,
  });

  Sentry.addBreadcrumb({
    category,
    message: `${type} ${value}`,
    data: {
      action: type,
      value: value,
    },
    level: Sentry.Severity.Info,
  });
}

export function logCreateGameEvent() {
  const action = amplitudeEvents.CREATE_GAME;
  amplitude.getInstance().logEvent(action);
  const category = logEventCategories.PRE_GAME_ACTION;

  ReactGA.event({
    category,
    action,
  });

  Sentry.addBreadcrumb({
    category,
    message: action,
    level: Sentry.Severity.Info,
  });
}

export function logJoinGameEvent() {
  const category = logEventCategories.PRE_GAME_ACTION;
  const action = amplitudeEvents.JOIN_GAME;
  amplitude.getInstance().logEvent(action);

  ReactGA.event({
    category,
    action,
  });

  Sentry.addBreadcrumb({
    category,
    message: action,
    level: Sentry.Severity.Info,
  });
}

export function logClickHandRankingEvent() {
  const action = amplitudeEvents.CLICK_HAND_RANKING;
  const identity = new amplitude.Identify().add("numClickShowHands", 1);
  amplitude.getInstance().identify(identity);
  amplitude.getInstance().logEvent(action);
}

export function logState(state) {
  Sentry.addBreadcrumb({
    category: logEventCategories.STATE_UPDATE,
    message: "State Update",
    level: Sentry.Severity.Debug,
    data: state,
  });
}

export function initSentry() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_API_KEY,
    environment: process.env.NODE_ENV,
  });
}

export function logError(error) {
  Sentry.captureException(error);
}
