import React from "react";
import Modal from "../../../shared/Modal";
import { SmallBodyText } from "../../../shared/Text";
import styled from "styled-components";

const NoInternetModal = ({ show, onBtnClick }) => {
  let wifiDisconnectText = (
    <Label>
      Your internet disconnected! Don&apos;t worry, your game will resume
      <br />
      exactly where you left off.
      <br />
      Just refresh once your internet is more stable -- you&apos;ll
      <br />
      automatically check/fold till then.
    </Label>
  );
  return (
    <Modal
      show={show}
      title="Internet unstable!"
      btnText="Refresh"
      onBtnClick={onBtnClick}
      dataTestId="refresh-button"
    >
      <div>{wifiDisconnectText}</div>
    </Modal>
  );
};

const Label = styled(SmallBodyText)`
  color: ${({ theme }) => theme.text.label};
`;

export default NoInternetModal;
