export const mttStateReducer = (_, action) => {
  return {
    timeout: action.payload.timeout,
    reason: action.payload.reason,
    isInCents: action.payload.isInCents,
    startingStack: action.payload.startingStack,
    initialBlindCents: action.payload.initialBlindCents,
    blindIncreaseMinutes: action.payload.blindIncreaseMinutes,
    MTTOngoing: action.payload.MTTOngoing,
  };
};
