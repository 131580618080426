import React from "react";

const Heart = ({ color = "#000000", style }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
      xmlSpace="preserve"
      style={style}
    >
      <g>
        <path
          style={{ fill: color }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M500.505,259.184c9.569-21.696,15.322-34.524,20.9-47.423
            C570.35,98.644,685.971,39.66,804.696,67.286c116.71,27.152,192.734,128.685,189.482,250.771
            c-3.378,126.897-58.851,232.329-134.703,328.939c-90.479,115.251-201.432,208.2-325.246,285.279
            c-16.418,10.224-49.749,11.711-65.459,1.771c-148.59-94.02-280.665-206.671-377.5-355.91
            C35.979,492.922,3.633,399.918,5.826,297.527C7.837,203.5,55.17,133.239,135.519,89.698c79.549-43.101,162.186-39.95,241.959,6.118
            c49.856,28.791,83.278,70.679,104.044,123.694C485.657,230.062,491.276,240.04,500.505,259.184z"
        />
      </g>
    </svg>
  );
};

export default Heart;
