import React, { useState, useEffect } from "react";
import Modal, { transitionTime } from "../../../shared/Modal";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { SmallHeaderText } from "../../../shared/Text";
import styled from "styled-components";
import * as explanations from "./QAs";

const faqList = [
  {
    id: 0,
    question: "Why was Lipoker created?",
    answer: <explanations.Philosophy />,
  },
  {
    id: 1,
    question: "How is the deck being shuffled?",
    answer: <explanations.Mersenne />,
  },
  {
    id: 2,
    question: "Who shows their cards during showdown?",
    answer: <explanations.ShowHand />,
  },
  {
    id: 3,
    question: "How can I donate?",
    answer: <explanations.Patreon />,
  },
  {
    id: 4,
    question: "I have a few more questions, whom should I contact?",
    answer: <explanations.ContactUs />,
  },
];

const FAQModal = ({ hideModal, show, isFourColorDeck, isMobile }) => {
  const [startTransition, setStartTransition] = useState(true);

  const close = () => {
    setStartTransition(true);
    setTimeout(() => {
      hideModal(false);
    }, transitionTime);
  };

  useEffect(() => {
    // if showing again, reset
    if (show) {
      setStartTransition(false);
    }
  }, [show]);

  let accordionList = faqList.map((x) => {
    return (
      <AccordionItem key={x.id}>
        <ButtonWrapper>
          <QuestionHeader>{x.question}</QuestionHeader>
        </ButtonWrapper>
        <AccordionItemPanel>{x.answer}</AccordionItemPanel>
      </AccordionItem>
    );
  });

  return (
    <Modal
      title="F.A.Q"
      show={true}
      onOverlayClick={close}
      dataTestId="faq-submit-button"
      isInvalid={false}
      transitionOut={startTransition}
      margin
      scrollMiddle={true}
    >
      <AccordionWrapper allowZeroExpanded={true}>
        {accordionList}
      </AccordionWrapper>
    </Modal>
  );
};

const AccordionWrapper = styled(Accordion)`
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 6px 10%;
  width: 100%;
`;

const QuestionHeader = styled(SmallHeaderText)`
  color: ${({ theme }) => theme.text.label};
`;

const ButtonWrapper = styled(AccordionItemButton)`
  cursor: pointer;
  background: ${({ theme }) => theme.foreground};
  padding-top: 15px;
  padding-bottom: 12px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 2px;
  &:hover {
    background: ${({ theme }) => theme.background};
  }
  outline: none;
  margin-bottom: 3px;
`;

export default FAQModal;
