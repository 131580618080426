import styled from "styled-components";

export const ActionButtonWrapper = styled.div`
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: ${(props) =>
    props.betButtonsOnRight ? `row` : `row-reverse`};
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
`;
