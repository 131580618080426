import React from "react";
import Modal from "../../../shared/Modal";
import { SmallBodyText } from "../../../shared/Text";
import styled from "styled-components";

const DifferentLogInModal = ({ show, onBtnClick, isSpectating }) => {
  return (
    <Modal
      show={show}
      title="Different log-in detected!"
      btnText="Refresh"
      onBtnClick={onBtnClick}
      dataTestId="refresh-button"
    >
      {!isSpectating ? (
        <div>
          <Label>
            Your account in this game has been opened from somewhere else.
            <br />
            <br />
            Refresh the page to reclaim control of your account! Consider
            <br />a strong password to make sure no one else can log in as you
            and
            <br />
            see your cards.
          </Label>
        </div>
      ) : (
        <div>
          <Label>
            Your spectator account in this game has been opened from somewhere
            else.
            <br />
            <br />
            Refresh the page to reclaim control of your account! Consider
            <br />a strong password to make sure no one else can log in as you.
            <br />
          </Label>
        </div>
      )}
    </Modal>
  );
};

const Label = styled(SmallBodyText)`
  color: ${({ theme }) => theme.text.label};
`;

export default DifferentLogInModal;
