import {
  LEADERBOARD,
  DISPLAY,
  JOIN_GAME,
  SPECTATE_GAME,
  GAME_STATE_CHANGE,
  MTT_STATE_CHANGE,
  RECEIVE_CHAT,
  IS_RUNNING_DELAY,
  CREATE_GAME,
  CREATE_MTT,
  CHECK_GAME_EXISTS,
  SET_USER_DISCONNECTED,
  SET_GAME_SETTINGS,
  SET_PLAYERS,
  SET_SPECTATORS,
  QUEUED_ACTION,
  UPDATE_PLAYER_FIELDS,
  GET_MTT_PLAYERS,
  CHECK_MTT_EXISTS,
  JOIN_MTT,
  UPDATE_MTT_STATE,
  SET_MTT_USERS,
} from "./types";
import api from "../helpers/api";
import { setUserVars } from "react-fullstory";
import { emitSocketAction } from "../helpers/socket";
import { actions, timer } from "../helpers/constants";
import { logError } from "../helpers/logger";
/*
Action Creator Example

export const actionCreator = (dispatch, oldState) => (yourFunctionParams) =>{
  let thingsToUpdateInState = doSomething()
  dispatch({
    type: SOME_ACTION_TYPE,
    payload:thingsToUpdateInState
  })
}

*/
export const updateLeaderboard = (dispatch) => (leaderboard_resp) => {
  let sessionUsers = leaderboard_resp["session"];
  let users = leaderboard_resp["all_time"];

  const sessionLeaderboard = sessionUsers.map((u) => {
    return { name: u[0], balance: u[1], buyin: u[2] };
  });
  const leaderboard = users.map((u) => {
    return { name: u[0], balance: u[1], buyin: u[2] };
  });
  dispatch({
    type: LEADERBOARD,
    payload: { sessionLeaderboard, leaderboard },
  });
};

export const updateDisplay = (dispatch) => (display) => {
  // SetUserVars method for Fullstory
  setUserVars({
    display: display,
  });
  dispatch({
    type: DISPLAY,
    payload: { display },
  });
};

export const updateMTTState = (dispatch) => (gameId) => {
  dispatch({
    type: UPDATE_MTT_STATE,
    payload: { gameId: gameId },
  });
};

export const updateIsRunningDelay = (dispatch) => (isRunningDelay) => {
  dispatch({
    type: IS_RUNNING_DELAY,
    payload: { isRunningDelay },
  });
};

export const receiveChatMessage = (dispatch) => (chatObj) => {
  dispatch({
    type: RECEIVE_CHAT,
    payload: { username: chatObj.username, message: chatObj.msg },
  });
};

export const joinGame = (dispatch) => (
  gameId,
  username,
  userPassword,
  gamePassword,
  isGod
) => {
  return api
    .get("/join_game", {
      params: {
        game_id: gameId,
        username: username,
        user_password: userPassword,
        game_password: gamePassword,
        is_god: isGod,
      },
    })
    .then((response) => {
      if (!response.data.key) {
        console.error("No key in response data");
      }
      console.log("join_game response; ", response);
      const payload = {
        username: username,
        sessionKey: response.data.key,
        smallBlindCents: response.data.smallBlindCents,
        gameId: gameId,
        isGod: isGod,
        isTournament: response.data.isTournament,
        autoBuyinCents: response.data.autoBuyinCents,
        totalBuyinCents: response.data.totalBuyinCents,
        initialBlindCents: response.data.initialBlindCents,
        blindIncreaseMinutes: response.data.blindIncreaseMinutes,
      };
      dispatch({
        type: JOIN_GAME,
        payload,
      });

      return {};
    })
    .catch((error) => {
      logError(error);
      return {
        error,
      };
    });
};

export const joinMTT = (dispatch) => (
  MTTId,
  username,
  userPassword,
  MTTPassword
) => {
  return api
    .get("/join_mtt", {
      params: {
        mtt_id: MTTId,
        username: username,
        user_password: userPassword,
      },
    })
    .then((response) => {
      if (!response.data.key) {
        console.error("No key in response data");
      }
      const payload = {
        MTTId,
        username,
        key: response.data.key,
        //TODO MTT
      };
      dispatch({
        type: JOIN_MTT,
        payload,
      });

      return {};
    })
    .catch((error) => {
      logError(error);
      return {
        error,
      };
    });
};

export const spectateGame = (dispatch) => (
  gameId,
  username,
  userPassword,
  gamePassword,
  isGod
) => {
  return api
    .get("/spectate_game", {
      params: {
        game_id: gameId,
        username: username,
        user_password: userPassword,
        game_password: gamePassword,
        is_god: isGod,
      },
    })
    .then((response) => {
      if (!response.data.key) {
        console.error("No key in response data");
      }
      const payload = {
        username: username,
        sessionKey: response.data.key,
        gameId: gameId,
        isGod: isGod,
      };
      dispatch({
        type: SPECTATE_GAME,
        payload,
      });

      return {};
    })
    .catch((error) => {
      logError(error);
      return {
        error,
      };
    });
};

export const updateGameStateFromSocket = (dispatch, oldState) => (
  newGameState
) => {
  if (
    newGameState === "Wrong config" ||
    newGameState === "Incorrect key auth"
  ) {
    return null;
  }
  dispatch({
    type: GAME_STATE_CHANGE,
    payload: newGameState,
  });
};

export const updateMTTStateFromSocket = (dispatch, oldState) => (
  newMTTState
) => {
  if (newMTTState === "Wrong config" || newMTTState === "Incorrect key auth") {
    return null;
  }
  dispatch({
    type: MTT_STATE_CHANGE,
    payload: newMTTState,
  });
};

// create a game and return the new gameId from the server
export const createGame = (dispatch) => async (
  smallBlindCents,
  gamePassword = "",
  preferredGameId = null,
  isTimerEnabled = false,
  timerLengthSeconds = 30,
  handTimerLengthSeconds = timer.MEDIUM_HAND_TIMER_SECONDS,
  isGodModeEnabled = false,
  isTournament = false,
  autoBuyinCents = 1000,
  blindIncreaseSeconds = 600,
  isInCents = true
) => {
  try {
    const { data } = await api.get("create_game", {
      params: {
        small_blind_amount: smallBlindCents,
        game_password: gamePassword,
        preferred_game_id: preferredGameId,
        is_timer_enabled: isTimerEnabled,
        timer_length_seconds: timerLengthSeconds,
        hand_timer_length_seconds: handTimerLengthSeconds,
        is_god_mode: isGodModeEnabled,
        is_tournament: isTournament,
        auto_buyin_cents: autoBuyinCents,
        blind_increase_seconds: blindIncreaseSeconds,
        is_in_cents: isInCents,
      },
    });

    dispatch({
      type: CREATE_GAME,
      payload: {
        gameId: data.gameId,
        smallBlindCents: data.smallBlindCents,
        hasGamePassword: data.hasGamePassword,
        prefilledGamePassword: gamePassword,
        isTimerEnabled: isTimerEnabled,
        timerLengthSeconds: timerLengthSeconds,
        handTimerLengthSeconds: handTimerLengthSeconds,
        isGodModeEnabled: isGodModeEnabled,
        isTournament: isTournament,
        autoBuyinCents: autoBuyinCents,
        isInCents: isInCents,
      },
    });

    return data.gameId;
  } catch (error) {
    logError(error);
    console.error(error);
  }
};

export const createMTT = (dispatch) => async (
  smallBlindCents,
  MTTPassword,
  timerLengthSeconds,
  startingStack,
  blindIncreaseSeconds,
  isInCents
) => {
  try {
    const { data } = await api.get("create_mtt", {
      params: {
        small_blind_amount: smallBlindCents,
        mtt_password: MTTPassword,
        timer_length_seconds: timerLengthSeconds,
        starting_stack: startingStack,
        blind_increase_seconds: blindIncreaseSeconds,
        is_in_cents: isInCents,
      },
    });

    dispatch({
      type: CREATE_MTT,
      payload: {
        MTTId: data.MTTId,
        hasMTTPassword: data.hasMTTPassword,
        smallBlindCents: smallBlindCents,
        prefilledMTTPassword: MTTPassword,
        timerLengthSeconds: timerLengthSeconds,
        startingStack: startingStack,
        isInCents: isInCents,
      },
    });
    return data.MTTId;
  } catch (error) {
    logError(error);
    console.error(error);
  }
};

export const getMTTPlayers = (dispatch) => (
  MTTId,
  username,
  key
) => async () => {
  try {
    const { data } = await api.get("/get_mtt_players", {
      params: {
        mtt_id: MTTId,
        username,
        key,
      },
    });
    dispatch({
      type: GET_MTT_PLAYERS,
      payload: {
        MTTPlayers: data.players,
      },
    });
  } catch (error) {
    logError(error);
    console.error(error);
  }
};

export const checkGameExists = (dispatch) => async (
  gameId,
  iframeClientName,
  cb = null
) => {
  try {
    const { data } = await api.get("/check_game_exists", {
      params: {
        game_id: gameId,
      },
    });
    let isIntermediateDisplay = iframeClientName !== "game" && !data.exists;
    let display = data.exists ? "login" : "newGame";
    if (isIntermediateDisplay) {
      display = "loading"; // in this case it's a new gather.town link, which will be followed up by a create game, then display "login"
    }
    dispatch({
      type: CHECK_GAME_EXISTS,
      payload: {
        display,
        hasGamePassword: data.hasGamePassword,
      },
    });
    if (cb) {
      cb(data.exists);
    }
  } catch (error) {
    logError(error);
    console.error(error);
  }
};

export const checkMTTExists = (dispatch) => async (MTTId) => {
  try {
    const { data } = await api.get("/check_mtt_exists", {
      params: {
        mtt_id: MTTId,
      },
    });

    let display = data.exists ? "login" : "initial";
    dispatch({
      type: CHECK_MTT_EXISTS,
      payload: {
        display,
        hasMTTPassword: data.hasMTTPassword,
      },
    });
  } catch (error) {
    logError(error);
    console.error(error);
  }
};

export const setUserDisconnected = (dispatch) => (username) => {
  dispatch({
    type: SET_USER_DISCONNECTED,
    payload: {
      username,
    },
  });
};

export const setGameSettings = (dispatch) => (
  smallBlindCents,
  isTimerEnabled,
  timerLengthSeconds,
  handTimerLengthSeconds,
  sessionReset
) => {
  dispatch({
    type: SET_GAME_SETTINGS,
    payload: {
      smallBlindCents,
      isTimerEnabled,
      timerLengthSeconds,
      handTimerLengthSeconds,
      sessionReset,
    },
  });
};

export const setPlayerState = (dispatch) => (players, reason) => {
  dispatch({
    type: SET_PLAYERS,
    payload: {
      players,
      reason,
    },
  });
};

export const setMTTUsersState = (dispatch) => (mtt_users, reason) => {
  dispatch({
    type: SET_MTT_USERS,
    payload: {
      mtt_users,
      reason,
    },
  });
};

export const setSpectatorState = (dispatch) => (spectators, reason) => {
  dispatch({
    type: SET_SPECTATORS,
    payload: {
      spectators,
      reason,
    },
  });
};

export const setPlayerFields = (dispatch) => (username, fields) => {
  dispatch({
    type: UPDATE_PLAYER_FIELDS,
    payload: {
      username,
      fields,
    },
  });
};

export const updateQueuedAction = (dispatch) => (queuedAction) => {
  dispatch({
    type: QUEUED_ACTION,
    payload: {
      queuedAction,
    },
  });
};

// This should only ever be called if there is a queued action
// Which is guaranteed by the context variable willExecuteQueuedAction
export const executeQueuedAction = (dispatch, oldState) => () => {
  if (oldState.queuedAction) {
    // convert CHECK/FOLD and CHECK to CALL
    // This is only written like this because the backend thinks a call and check are the same thing
    // Even worse, the backend doesn't care what amount you think you're calling for, a call is a call
    // I recommend refactoring the backend to make this cleaner and less bug prone
    // This is safechecked in the GAME_STATE_CHANGE reducer
    // If we got here, and it still says "CHECK", it is guaranteed that the user can check
    // i.e, it is guaranteed that sending a CALL event will result in a CHECK
    let queuedAction = oldState.queuedAction;
    if (
      queuedAction.actionType === actions.CHECK ||
      queuedAction.actionType === actions.CHECKFOLD
    ) {
      queuedAction.actionType = actions.CALL;
    }

    // It is also guaranteed that if the user was trying to call a specific amount
    // It will only call for that specific amount
    // As above, this is guaranteed by the reducer
    // but the backend should be refactored to enforce this as well, which would be less bug prone
    emitSocketAction(
      queuedAction.actionType,
      queuedAction.betValue,
      oldState.sessionKey,
      oldState.gameId,
      oldState.username
    );

    dispatch({
      type: QUEUED_ACTION,
      payload: {
        queuedAction: null,
      },
    });
  }
};

/* For Testing Purposes */
export const setTestFrontend = (dispatch) => () => {
  // test method that logs in with default values
  const payload = {
    activePlayers: [
      {
        playerName: "Aayush",
        playerBalance: 14985,
        playerPot: 300,
        cards: ["2c", "2s"],
        playerIsInGame: true,
        playerIsInHand: true,
        playerIsturn: true,
        isAtTable: true,
        connected: true,
      },
      {
        playerName: "Archer",
        playerBalance: 14985,
        playerPot: 300,
        cards: ["as", "ah"],
        playerIsInGame: true,
        playerIsInHand: true,
        playerIsturn: true,
        isAtTable: true,
        connected: true,
      },
      {
        playerName: "Yvonne",
        playerBalance: 14985,
        playerPot: 300,
        cards: ["ts", "7s"],
        playerIsInGame: true,
        playerIsInHand: true,
        playerIsturn: true,
        isAtTable: true,
        connected: true,
      },
      {
        playerName: "Dmitri",
        playerBalance: 14985,
        playerPot: 300,
        cards: ["jc", "qh"],
        playerIsInGame: true,
        playerIsInHand: true,
        playerIsturn: true,
        isAtTable: true,
        connected: true,
      },
      {
        playerName: "Ashwin",
        playerBalance: 14985,
        playerPot: 300,
        cards: ["7c", "2d"],
        playerIsInGame: true,
        playerIsInHand: true,
        playerIsturn: true,
        isAtTable: true,
        connected: true,
      },
      {
        playerName: "Ankit",
        playerBalance: 14985,
        playerPot: 300,
        cards: ["8c", "3h"],
        playerIsInGame: true,
        playerIsInHand: true,
        playerIsturn: true,
        isAtTable: true,
        connected: true,
      },
      {
        playerName: "Russell",
        playerBalance: 14985,
        playerPot: 300,
        cards: ["kc", "jh"],
        playerIsInGame: true,
        playerIsInHand: true,
        playerIsturn: true,
        isAtTable: true,
        connected: true,
      },
      {
        playerName: "Ali",
        playerBalance: 14985,
        playerPot: 300,
        cards: ["9d", "9s"],
        playerIsInGame: true,
        playerIsInHand: true,
        playerIsturn: true,
        isAtTable: true,
        connected: true,
      },
      {
        playerName: "R&J",
        playerBalance: 14985,
        playerPot: 300,
        cards: ["6d", "5s"],
        playerIsInGame: true,
        playerIsInHand: true,
        playerIsturn: true,
        isAtTable: true,
        connected: true,
      },
    ],
    faceUpHoleCards: {
      0: "3c",
      1: "3s",
      2: "th",
      3: "jh",
      4: "ad",
    },
    display: "play",
    mainPotCents: 10,
    sidePotCents: 10,
  };
  dispatch({
    type: GAME_STATE_CHANGE,
    payload,
  });
};
