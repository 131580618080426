import { generateCardHighlights } from "../helpers/utils";
export const stateReducer = (_, action) => {
  let newFaceUpHoleCards;
  if ("community" in action.payload) {
    const faceUpHoleCards = action.payload.community.map((x) =>
      x.toUpperCase()
    );
    newFaceUpHoleCards = generateCardHighlights(
      action.payload.comm_indices_to_highlight,
      faceUpHoleCards
    );
  } else {
    newFaceUpHoleCards = {};
  }
  return {
    faceUpHoleCards: newFaceUpHoleCards,
    mainPotCents: "pot" in action.payload ? action.payload.pot : 0,
    minRaise: action.payload.minRaise,
    dealerPosition: action.payload.dealerPosition,
    timeout: action.payload.timeout,
    reason: action.payload.reason,
    turnNonce: action.payload.turnNonce,
    ongoing: action.payload.ongoing,
    isGodModeEnabled: action.payload.isGodModeEnabled,
    god: action.payload.god,
    gameStartTime: action.payload.startTime,
    paused: action.payload.paused ? action.payload.paused : false,
    isInCents: action.payload.isInCents,
    isTournament: action.payload.isTournament,
    autoBuyinCents: action.payload.autoBuyinCents,
    initialBlindCents: action.payload.initialBlindCents,
    blindIncreaseMinutes: action.payload.blindIncreaseMinutes,
    isTimerEnabled: action.payload.isTimerEnabled,
    timerLengthSeconds: action.payload.timerLengthSeconds,
  };
};
