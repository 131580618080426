import { colors } from "../styles";

export function generateLightTheme(primary, secondary, tertiary, background) {
  return {
    // general
    primary: primary,
    secondary: secondary,
    secondaryLighter: colors.darkBlueLighter,
    tertiary: tertiary,
    background: background,
    foreground: colors.lightGrey2,
    disabled: colors.darkGrey2,
    profit: colors.green,
    loss: tertiary,
    // components
    card: {
      heart: tertiary,
      diamond: primary,
      spade: colors.black,
      club: colors.green,
      dark: colors.black,
      light: tertiary,
    },
    modal: {
      background: colors.white,
      subtitle: colors.grey,
      overlay: colors.white,
      divider: background,
      cta: background,
    },
    chat: {
      background: colors.white,
      divider: colors.lightGrey2,
      gameText: primary,
      linkText: secondary,
    },
    table: {
      outer: colors.grey,
      inner: colors.darkGrey,
    },
    openSeat: {
      background: colors.lightGrey2,
      borderColor: colors.white,
    },
    player: {
      disabledBackground: colors.grey,
      borderColor: background,
      borderHighlightedColor: background,
      pot: secondary,
    },
    keyboardChar: {
      background: colors.white,
      text: null,
    },
    input: {
      modal: background,
      contrast: colors.white,
      noBack: colors.white,
    },
    switch: {
      background: colors.lightGrey2,
    },
    flatShadow: colors.grey,
    // text
    text: {
      mainBody: colors.darkGrey,
      widget: colors.darkGrey2,
      label: colors.black,
      placeholder: colors.grey,
    },
  };
}
