import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { withTheme } from "styled-components";
import { colors } from "../../../helpers/styles";

const Icon = ({ isLeft = false, ...rest }) => {
  return <IconInner {...rest} theme={rest.theme} isLeft={isLeft} />;
};

const IconInner = styled(FontAwesomeIcon)`
  color: ${(props) => (props.color ? props.color : colors.darkGrey)};
  color: ${(props) =>
    props.widget && props.theme && `${props.theme.text.widget}`};
  ${(props) =>
    props.onClick &&
    `
      cursor: pointer;
  `}
  ${(props) => props.isLeft && "margin-right: 10px"};
  ${(props) => props.disabled && "opacity: 0.5"};
  ${(props) => props.disabled && "cursor: not-allowed"};
  ${(props) => props.disabled && "pointer-events: none"};
`;

export default withTheme(Icon);
