import React from "react";

const Club = ({ color = "#000000", style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
      xmlSpace="preserve"
      style={style}
    >
      <g>
        <path
          style={{ fill: color }}
          d="M535.172,668.428c0,0,40.126,251.511,261.298,320.997v15.656H196.566v-14.68
        c0,0,270.104-141.901,267.167-321.974c0,0-18.596,91.993-190.834,105.696C100.659,787.825,51.727,607.756,55.643,545.12
        c3.912-62.633,42.079-211.384,208.447-219.214c0,0-52.013-322.951,237.738-325.886C791.577-2.916,733.84,319.056,733.84,319.056
        s164.41,24.464,202.576,171.262c38.168,146.795-63.612,263.252-181.049,278.911S561.597,721.279,535.172,668.428z"
        />
        <path
          style={{ fill: color }}
          d="M464.729,668.428c0,0-39.685,251.511-258.396,320.997v15.656h593.234v-14.68
        c0,0-267.108-141.901-264.204-321.974c0,0,18.398,91.993,188.72,105.696c170.324,13.701,218.716-166.368,214.84-229.004
        c-3.866-62.633-41.615-211.384-206.127-219.214c0,0,51.434-322.951-235.094-325.886C211.172-2.916,268.27,319.056,268.27,319.056
        s-162.58,24.464-200.327,171.262C30.204,637.113,130.848,753.57,246.981,769.229C363.109,784.889,438.594,721.279,464.729,668.428z
        "
        />
      </g>
    </svg>
  );
};

export default Club;
