import React, { Fragment, useState } from "react";
import { BrowserSettingsModal } from "../Modals";
import { Icon } from "../../shared/FormElements";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import Widget from "../../shared/Widget";

const SettingsWidget = ({
  gameId,
  username,
  sound,
  setSound,
  showVideoChat,
  setShowVideoChat,
  videoIsOnRight,
  setVideoIsOnRight,
  betButtonsOnRight,
  setBetButtonsOnRight,
  joinCall,
  setJoinCall,
  setAllowKeyboardShortcuts,
  setExistsModal,
  isMobile,
  smallBlindCents,
  isTimerEnabled,
  timerLengthSeconds, //    timer.DEFAULT_TIMER_SECONDS
  handTimerLengthSeconds, //    timer.MEDIUM_HAND_TIMER_SECONDS
  isFourColorDeck,
  setIsFourColorDeck,
  isSpectating,
  isTournament,
  isAdmin,
  isInCents,
  adminPlayers,
}) => {
  const [showSettingsModal, _setShowSettingsModal] = useState(false);
  const [startTransition, setStartTransition] = useState(true);

  const setShowSettingsModal = (show) => {
    console.log("settingsWidget", show);
    setStartTransition(!show);
    _setShowSettingsModal(show);
    setAllowKeyboardShortcuts(!show);
    setExistsModal(show);
  };

  return (
    <Fragment>
      <Widget
        onClick={() => setShowSettingsModal(true)}
        text={<Icon icon={faCog} widget />}
        showNotif={false}
        left={isMobile ? "2px" : "6px"}
        dataTestId={"settings-widget"}
      />
      <BrowserSettingsModal
        gameId={gameId}
        username={username}
        isMobile={isMobile}
        show={showSettingsModal}
        hideModal={() => {
          setShowSettingsModal(false);
        }}
        sound={sound}
        setSound={setSound}
        showVideoChat={showVideoChat}
        setShowVideoChat={setShowVideoChat}
        videoIsOnRight={videoIsOnRight}
        setVideoIsOnRight={setVideoIsOnRight}
        betButtonsOnRight={betButtonsOnRight}
        setBetButtonsOnRight={setBetButtonsOnRight}
        joinCall={joinCall}
        setJoinCall={setJoinCall}
        smallBlindCents={smallBlindCents}
        isTimerEnabled={isTimerEnabled}
        timerLengthSeconds={timerLengthSeconds} //    timer.DEFAULT_TIMER_SECONDS
        handTimerLengthSeconds={handTimerLengthSeconds} //    timer.MEDIUM_HAND_TIMER_SECONDS
        isFourColorDeck={isFourColorDeck}
        setIsFourColorDeck={setIsFourColorDeck}
        isSpectating={isSpectating}
        isTournament={isTournament}
        isAdmin={isAdmin}
        isInCents={isInCents}
        adminPlayers={adminPlayers}
        startTransition={startTransition}
        setStartTransition={setStartTransition}
      />
    </Fragment>
  );
};

export default SettingsWidget;
