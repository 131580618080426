import { generateCardHighlights } from "../helpers/utils";

export const playerReducer = (state, action) => {
  const players = action.payload.players;
  let newActivePlayers = [];
  let newMaxPotCents = 0;
  let newStillInHand = false;
  let newOwnPotCents = state.ownPotCents;
  let newStillInGame = false;
  let newOwnStackCents = 0;
  let newBetsOnTableSumCents = 0;
  let newRequestedBuyIn = false;
  let newPendingBuyIn = false;
  let newIsGod = false;
  let newShowCardsToGod = false;
  for (let pos in players) {
    let newPlayer = {
      playerName: players[pos].name,
      playerRole: players[pos].role,
      playerBalance: players[pos].stack,
      playerPot: players[pos].pot,
      playerIsInHand: players[pos].is_in_hand,
      playerIsInGame: players[pos].is_in_game,
      playerIsTurn: players[pos].turn,
      connected: players[pos].connected,
      isAtTable: players[pos].is_at_table,
      cards: generateCardHighlights(
        players[pos].hand_indices_to_highlight,
        players[pos].cards
      ),
      bestHand: players[pos].best_hand,
      pos: players[pos].pos,
      queuedAction: players[pos].queued_action,
      requestedBuyIn: players[pos].requested_buy_in,
      pendingBuyIn: players[pos].pending_buy_in,
      showingHand: players[pos].is_showing_cards_publicly,
      isWinner: players[pos].is_winner,
      isGod: players[pos].is_god,
      showCardsToGod: players[pos].showing_to_god,
      handsWon: players[pos].hands_won,
    };
    newMaxPotCents = Math.max(newMaxPotCents, players[pos].pot);
    newBetsOnTableSumCents += players[pos].pot;
    if (players[pos].name === state.username && players[pos].is_in_hand) {
      newStillInHand = true;
      newOwnPotCents = players[pos].pot;
    }
    // if is client player
    if (players[pos].name === state.username) {
      newOwnStackCents = players[pos].stack;
      if (players[pos].is_in_game) {
        newStillInGame = true;
      }
      newRequestedBuyIn = newPlayer.requestedBuyIn > 0 ? true : false;
      newPendingBuyIn = newPlayer.pendingBuyIn > 0 ? true : false;
      newIsGod = players[pos].is_god;
      newShowCardsToGod = players[pos].showing_to_god;
    }
    newActivePlayers.push(newPlayer);
  }
  return {
    activePlayers: newActivePlayers,
    maxPotCents: newMaxPotCents,
    stillInHand: newStillInHand,
    stillInGame: newStillInGame,
    ownStackCents: newOwnStackCents,
    ownPotCents: newOwnPotCents,
    showBuyIn:
      !newStillInHand &&
      !newRequestedBuyIn &&
      !newPendingBuyIn &&
      (newOwnStackCents === 0 || !newOwnStackCents),
    betsOnTableSumCents: newBetsOnTableSumCents,
    isGod: newIsGod,
    showCardsToGod: newShowCardsToGod,
  };
};
