import React from "react";
import { SmallHeaderText } from "../../../../shared/Text";
import { PrimaryButton } from "../../../../shared/FormElements";
import { colors } from "../../../../helpers/styles";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../../../helpers/constants";
import styled from "styled-components";

const UnnecessaryFoldView = ({
  isMobile,
  onFoldClick,
  setShowUnnecessaryFoldView,
}) => {
  return (
    <>
      <InstructionsText>
        {!isMobile
          ? `You're about to make an unnecessary fold. Are you sure?`
          : `Are you sure?`}
      </InstructionsText>
      <PrimaryButton
        keyboardChar="F"
        onClick={onFoldClick}
        id="real-fold-btn"
        dataTestId="real-fold-btn"
      >
        FOLD
      </PrimaryButton>
      <PrimaryButton
        keyboardChar="B"
        secondaryColor
        onClick={() => setShowUnnecessaryFoldView(false)}
      >
        Back
      </PrimaryButton>
    </>
  );
};

const InstructionsText = styled(SmallHeaderText)`
  position: absolute;
  top: -20px;
  text-align: right;
  color: ${colors.grey};
  padding-right: 10px;
  transform: translateY(-100%);
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    color: ${colors.darkGrey};
    top: -10px;
  }
`;

export default UnnecessaryFoldView;
