import React from "react";
import styled, { withTheme } from "styled-components";
import { colors } from "../../helpers/styles";

const Switch = ({ isOn, handleToggle, dataTestId }) => {
  return (
    <CheckboxLabel
      onClick={handleToggle}
      isOn={isOn}
      className="react-switch-label"
      htmlFor={`react-switch-new`}
      data-testid={dataTestId}
    >
      <SwitchButton className={`react-switch-button`} isOn={isOn} />
    </CheckboxLabel>
  );
};

const SwitchButton = styled.span`
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transition: 0.2s;
  background: ${colors.white};
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  top: 5px;
  left: 5px;
  ${(props) =>
    props.isOn &&
    `
    transform: translateX(100%);
    left: 16px;
  `}
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 70px;
  height: 35px;
  background: ${({ theme }) => theme.switch.background};
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 70px;
  margin: 0;
  ${(props) =>
    props.isOn &&
    props.theme &&
    `
    background: ${props.theme.tertiary}
  `}
`;

export default withTheme(Switch);
