import React, { Fragment, useEffect, useContext } from "react";
import styled, { withTheme } from "styled-components";
import { font } from "../../../helpers/styles";
import { BrowserContext } from "../../../context/BrowserContext";

const charToKeyCodeMap = {
  R: 82,
  F: 70,
  C: 67,
  B: 66,
  S: 83,
  M: 77,
  X: 88,
  E: 69,
  "+": 187,
  "-": 189,
  "<LEFT>": 37,
  "<UP>": 38,
  "<RIGHT>": 39,
  "<DOWN>": 40,
};

const KeyboardChar = ({
  char,
  onClick,
  onKeydown,
  onKeyUp,
  style,
  foregroundColor,
  theme,
  color,
}) => {
  const ContextConsumer = useContext(BrowserContext);

  // null check for testing, temporarily
  let allowKeyboardShortcuts, existsModal, isMobile, currentModal;
  if (ContextConsumer) {
    allowKeyboardShortcuts = ContextConsumer.allowKeyboardShortcuts;
    // TODO: once all modals migrated to use ModalConductor, existsModal won't need to exist
    existsModal = ContextConsumer.existsModal;
    currentModal = ContextConsumer.currentModal;
    isMobile = ContextConsumer.isMobile;
  }

  useEffect(() => {
    if (char && onClick && char.toUpperCase() in charToKeyCodeMap) {
      document.addEventListener("keydown", handleKeydown);
      document.addEventListener("keyup", handleKeyup);
    }
    return () => {
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("keyup", handleKeyup);
    };
  });

  const _matchesKey = (e) => {
    const keyCode = e.keyCode;
    const charFromProps = char.toUpperCase();
    return (
      keyCode === charToKeyCodeMap[charFromProps] &&
      allowKeyboardShortcuts &&
      currentModal == null &&
      !existsModal
    );
  };

  const handleKeydown = (e) => {
    if (onKeydown && _matchesKey(e)) {
      onKeydown(e);
    }
  };

  const handleKeyup = (e) => {
    if (_matchesKey(e)) {
      if (onKeyUp) {
        onKeyUp(e);
      }
      if (onClick) {
        onClick(e);
      }
    }
  };

  if (isMobile) {
    return <Fragment />;
  }

  return (
    <KeyboardCharContent
      style={style}
      onClick={onClick}
      color={foregroundColor ? theme.foreground : color}
    >
      <span>{char}</span>
    </KeyboardCharContent>
  );
};

const KeyboardCharContent = styled.div`
  border: 2px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
  color: ${({ theme }) => theme.primary};
  background: ${({ theme }) => theme.keyboardChar.background};
  font-family: ${font.header};
  ${(props) =>
    props.color &&
    `
      border-color: ${props.color};
      color: ${props.color};
  `};
  ${(props) =>
    props.theme.keyboardChar.text &&
    `
    color: ${props.theme.keyboardChar.text};
  `}
  ${(props) =>
    props.onClick &&
    `
    cursor: pointer;
  `};
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  span {
    margin-top: 2px;
    margin-left: -1px;
  }
`;

export default withTheme(KeyboardChar);
