import { actions, stateReasons } from "../helpers/constants";

export const queuedActionReducer = (state, action, stateUpdate) => {
  let queuedAction = state.queuedAction;

  // reset queued action on a new round/hand
  if (
    action.payload.reason === stateReasons.REASON_NEW_HAND ||
    action.payload.reason === stateReasons.REASON_DEAL ||
    action.payload.reason === stateReasons.REASON_FLOP
  )
    queuedAction = null;

  // potentially modify the queued action depending on the latest bets
  if (queuedAction) {
    // if the user can't check anymore, then remove the queued action
    const canUserCheck = !(
      stateUpdate.maxPotCents &&
      stateUpdate.ownPotCents < stateUpdate.maxPotCents
    );
    if (queuedAction.actionType === actions.CHECK && !canUserCheck) {
      queuedAction = null;
    } else if (queuedAction.actionType === actions.CHECKFOLD && !canUserCheck) {
      queuedAction = {
        actionType: actions.FOLD,
        betValue: -1,
      };
    }
    // if the user was set to call a specific amount, but can't anymore, remove the queued action
    else if (
      queuedAction.actionType === actions.CALL &&
      queuedAction.betValue !== -1
    ) {
      const canUserCallSpecifiedAmount =
        stateUpdate.maxPotCents - stateUpdate.ownPotCents ===
        queuedAction.betValue;

      if (!canUserCallSpecifiedAmount) {
        queuedAction = null;
      }
    }
  }
  const clientPlayer = stateUpdate.activePlayers.filter(
    (player) => player.playerName === state.username
  )[0];
  const isMyTurn = clientPlayer ? clientPlayer.playerIsTurn : false;
  return {
    queuedAction,

    // It is guaranteed at this point that if the queued action still exists, it will execute if it's the user's turn
    // So we track this in a state variable willExecuteQueuedAction
    willExecuteQueuedAction: queuedAction !== null && isMyTurn,
  };
};
