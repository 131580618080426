import React, { Fragment, useState } from "react";
import { HandRankingsModal } from "../../components/Modals";
import { logClickHandRankingEvent } from "../../helpers/logger";
import Widget from "../Widget";

const HandRankingsWidget = ({
  isMobile,
  setAllowKeyboardShortcuts,
  setExistsModal,
  isFourColorDeck,
  playerHand,
}) => {
  const [showModal, _setShowModal] = useState(false);

  const setShowModal = (show) => {
    _setShowModal(show);
    setAllowKeyboardShortcuts(!show);
    setExistsModal(show);
  };

  return (
    <Fragment>
      <Widget
        onClick={() => {
          setShowModal(true);
          logClickHandRankingEvent();
        }}
        text={isMobile ? "Hands" : "Hand Rankings"}
        showNotif={false}
        left={isMobile ? "2px" : "6px"}
        //right={isMobile ? "85px" : "135px"}
      />
      <HandRankingsModal
        isMobile={isMobile}
        show={showModal}
        hideModal={() => {
          setShowModal(false);
        }}
        isFourColorDeck={isFourColorDeck}
        playerHand={playerHand}
      />
    </Fragment>
  );
};

export default HandRankingsWidget;
