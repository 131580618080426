import React from "react";
import { MediumHeaderText } from "../../../shared/Text";
import {
  OverlayWrapper,
  ListWrapper,
  ListBodyText,
} from "../../../shared/Overlay";

export const TournamentInfoOverlay = (props) => {
  return (
    <OverlayWrapper {...props}>
      <MediumHeaderText>Tournament Mode Features</MediumHeaderText>
      <ListWrapper>
        <ul>
          <li>
            <ListBodyText>Increasing Blinds</ListBodyText>
          </li>
          <li>
            <ListBodyText>Mandatory Timer</ListBodyText>
          </li>
          <li>
            <ListBodyText>Automatic Buy Ins</ListBodyText>
          </li>
          <li>
            <ListBodyText>Disabled Buy In Requests</ListBodyText>
          </li>
        </ul>
      </ListWrapper>
    </OverlayWrapper>
  );
};

export const GodModeOverlay = (props) => {
  return (
    <OverlayWrapper {...props}>
      <MediumHeaderText>God Mode Features</MediumHeaderText>
      <ListWrapper>
        <ListBodyText>
          Players have the option to show or hide their cards from the creator
          of the game. This mode is ideal for teaching or streaming.
        </ListBodyText>
      </ListWrapper>
    </OverlayWrapper>
  );
};
