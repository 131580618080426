export const nameMap = {
  "High Card": "10. High Card",
  Pair: "9. Pair",
  "Two Pair": "8. Two Pair",
  Trips: "7. Three of a Kind",
  Straight: "6. Straight",
  Flush: "5. Flush",
  "Full House": "4. Full House",
  Quads: "3. Four of a Kind",
  "Straight Flush": "2. Straight Flush",
};

export const handsList = [
  {
    name: "1. Royal Flush",
    cards: [
      {
        suit: "heart",
        number: "A",
      },
      {
        suit: "heart",
        number: "K",
      },
      {
        suit: "heart",
        number: "Q",
      },
      {
        suit: "heart",
        number: "J",
      },
      {
        suit: "heart",
        number: "10",
      },
    ],
  },
  {
    name: "2. Straight Flush",
    cards: [
      {
        suit: "heart",
        number: "5",
      },
      {
        suit: "heart",
        number: "4",
      },
      {
        suit: "heart",
        number: "3",
      },
      {
        suit: "heart",
        number: "2",
      },
      {
        suit: "heart",
        number: "A",
      },
    ],
  },
  {
    name: "3. Four of a Kind",
    cards: [
      {
        suit: "heart",
        number: "4",
      },
      {
        suit: "diamond",
        number: "4",
      },
      {
        suit: "club",
        number: "4",
      },
      {
        suit: "spade",
        number: "4",
      },
      {
        isEmpty: true,
      },
    ],
  },
  {
    name: "4. Full House",
    cards: [
      {
        suit: "club",
        number: "8",
      },
      {
        suit: "diamond",
        number: "8",
      },
      {
        suit: "club",
        number: "5",
      },
      {
        suit: "spade",
        number: "5",
      },
      {
        suit: "heart",
        number: "5",
      },
    ],
  },
  {
    name: "5. Flush",
    cards: [
      {
        suit: "club",
        number: "9",
      },
      {
        suit: "club",
        number: "2",
      },
      {
        suit: "club",
        number: "4",
      },
      {
        suit: "club",
        number: "6",
      },
      {
        suit: "club",
        number: "A",
      },
    ],
  },
  {
    name: "6. Straight",
    cards: [
      {
        suit: "spade",
        number: "9",
      },
      {
        suit: "diamond",
        number: "8",
      },
      {
        suit: "diamond",
        number: "7",
      },
      {
        suit: "club",
        number: "6",
      },
      {
        suit: "heart",
        number: "5",
      },
    ],
  },
  {
    name: "7. Three of a Kind",
    cards: [
      {
        suit: "spade",
        number: "2",
      },
      {
        suit: "heart",
        number: "2",
      },
      {
        suit: "diamond",
        number: "2",
      },
      {
        isEmpty: true,
      },
      {
        isEmpty: true,
      },
    ],
  },
  {
    name: "8. Two Pair",
    cards: [
      {
        suit: "club",
        number: "9",
      },
      {
        suit: "diamond",
        number: "9",
      },
      {
        suit: "spade",
        number: "K",
      },
      {
        suit: "diamond",
        number: "K",
      },
      {
        isEmpty: true,
      },
    ],
  },
  {
    name: "9. Pair",
    cards: [
      {
        suit: "heart",
        number: "6",
      },
      {
        suit: "club",
        number: "6",
      },
      {
        isEmpty: true,
      },
      {
        isEmpty: true,
      },
      {
        isEmpty: true,
      },
    ],
  },
  {
    name: "10. High Card",
    cards: [
      {
        suit: "spade",
        number: "A",
      },
      {
        isEmpty: true,
      },
      {
        isEmpty: true,
      },
      {
        isEmpty: true,
      },
      {
        isEmpty: true,
      },
    ],
  },
];
