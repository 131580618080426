import React, { useContext } from "react";
import Table from "./Table";
import PlayersAndPots from "./PlayersAndPots";
import ActionButtons from "./ActionButtons";
import styled from "styled-components";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../helpers/constants";
import { BrowserContext } from "../../context/BrowserContext";
import { removeScrollbars } from "../../shared/Overlay";

const GameWindow = ({
  emitSocketAction,
  emitSocketMessage,
  activePlayers,
  iframeClientName,
}) => {
  const { showVideoChat, isMobile } = useContext(BrowserContext);
  const playersAreInHand =
    activePlayers.filter((player) => player.playerIsInHand).length > 0;
  const winningPlayers = activePlayers.filter((player) => player.isWinner);

  return (
    <GameWrapper showVideoChat={showVideoChat && !isMobile}>
      <TableWrapper>
        <Table
          playersAreInHand={playersAreInHand}
          isMobile={isMobile}
          iframeClientName={iframeClientName}
        />
        <PlayersAndPots
          emitSocketMessage={emitSocketMessage}
          winningPlayers={winningPlayers}
        />
      </TableWrapper>
      <ActionButtons
        emitSocketAction={emitSocketAction}
        emitSocketMessage={emitSocketMessage}
        numWinners={winningPlayers.length}
      />
    </GameWrapper>
  );
};

const GameWrapper = styled.div`
  height: 100%;
  width: ${(props) => (props.showVideoChat ? `85` : `100`)}%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  overflow: scroll; // overriden by removeScrollbars
  ${removeScrollbars}
`;

const TableWrapper = styled.div`
  height: 50vh;
  width: 75vw;
  margin-top: -10vh;
  position: relative;
  text-align: center;
  display: block;
  z-index: -2;
  transform: translateY(30px);
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    width: 90vw;
    height: 55vh;
  }
`;

export default GameWindow;
