import React from "react";
import { PrimaryButton } from "../../../../shared/FormElements";
import { withTheme } from "styled-components";
import generateQueuedActions from "./options";
import { ActionButtonWrapper } from "../shared";

const PreTurnActions = ({
  updateQueuedAction,
  selectedAction,
  canUserCheck,
  callAmount,
  betButtonsOnRight,
  theme,
  isInCents,
}) => {
  const queuedActions = generateQueuedActions(
    canUserCheck,
    callAmount,
    isInCents
  );

  return (
    <ActionButtonWrapper betButtonsOnRight={betButtonsOnRight}>
      {queuedActions.map((queuedAction) => {
        const selected =
          selectedAction &&
          selectedAction.actionType === queuedAction.actionType &&
          selectedAction.betValue === queuedAction.betValue;

        return (
          <PrimaryButton
            key={queuedAction.displayName}
            keyboardChar={queuedAction.keyboardChar}
            color={selected ? theme.secondaryLighter : theme.disabled}
            onClick={() =>
              selected
                ? updateQueuedAction(null)
                : updateQueuedAction({
                    actionType: queuedAction.actionType,
                    betValue: queuedAction.betValue,
                  })
            }
            selected={selected}
          >
            {queuedAction.displayName}
          </PrimaryButton>
        );
      })}
    </ActionButtonWrapper>
  );
};

export default withTheme(PreTurnActions);
