import styled from "styled-components";
import { font, colors } from "../../../helpers/styles";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../../helpers/constants";

export const BaseButton = styled.button`
  font-weight: bold;
  border-radius: 10px;
  &:active,
  &:focus {
    outline: 0;
  }
  transition: background 0.3s ease;
  ${(props) =>
    props.disable &&
    `
    background: ${({ theme }) => theme.foreground} !important;
    pointer-events: none;
  `}
`;

export const Button = styled(BaseButton)`
  text-transform: uppercase;
  font-family: ${font.header};
  font-size: 12px;
  letter-spacing: 0.6px;
  color: white;
  border: 1px solid ${colors.white};
  width: 130px;
  height: 30px;
  background: ${({ theme }) => theme.tertiary};
  white-space: nowrap;
  ${(props) => props.fullWidth && `width: 100%;`}
  @media screen and (max-width: ${mobileBreakpoint}), screen and (max-height: ${mobileHeightBreakpoint}) {
    font-size: 12px;
    padding: 8px 12px;
    ${(props) => props.fullWidth && `width: 100%;`}
  }
  ${(props) =>
    props.disable &&
    `
    opacity: 0.3;
    pointer-events: none;
  `}
`;

export const PrimaryButton = styled(Button)`
  background: ${({ theme }) => theme.primary};
  color: white;
  border: 1px solid ${({ theme }) => theme.foreground};
  color: ${colors.white};
  height: 60px;
  width: 120px;
  padding: 0 15px;
  top: 0px;
  font-weight: 400;
  font-size: 18px;
  ${(props) =>
    !props.disableShadow &&
    `
    &:active {
      transform: translate(5px, 5px);
    }
  `}
  ${(props) =>
    props.isClicked
      ? `background: ${({ theme }) => theme.tertiary}; color: ${
          colors.white
        }; outline: 0;`
      : ``} &:focus {
    outline: 0;
  }
  ${(props) =>
    !props.disableShadow &&
    props.pressed &&
    `
      transform: translate(5px, 5px);
    `}
  border-radius: 5px;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    width: 80px;
    height: 40px;
  }
`;
