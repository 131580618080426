/**
 * Add all new modals to be rendered into components/Global/ModalConductor/index.js
 */
import React, { Fragment, useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import { ModalButton } from "../FormElements";
import { MediumHeaderText, SmallBodyText } from "../Text";
import { styles } from "../../helpers/styles";
import { MainOverlay, removeScrollbars } from "../Overlay";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../helpers/constants";

const transitionTime = 1200;
const Modal = ({
  title,
  subtitle,
  children,
  btnText,
  onBtnClick,
  btnTextAlt,
  onBtnAltClick,
  show,
  transitionOut,
  loading,
  margin,
  onOverlayClick,
  align,
  ctaContent,
  isInvalid,
  dataTestId,
  dataTestAltId,
  fullWidthInput,
  scrollMiddle,
}) => {
  const [actionDone, setActionDone] = useState(false);
  const modalContainerRef = useRef(null);

  const onCTAClick = (func) => async (e) => {
    setActionDone(true);
    const promise = func(e);
    if (func && promise)
      promise.then((isDone) => {
        if (!isDone) {
          setActionDone(false);
        }
      });
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  const scrollToBottom = () => {
    if (modalContainerRef.current) {
      modalContainerRef.current.scrollTop = scrollMiddle
        ? (modalContainerRef.current.scrollHeight -
            modalContainerRef.current.clientHeight) /
          2
        : 0;
    }
  };

  if (!show) {
    return <Fragment></Fragment>;
  }

  return (
    <ModalOuter
      ref={modalContainerRef}
      transitionOut={transitionOut}
      isLoading={actionDone || loading}
    >
      <CenterModal margin={margin}>
        <MainOverlay
          onClick={onOverlayClick}
          transitionTimeSeconds={transitionTime / 1000}
          hide={transitionOut}
        />
        <ModalWidthWrapper>
          <ModalWrapper>
            <ModalContent>
              {(title || subtitle) && (
                <HeaderWrapper>
                  {title && <ModalTitle align={align}>{title}</ModalTitle>}
                  {subtitle && <ModalSubTitle>{subtitle}</ModalSubTitle>}
                </HeaderWrapper>
              )}
              {children}
            </ModalContent>
            {btnText && (
              <CTABottom>
                {ctaContent}
                {btnTextAlt && (
                  <ModalButton
                    disable={isInvalid}
                    data-testid={dataTestAltId}
                    onClick={onCTAClick(onBtnAltClick)}
                    fullWidth={fullWidthInput}
                  >
                    {btnTextAlt}
                  </ModalButton>
                )}
                <ModalButton
                  disable={isInvalid}
                  data-testid={dataTestId}
                  onClick={onCTAClick(onBtnClick)}
                  fullWidth={fullWidthInput}
                >
                  {btnText}
                </ModalButton>
              </CTABottom>
            )}
          </ModalWrapper>
        </ModalWidthWrapper>
      </CenterModal>
    </ModalOuter>
  );
};

const ModalOuter = styled.div`
  position: fixed;
  overflow: scroll; // overriden by removeScrollbars
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 8;
  ${(props) =>
    props.transitionOut &&
    css`
      pointer-events: none;
      ${ModalWrapper} {
        margin-top: 100px;
        opacity: 0;
        pointer-events: none;
      }
    `}
  ${(props) =>
    props.isLoading &&
    css`
      pointer-events: none;
      ${ModalContent} > * {
        opacity: 0.5;
      }
      ${CTABottom} {
        opacity: 0.5;
      }
    `}
  ${removeScrollbars}
`;

export const CenterModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.margin && `margin: 100px 0`};
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    ${(props) => props.margin && `margin: 100px 0 200px 0`};
  }
`;

const ModalWidthWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const ModalWrapper = styled.div`
  width: 50vw;
  max-width: 90%;
  min-width: 300px;
  z-index: 13;
  border-radius: 5px;
  overflow: hidden;
  transition: 0.8s ease all;
  box-shadow: ${styles.largeBoxShadowDark};
  background: ${(props) => props.theme && props.theme.modal.background};
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    width: 70vw;
  }
`;

const bottomCTAHeight = 70;
export const ModalContent = styled.div`
  padding: 30px 0px 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  justify-self: center;
  &:hover,
  &:focus {
    outline: 0;
  }
`;

const HeaderWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.modal.divider};
  padding-bottom: 20px;
  margin-bottom: 20px;
  width: 100%;
`;

const ModalTitle = styled(MediumHeaderText)`
  color: ${({ theme }) => theme.tertiary};
  text-transform: uppercase;
`;

const ModalSubTitle = styled(SmallBodyText)`
  color: ${({ theme }) => theme.modal.subtitle};
  text-transform: uppercase;
  text-align: center;
`;

const CTABottom = styled.div`
  bottom: 0;
  width: 100%;
  background: ${({ theme }) => theme.modal.cta};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 30px;
  height: ${bottomCTAHeight}px;
  transition: 0.2s ease all;
  z-index: -1;
`;

export default Modal;
export { transitionTime };
