import React, { Fragment, useState } from "react";
import { FAQModal } from "../../components/Modals";
import { Icon } from "../FormElements";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import Widget from "../Widget";

const FAQWidget = ({
  isMobile,
  setAllowKeyboardShortcuts,
  setExistsModal,
  isFourColorDeck,
}) => {
  const [showFAQModal, _setShowFAQModal] = useState(false);

  const setShowFAQModal = (show) => {
    _setShowFAQModal(show);
    setAllowKeyboardShortcuts(!show);
    setExistsModal(show);
  };

  return (
    <Fragment>
      <Widget
        onClick={() => setShowFAQModal(true)}
        text={<Icon icon={faQuestionCircle} widget />}
        showNotif={false}
        left={isMobile ? "2px" : "6px"}
      />
      <FAQModal
        isMobile={isMobile}
        show={showFAQModal}
        hideModal={() => {
          setShowFAQModal(false);
        }}
        isFourColorDeck={isFourColorDeck}
      />
    </Fragment>
  );
};

export default FAQWidget;
