import React, { useReducer, createContext } from "react";
import gameReducer, { INITIAL_STATE } from "../reducers/game";
import * as actions from "../actions/game";
import { connectActionCreators } from "../helpers/context";
import useNext from "../hooks/useNext";

export const GameContext = createContext();

export const GameProvider = ({ children }) => {
  const [state, dispatch] = useReducer(gameReducer, INITIAL_STATE);

  const nextState = useNext(state);
  const value = {
    ...state,
    ...connectActionCreators(actions, dispatch, state),
    nextState,
  };

  return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
};

export const GameConsumer = GameContext.Consumer;
