import React, { Component, Fragment } from "react";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";

import styled, { ThemeProvider } from "styled-components";
import Chat from "./Chat";
import * as Modal from "./Modals";
import VideoChat from "./VideoChat";
import FixedWidgets from "./FixedWidgets";
import MTTLobbyWidgets from "./MTTLobbyWidgets";
import MTTLobby from "./MTTLobby";
import Global from "./Global";
import "bootstrap/dist/css/bootstrap.min.css";
import "typeface-rambla";
import { hot, setConfig } from "react-hot-loader";
import { modals } from "../helpers/constants";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
  mobileBreakpointRaw,
  mobileHeightBreakpointRaw,
  themes,
  blinds,
  timer,
} from "../helpers/constants";
import themesDict, { defaultTheme } from "../helpers/theme";
import {
  initSocket,
  forceDisconnect,
  emit,
  socketOff,
  setOnConnect,
  emitEnsureInRoom,
  setOnDisconnect,
  setOnStateChange,
  setOnMTTStateChange,
  setOnLeaderboard,
  setOnSound,
  setBootInactive,
  setOnDistributeChat,
  setOnDifferentLogIn,
  setOnSettingsChange,
  socketIsConnected,
  setOnPlayerStateChange,
  setOnSpectatorStateChange,
  setOnPlayerFieldsChange,
  connectEmitSocketMessage,
  connectEmitSocketAction,
  setMTTGameEnd,
  setMTTPlayers,
  setOnMTTUsersState,
} from "../helpers/socket";
import api, { checkAlive } from "../helpers/api";
import { refreshPage } from "../helpers/utils";
import {
  initLogger,
  logJoinGameEvent,
  logCreateGameEvent,
  setLoggerIdentity,
  logError,
} from "../helpers/logger";

import GameWindow from "./GameWindow";
import { getMTTPlayers, setMTTUsersState } from "../actions/game";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // this is actually a prop, so can't be in context
      // to avoid prop drilling, use withRouter() on relevant components
      gameId: "", // stores url parameter
      MTTId: "",
    };

    // meant to be called from settings or newgame (not join!)
    // to remove overlay, and only the newgame that involves making a new game
    this._cancelPopup = () => {
      if (this.state.gameId.length > 0) {
        this.props.context.updateDisplay("play");
      }
      this.props.browserContext.setCurrentModal(null);
      // TODO: call endpoint that caches to backend
    };
    this._openNewGameDisplay = () => {
      // this.props.context.updateDisplay("newGame");
      return checkAlive(
        (resolve) => {
          this.props.context.updateDisplay("newGame");
          return true;
        },
        (reject) => {
          this.props.browserContext.setCurrentModal(
            modals.DISCONNECTED_SERVER_NOT_IN_GAME
          );
          return false;
        }
      );
    };

    this._openNewMTTDisplay = () => {
      return checkAlive(
        (resolve) => {
          this.props.context.updateDisplay("newMTT");
          return true;
        },
        (reject) => {
          this.props.browserContext.setCurrentModal(
            modals.DISCONNECTED_SERVER_NOT_IN_GAME
          );
          return false;
        }
      );
    };

    this._getOverallStats = async () => {
      try {
        const { data } = await api.get("/get_overall_stats", {
          params: {},
        });
        this.setState({
          numGames: data.numGames.toLocaleString(),
          numPlayers: data.numPlayers.toLocaleString(),
        });
      } catch (error) {
        logError(error);
        console.error(error);
      }
    };

    this._openSettingsDisplay = () => {
      this.props.context.updateDisplay("settings");
    };
    // this.backend = '172.17.170.145:5000' // use a global url to avoid changing later

    // player i uses cards i and i + 1, activePlayers in state
    // is stored as dictionary with 0:{...info}
    // example is {0:{playerPot: 3, playerName: "Bob", playerBalance: 14},
    //             1:{playerPot: 3, playerName: "Joe", playerBalance: 13}}
    // this is only for the display in Players and Cards.js, will eventually migrate to
    // a dictionary with players with a position value for where on the table they are
    // instead of the key

    this._joinGame = async (
      username,
      userPassword,
      gamePassword,
      isGod = false
    ) => {
      const response = await this.props.context.joinGame(
        this.state.gameId,
        username,
        userPassword,
        gamePassword,
        isGod
      );
      if (response.error) {
        return {
          error: response.error,
          type: response?.error?.response?.data?.type,
          field: response?.error?.response?.data?.field,
        };
      } else {
        const newState = await this.props.context.nextState();

        initSocket(this.state.gameId, this.state.MTTId, () => {
          emit("join", {
            username: newState.username,
            game_id: this.state.gameId,
            key: newState.sessionKey,
          }); // join room on socket

          if (newState.isTournament && newState.totalBuyinCents === 0) {
            //and if new user (not re-join)
            emit("request_buy_in", {
              username: newState.username,
              game_id: this.state.gameId,
              key: newState.sessionKey,
              amount: newState.autoBuyinCents,
              autobuy: true,
            });
          }

          this.props.browserContext.setAllowKeyboardShortcuts(true);
          this.props.browserContext.setExistsModal(false);

          setLoggerIdentity(
            newState.username,
            this.state.gameId,
            this.props.iframeClientName
          );

          logJoinGameEvent();

          this._initSocketListeners();
        });
        return {};
      }
    };

    this._spectateGame = async (
      username,
      userPassword,
      gamePassword,
      isGod = false
    ) => {
      const response = await this.props.context.spectateGame(
        this.state.gameId,
        username,
        userPassword,
        gamePassword,
        isGod
      );
      if (response.error) {
        return {
          error: response.error,
          type: response?.error?.response?.data?.type,
          field: response?.error?.response?.data?.field,
        };
      } else {
        const newState = await this.props.context.nextState();
        initSocket(this.state.gameId, this.state.MTTId, () => {
          emit("spectate", {
            username: newState.username,
            game_id: this.state.gameId,
            key: newState.sessionKey,
          }); // join room on socket

          this.props.browserContext.setAllowKeyboardShortcuts(true);
          this.props.browserContext.setExistsModal(false);

          setLoggerIdentity(
            newState.username,
            this.state.gameId,
            this.props.iframeClientName
          );

          this._initSocketListenersSpectators();
        });
        return {};
      }
    };

    this._joinMTT = async (username, userPassword, MTTPassword) => {
      const response = await this.props.context.joinMTT(
        this.state.MTTId,
        username,
        userPassword,
        MTTPassword
      );
      if (response.error) {
        return {
          error: response.error,
          type: response?.error?.response?.data?.type,
          field: response?.error?.response?.data?.field,
        };
      } else {
        const newState = await this.props.context.nextState();

        initSocket(this.state.gameId, this.state.MTTId, () => {
          emit("join_mtt", {
            username: newState.username,
            mtt_id: this.state.MTTId,
            user_password: newState.userPassword,
            key: newState.sessionKey,
          }); // join room on socket

          this._initSocketListeners();
        });
        return {};
      }
    };

    this._initSocketListeners = () => {
      socketOff();
      this._setChatRecieveSocket();
      this._setGameStateSocket();
      this._setUpdateLeaderboardSocket();
      this._setSoundsSocket();
      this._setBootInactiveSocket();
      this._setDisconectSocket();
      this._setDifferentLogInSocket();
      this._setReconnectSocket();
      this._setSettingsSocket();
      this._setPlayerStateChangeSocket();
      this._setSpectatorStateChangeSocket();
      this._setOnPlayerFieldsChangeSocket();
      this._setMTTGameEnd();
      this._setMTTPlayers();
      this._setOnMTTUsersStateSocket();
      this._setMTTStateSocket();
    };

    this._initSocketListenersSpectators = () => {
      socketOff();
      this._setChatRecieveSocket();
      this._setGameStateSocket();
      this._setUpdateLeaderboardSocket();
      this._setDisconectSocket();
      this._setDifferentLogInSocket();
      this._setReconnectSocket();
      this._setSettingsSocket();
      this._setPlayerStateChangeSocket();
      this._setSpectatorStateChangeSocket();
      this._setOnPlayerFieldsChangeSocket();
      this._setMTTGameEnd();
      this._setMTTPlayers();
      this._setOnMTTUsersStateSocket();
      this._setMTTStateSocket();
    };

    this._setBootInactiveSocket = () => {
      if (this.props.context.username) {
        // if (this.props.context.username && this.state.gameId) {
        setBootInactive(
          this.props.context.username,
          this.state.gameId,
          (disconnectedUser) => {
            this.props.context.setUserDisconnected(disconnectedUser);
          }
        );
      }
    };

    this._setChatRecieveSocket = () => {
      if (this.props.context.username) {
        //if (this.props.context.username && this.state.gameId) {
        setOnDistributeChat((chat_obj) => {
          let timeout = 0;
          if ("timeout" in chat_obj) {
            timeout = chat_obj["timeout"];
          }
          setTimeout(() => {
            this.props.context.receiveChatMessage(chat_obj);
          }, timeout * 1000);
        });
      }
    };

    this._setDifferentLogInSocket = () => {
      setOnDifferentLogIn(() => {
        // Auto-reconnect code,
        // OLD: prone to looping when server class db fields change and db needs to be migrated locally
        // OLD: Reconnect loop needed or else it reconnects with a socket that can send to the server but not recieve

        this.props.context.updateDisplay("different_log_in");
      });
    };

    this._setReconnectSocket = () => {
      setOnConnect(() => {
        emitEnsureInRoom(
          this.props.context.username,
          this.state.gameId,
          this.props.context.sessionKey,
          this.props.context.isSpectating,
          () => {
            this._cancelPopup();
            // No identify method for fs, since reidentifying causes a split
            // if (!this.props.context.isSpectating) {
            //   this._initSocketListeners();
            // } else {
            //   this._initSocketListenersSpectators();
            // }
          }
        );
      });
    };

    this._setDisconectSocket = () => {
      setOnDisconnect(() => {
        // Auto-reconnect code
        // OLD: prone to looping when server class db fields change and db needs to be migrated locally
        // OLD: Reconnect loop needed or else it reconnects with a socket that can send to the server but not recieve

        // if alive and connected, then don't set to refresh
        // else, set it to refresh
        return checkAlive(
          (resolve) => {
            setTimeout(() => {
              if (!socketIsConnected()) {
                this.props.context.updateDisplay("disconnected");
              }
            }, 1200);
          },
          (reject) => {
            setTimeout(() => {
              if (!socketIsConnected()) {
                this.props.context.updateDisplay("disconnected");
              }
            }, 1200);
          }
        );
      });
    };

    this._setGameStateSocket = () => {
      setOnStateChange((newGameState) => {
        let timeout = newGameState["timeout"];
        setTimeout(() => {
          this.props.context.updateGameStateFromSocket(newGameState);
        }, timeout * 1000);
      });
    };

    this._setMTTStateSocket = () => {
      setOnMTTStateChange((newMTTState) => {
        let timeout = newMTTState["timeout"];
        console.log("mtt end timeout", timeout);
        setTimeout(() => {
          this.props.context.updateMTTStateFromSocket(newMTTState);
        }, timeout * 1000);
      });
    };

    this._setUpdateLeaderboardSocket = () => {
      setOnLeaderboard((leaderboard_resp) => {
        let timeout = 0;
        if ("timeout" in leaderboard_resp) {
          timeout = leaderboard_resp["timeout"];
        }
        setTimeout(() => {
          this.props.context.updateLeaderboard(leaderboard_resp);
        }, timeout * 1000);
      });
    };

    this._setSoundsSocket = () => {
      setOnSound((data) => {
        this.props.browserContext.playSound(data.action);
      });
    };

    this._setMTTGameEnd = () => {
      setMTTGameEnd((data) => {
        setTimeout(() => {
          this.props.context.updateDisplay("MTTGameEnd");
        }, data["timeout"] * 1000);
      });
    };

    this._setMTTPlayers = () => {
      setMTTPlayers((data) => {
        this.props.context.updateMTTState(data.gameId);
        if (data["reason"] == "mtt_distribute") {
          this.props.context.updateDisplay("MTTDistribute");
        }
        setTimeout(() => {
          emit("start_mtt_games_in_round", {
            mtt_id: this.props.context.MTTId,
          });
        }, 7 * 1000);

        // initSocket(data.gameId, this.state.MTTId, () => {
        //   this._initSocketListeners();
        // });
      });
    };

    this._setOnMTTUsersStateSocket = () => {
      setOnMTTUsersState((data) => {
        this.props.context.setMTTUsersState(data.users, data.reason);
      });
    };

    this._createGame = async (
      smallBlindCents,
      gamePassword,
      preferredGameId = "",
      iframeClientName = "game",
      isTimerEnabled = false,
      timerLengthSeconds = 30,
      handTimerLengthSeconds = timer.MEDIUM_HAND_TIMER_SECONDS,
      isGodModeEnabled = false,
      isTournament = false,
      autoBuyinCents = 1000,
      blindIncreaseSeconds = 600,
      isInCents = true,
      cb = null
    ) => {
      try {
        const gameId = await this.props.context.createGame(
          smallBlindCents,
          gamePassword,
          preferredGameId,
          isTimerEnabled,
          timerLengthSeconds,
          handTimerLengthSeconds,
          isGodModeEnabled,
          isTournament,
          autoBuyinCents,
          Math.max(blindIncreaseSeconds, 60),
          isInCents
        );
        forceDisconnect();
        let pathname = `/${iframeClientName}/${gameId}`;
        this.props.history.push({
          pathname: pathname,
          search: this.props.location.search,
        });
        logCreateGameEvent();
        if (cb) {
          cb();
        }
      } catch (error) {
        logError(error);
        console.error(error);
      }
    };

    this._createMTT = async (
      smallBlindCents,
      MTTPassword,
      timerLengthSeconds = 30,
      startingStack = 1000,
      blindIncreaseSeconds = 600,
      isInCents = true,
      cb = null
    ) => {
      try {
        const MTTId = await this.props.context.createMTT(
          smallBlindCents,
          MTTPassword,
          timerLengthSeconds,
          startingStack,
          blindIncreaseSeconds,
          isInCents
        );
        forceDisconnect();
        let pathname = `mtt/${MTTId}`;
        this.props.history.push({
          pathname: pathname,
          search: this.props.location.search,
        });
        if (cb) {
          cb();
        }
      } catch (error) {
        logError(error);
        console.error(error);
      }
    };

    this._setSettingsSocket = () => {
      setOnSettingsChange((data) => {
        this.props.context.setGameSettings(
          data.small_blind,
          data.is_timer_enabled,
          data.timer_length_seconds,
          data.hand_timer_length_seconds,
          data.session_reset
        );
      });
    };

    this._setOnPlayerFieldsChangeSocket = () => {
      setOnPlayerFieldsChange((data) => {
        this.props.context.setPlayerFields(data.username, data.fields);
      });
    };

    this._setPlayerStateChangeSocket = () => {
      setOnPlayerStateChange((data) => {
        const timeout = data["timeout"];
        setTimeout(async () => {
          this.props.context.setPlayerState(data.players, data.reason);
          // only notify once it switches to their turn, not on every state change while it's their turn
          const turnSoundReasons = [
            "REASON_ACTION",
            "REASON_DEAL",
            "REASON_FLOP",
            "REASON_NEW_HAND",
          ];
          const clientPlayer = data.players.filter(
            (player) => player.name === this.props.context.username
          )[0];
          const isMyTurn = clientPlayer ? clientPlayer.turn : false;

          const willBecomeUserTurn =
            isMyTurn && turnSoundReasons.includes(data.reason);

          if (willBecomeUserTurn) {
            // Important: Don't do anything until the player state is updated
            // Queued actions may need to be cancelled based on the previous bet
            await this.props.context.nextState();
            if (this.props.context.willExecuteQueuedAction) {
              this.props.context.executeQueuedAction();
            } else {
              // Play a turn sound if it is the user's turn and they don't have any queued actions
              this.props.browserContext.playSoundDelay("DING", 500);
            }
          }
        }, timeout * 1000);
      });
    };

    this._setSpectatorStateChangeSocket = () => {
      setOnSpectatorStateChange((data) => {
        const timeout = data["timeout"];
        setTimeout(async () => {
          this.props.context.setSpectatorState(data.spectators, data.reason);
        }, timeout * 1000);
      });
    };

    this._parseQueryParams = () => {
      const params = queryString.parse(this.props.location.search);
      // This only sends user to join/spectate a game if autojoin is true, otherwise some form of NewPlayerModal will be displayed
      if (params.autojoin === "true") {
        if (params.username) {
          if (params.spectate === "true") {
            //?username=<username>&autojoin=true&spectate=true
            this._spectateGame(
              decodeURI(params.username),
              "",
              this.props.context.hasGamePassword
                ? this.props.context.prefilledGamePassword
                : ""
            );
          } else {
            //?username=<username>&autojoin=true&spectate=false
            //if game is not full, join game (implement checker in the future)
            this._joinGame(
              decodeURI(params.username),
              "",
              this.props.context.hasGamePassword
                ? this.props.context.prefilledGamePassword
                : ""
            );
          }
        }
      }
    };
  }

  checkMobile = () => {
    const isMobile =
      window.innerWidth <= mobileBreakpointRaw ||
      window.innerHeight <= mobileHeightBreakpointRaw;
    this.props.browserContext.setIsMobile(isMobile);
  };

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  // this function smh
  static getDerivedStateFromProps(props, prevState) {
    let gameId = props.match.params.gameId
      ? props.match.params.gameId
      : props.context.gameId
      ? props.context.gameId
      : "";
    let MTTId = props.match.params.MTTId
      ? props.match.params.MTTId
      : props.context.MTTId
      ? props.context.MTTId
      : "";
    return {
      ...prevState,
      gameId,
      MTTId,
    };
  }

  async componentDidMount() {
    console.log("Didmount called");

    // Load the stats for the site intro page
    this._getOverallStats();

    document.onreadystatechange = () => {
      console.log("Didmount state called");
      this.props.context.updateDisplay("loading");
      if (document.readyState === "complete") {
        if (this.state.MTTId) {
          this.props.context.checkMTTExists(this.state.MTTId);
        } else if (!this.state.gameId) {
          this.props.context.updateDisplay("initial");
        } else {
          this.props.context.checkGameExists(
            this.state.gameId,
            this.props.iframeClientName,
            (exists) => {
              if (!exists) {
                this._createGame(
                  blinds.DEFAULT_SMALL_BLIND_CENTS,
                  "",
                  this.state.gameId,
                  this.props.iframeClientName,
                  false,
                  30,
                  8,
                  false,
                  false,
                  1000,
                  600,
                  true,
                  this._parseQueryParams
                );
                console.log("Created game");
              } else if (this.props.context.display === "login") {
                this._parseQueryParams();
              }
            }
          );
        }
      }
    };

    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
    initLogger();
  }

  render() {
    if (this.props.context.display === "loading") return <Fragment />;
    if (this.props.context.display === "disconnected") {
      if (this.state.gameId.length > 0) {
        this.props.browserContext.setCurrentModal(
          modals.DISCONNECTED_SERVER_IN_GAME
        );
      } else {
        this.props.browserContext.setCurrentModal(
          modals.DISCONNECTED_SERVER_NOT_IN_GAME
        );
      }
    }
    if (this.props.context.display === "different_log_in") {
      this.props.browserContext.setCurrentModal(modals.DIFFERENT_LOG_IN);
    }

    let currentOverlay = <></>; // this is used for modals that are mandatory parts of game flow, ModalController is for interrupts of default game flow
    if (
      this.props.context.display === "initial" &&
      !this.state.gameId &&
      !this.state.MTTId
    ) {
      currentOverlay = (
        <Modal.SiteIntroModal
          continue={this._openNewGameDisplay}
          numGames={this.state.numGames}
          continueAlt={this._openNewMTTDisplay}
        />
      );
    }

    if (this.props.context.display === "login") {
      const params = queryString.parse(this.props.location.search);
      currentOverlay = (
        <Modal.NewPlayerModal
          _logIn={this.state.gameId ? this._joinGame : this._joinMTT}
          _spectateGame={
            this.state.gameId ? this._spectateGame : this._spectateMTT
          }
          prefilledUsername={params.username ? decodeURI(params.username) : ""}
          hasGamePassword={this.props.context.hasGamePassword}
          prefilledGamePassword={this.props.context.prefilledGamePassword}
          spectateOnly={params.spectate === "true"}
          isGod={this.props.context.isGod}
          MTTId={this.props.context.MTTId}
        />
      );
    }
    if (this.props.context.display === "settings") {
      currentOverlay = (
        <Modal.GameSettingsModal
          title={"Game Settings"}
          buttonTitle={"Save"}
          // buttonClick={this._saveGame} # TODO streamline this and add a save game methos
          _cancelPopup={this._cancelPopup}
        />
      );
    }
    if (this.props.context.display === "newGame") {
      currentOverlay = (
        <Modal.GameSettingsModal
          title={"Create New Game"}
          buttonTitle={"Create"}
          _createGame={this._createGame}
          _cancelPopup={this._cancelPopup}
        />
      );
    }
    if (this.props.context.display === "newMTT") {
      currentOverlay = (
        <Modal.MTTSettingsModal
          title={"Create MTT"}
          buttonTitle={"Create"}
          _createMTT={this._createMTT}
          _cancelPopup={this._cancelPopup}
        />
      );
    }
    if (this.props.context.display === "MTTGameEnd") {
      currentOverlay = <Modal.MTTGameEndModal />;
    }

    if (this.props.context.display === "MTTDistribute") {
      currentOverlay = (
        <Modal.MTTDistributeModal
          _joinGame={this._joinGame}
          _cancelPopup={this._cancelPopup}
          history={this.props.history}
          location={this.props.location}
        />
      );
    }
    
    if (this.state.MTTId) {
      console.log("MTT ID: ", this.state.MTTId);
    }
    
    const emitSocketMessage = connectEmitSocketMessage(
      this.props.context.sessionKey,
      this.state.gameId,
      this.props.context.username
    );

    const emitSocketAction = connectEmitSocketAction(
      this.props.context.sessionKey,
      this.state.gameId,
      this.props.context.username
    );
    console.log("Display: ", this.props.context.display);

    return (
      <ThemeProvider
        theme={
          this.props.browserContext.theme in themes
            ? themesDict[this.props.browserContext.theme].theme
            : defaultTheme
        }
      >
        <>
          <Global
            gameId={this.state.gameId}
            provider={this.props.iframeClientName}
          />
          {currentOverlay}
          <Modal.LandscapeSwitchModal />
          <Wrapper reverseOrder={!this.props.browserContext.videoIsOnRight}>
            <CentralLayout fullWidth={!this.props.browserContext.showVideoChat}>
              {!this.state.MTTId ||
              this.props.context.display == "play" ||
              this.props.context.display == "MTTGameEnd" ? (
                <>
                  <FixedWidgets
                    emitSocketMessage={emitSocketMessage}
                    gameId={this.state.gameId}
                    username={this.props.context.username}
                    smallBlindCents={this.props.context.smallBlindCents}
                    isTimerEnabled={this.props.context.isTimerEnabled}
                    timerLengthSeconds={this.props.context.timerLengthSeconds}
                    handTimerLengthSeconds={
                      this.props.context.handTimerLengthSeconds
                    }
                    activePlayers={this.props.context.activePlayers}
                    isSpectating={this.props.context.isSpectating}
                    isTournament={this.props.context.isTournament}
                    initialBlindCents={this.props.context.initialBlindCents}
                    blindIncreaseMinutes={
                      this.props.context.blindIncreaseMinutes
                    }
                  />
                  <GameWindow
                    emitSocketMessage={emitSocketMessage}
                    emitSocketAction={emitSocketAction}
                    activePlayers={this.props.context.activePlayers}
                    iframeClientName={this.props.iframeClientName}
                  />
                  <Chat gameId={this.state.gameId} isMTTLobby={false} />{" "}
                </>
              ) : (
                <>
                  <MTTLobbyWidgets
                    emitSocketMessage={emitSocketMessage}
                    gameId={this.state.gameId}
                    username={this.props.context.username}
                    smallBlindCents={this.props.context.smallBlindCents}
                    isTimerEnabled={this.props.context.isTimerEnabled}
                    timerLengthSeconds={this.props.context.timerLengthSeconds}
                    handTimerLengthSeconds={
                      this.props.context.handTimerLengthSeconds
                    }
                    activePlayers={this.props.context.activePlayers}
                    isSpectating={this.props.context.isSpectating}
                    isTournament={this.props.context.isTournament}
                    initialBlindCents={this.props.context.initialBlindCents}
                    blindIncreaseMinutes={
                      this.props.context.blindIncreaseMinutes
                    }
                  />
                  <MTTLobby
                    data={this.props.context.MTTPlayers}
                    getPlayers={this.props.context.getMTTPlayers(
                      this.props.context.MTTId,
                      this.props.context.username,
                      this.props.context.sessionKey
                    )}
                    MTTUsers={this.props.context.MTTUsers}
                  />
                  <Chat MTTId={this.state.MTTId} isMTTLobby={true} />
                </>
              )}
            </CentralLayout>
            {/* Intentionally prop drilling for memo performance optimization. Will do without prop drilling later. */}
            <VideoChat
              displayName={this.props.context.username}
              roomName={`lipoker-${this.state.gameId}`}
              showVideoChat={this.props.browserContext.showVideoChat}
              setShowVideoChat={this.props.browserContext.setShowVideoChat}
              isMobile={this.props.browserContext.isMobile}
              isOnRight={this.props.browserContext.videoIsOnRight}
              joinCall={this.props.browserContext.joinCall}
              setJoinCall={this.props.browserContext.setJoinCall}
              iframeClientName={this.props.iframeClientName}
            />
          </Wrapper>
        </>
      </ThemeProvider>
    );
  }
}

const Wrapper = styled.div`
  height: 100vh;
  background: ${({ theme }) => theme.background};

  display: flex;
  ${(props) =>
    props.reverseOrder &&
    `
    flex-direction: row-reverse;
  `}
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    right: 5px;
    bottom: 71px;
  }
`;

let CentralLayout = styled.div`
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  ${(props) => (props.fullWidth ? "width: 100%;" : "width: 85%;")}
`;

export const LeaderboardWidthPercent = 15;

setConfig({ reloadHooks: true });
export default hot(module)(App);
