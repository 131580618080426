import React, { useState, cloneElement } from "react";
import Notification from "../Notification";

const ModalNotifWrapper = ({
  modalConstant,
  children,
  setModal,
  setExistsModal,
  notification,
}) => {
  const [showNotif, setShowNotif] = useState(false);

  const setShowFeedbackModal = (show) => {
    if (show) {
      setModal(modalConstant);
    } else {
      setModal(null);
    }
    setExistsModal(show);
  };

  const hideModal = (showThankYou) => {
    setShowFeedbackModal(false);
    if (showThankYou) {
      setShowNotif(true);
    }
  };

  return (
    <>
      <Notification show={showNotif} onNotifEnd={() => setShowNotif(false)}>
        {notification}
      </Notification>
      {cloneElement(children, { hideModal: hideModal })}
    </>
  );
};

export default ModalNotifWrapper;
