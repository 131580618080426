import React, { useState } from "react";
import styled, { withTheme } from "styled-components";
import * as Base from "./Base";
import KeyboardChar from "./KeyboardChar";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../../helpers/constants";

const PrimaryButton = ({
  style,
  isClicked,
  disableShadow,
  disable,
  fullWidth,
  keyboardChar,
  onClick,
  secondaryColor,
  children,
  theme,
  color,
  dataTestId,
  id,
  isPressed,
}) => {
  const [pressed, setPressed] = useState(false);
  return (
    <ButtonWrapper style={style}>
      <PrimaryButtonFixed
        id={id}
        pressed={isPressed === undefined ? pressed : isPressed}
        isClicked={isClicked}
        disableShadow={disableShadow}
        disable={disable}
        fullWidth={fullWidth}
        style={style}
        theme={theme}
        color={color}
        secondaryColor={secondaryColor}
        onClick={onClick}
        data-testid={dataTestId}
      >
        {children}
      </PrimaryButtonFixed>
      {!disableShadow && <PrimaryButtonShadow color={theme.flatShadow} />}
      {keyboardChar && (
        <KeyboardCharWrapper>
          <KeyboardChar
            char={keyboardChar}
            onClick={onClick}
            onKeydown={() => setPressed(true)}
            onKeyUp={() => setPressed(false)}
            color={color || (secondaryColor && theme.secondary)}
          />
        </KeyboardCharWrapper>
      )}
    </ButtonWrapper>
  );
};
const ButtonWrapper = styled.div`
  position: relative;
  height: 65px;
  width: 125px;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    width: 80px;
    height: 40px;
    margin-right: 10px;
  }
`;
const PrimaryButtonFixed = styled(Base.PrimaryButton)`
  position: absolute;
  ${(props) =>
    props.theme &&
    props.secondaryColor &&
    `background: ${props.theme.secondary}`};
  ${(props) =>
    props.color &&
    `
    background: ${props.color}
  `}
  z-index: 1;
  left: 0;
`;
const PrimaryButtonShadow = styled(Base.PrimaryButton)`
  position: absolute;
  z-index: 0;
  background: ${(props) => props.color && `${props.color}`}
  left: 0;
  transform: translate(5px, 5px);
`;
const KeyboardCharWrapper = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  transform: translate(10px, -50%);
`;
export default withTheme(PrimaryButton);
