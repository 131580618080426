import React, { useRef, useState, useEffect } from "react";
import {
  Slider,
  PrimaryButton,
  InputText,
  KeyboardChar,
} from "../../../../shared/FormElements";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../../../helpers/constants";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { colors } from "../../../../helpers/styles";
import { SecondaryButton, Icon } from "../../../../shared/FormElements";
import { modals } from "../../../../helpers/constants";
import { formatCurrency } from "../../../../helpers/utils";
import styled from "styled-components";

const RaiseView = ({
  isMobile,
  betOnTableCents,
  betsOnTableSumCents,
  smallBlindCents,
  minBetCents,
  minBetSliderCents,
  maxBetSliderCents,
  onBetClick,
  setShowRaiseView,
  callAmountCents,
  ownBetOnTableCents,
  betValueCents,
  setBetValueCents,
  bigBlindCents,
  mainPotCents,
  isPreFlop,
  setCurrentModal,
  isInCents,
}) => {
  const betInputElement = useRef(null);
  const [betFocus, setBetFocus] = useState(false);
  const [potFocus, setPotFocus] = useState(false);

  useEffect(() => {
    if (!betFocus) {
      // round betValueCents
      if (betValueCents) {
        const betValueCentsFloat = parseInt(betValueCents);
        setBetValueCents(betValueCentsFloat);
      }
    }
  }, [betFocus]);

  const enterPressedForBet = (event) => {
    if (!isInCents && !/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
    var code = event.keyCode || event.which;
    const bet = parseInt(betValueCents || minBetCents);
    if (
      code === 13 &&
      bet >= minBetSliderCents &&
      bet <= maxBetSliderCents &&
      bet !== 0
    ) {
      setBetValueCents(bet);
      onBetClick(bet);
    }
  };

  const getPercentPot = (betDollars) => {
    const betValueCentsFloat = betDollars
      ? parseFloat(betDollars)
      : parseFloat(betValueCents);
    const mainPotCentsFloat = parseFloat(mainPotCents);
    const betsOnTableSumCentsFloat = parseFloat(betsOnTableSumCents);
    if (mainPotCents + betsOnTableSumCents + callAmountCents === 0) {
      return "";
    }
    const percentPot = Math.round(
      ((betValueCentsFloat - callAmountCents - ownBetOnTableCents) /
        (mainPotCentsFloat + betsOnTableSumCentsFloat + callAmountCents)) *
        100
    );
    return !isNaN(percentPot) ? percentPot : "";
  };

  const getBetValue = () => {
    const betValueDollarsFloat = parseFloat(betValueCents) / 100;
    if (isNaN(betValueDollarsFloat) || betValueDollarsFloat === null) {
      return "";
    }
    if (!isInCents) {
      return Math.round(betValueCents, 0);
    }
    return betFocus
      ? parseFloat(betValueCents) / 100
      : betValueDollarsFloat.toFixed(2);
  };

  const betValueFromPercentPot = (val) => {
    const newValue = parseFloat(val);
    const newBet =
      (newValue / 100) *
        parseFloat(mainPotCents + betsOnTableSumCents + callAmountCents) +
      callAmountCents +
      ownBetOnTableCents;
    return newBet;
  };

  const setBetFromPercentPot = (val) => {
    const newBet = betValueFromPercentPot(val);
    if (newBet > maxBetSliderCents) {
      setBetValueCents(maxBetSliderCents);
    } else {
      setBetValueCents(newBet);
    }
  };

  let raiseButtons;

  if (isPreFlop && minBetSliderCents / bigBlindCents < 5) {
    raiseButtons = [3, 4, 5].map((bigBlindCount) => {
      return (
        <SecondaryButton
          key={bigBlindCount}
          onClick={() => setBetValueCents(bigBlindCount * bigBlindCents)}
          disable={bigBlindCount * bigBlindCents < minBetSliderCents}
        >
          {`${bigBlindCount} BB`}
        </SecondaryButton>
      );
    });
  } else {
    raiseButtons = [
      { view: "+ 1/2 pot", percent: 50 },
      { view: "+ 3/4 pot", percent: 75 },
      { view: "+ pot", percent: 100 },
    ].map((potObj) => {
      return (
        <SecondaryButton
          key={potObj.view}
          onClick={() => setBetFromPercentPot(potObj.percent)}
          disable={betValueFromPercentPot(potObj.percent) < minBetSliderCents}
        >
          {potObj.view}
        </SecondaryButton>
      );
    });
  }

  return (
    <>
      <FullViewWrapper>
        <RaiseOptionsWrapper>
          <TopRaiseViewOptions data-testid="top-raise-view-options">
            <SecondaryButton
              disable={parseFloat(mainPotCents + betsOnTableSumCents) === 0}
              firstInList
            >
              <FlatBetTextField
                contrast
                type="number"
                step={5}
                data-testid="bet-pot-percent-field"
                onChange={(e) => setBetFromPercentPot(e.target.value)}
                value={getPercentPot()}
                placeholder={getPercentPot(minBetCents)}
                onFocus={() => setPotFocus(true)}
                onBlur={() => setPotFocus(false)}
              />
              <span style={{ marginLeft: "20px" }}>
                % pot
                {callAmountCents !== 0 && (
                  <InfoQuestionMark
                    icon={faQuestionCircle}
                    size="sm"
                    onClick={() => setCurrentModal(modals.RAISE_INFO)}
                  />
                )}
              </span>
            </SecondaryButton>
            {raiseButtons}
            <SecondaryButton
              lastInList
              onClick={() => setBetValueCents(maxBetSliderCents)}
            >
              ALL-IN
            </SecondaryButton>
          </TopRaiseViewOptions>
          <BottomRaiseViewOptions>
            <BetTextField
              contrast
              type="number"
              data-testid="bet-raise-field"
              step={isInCents ? smallBlindCents / 100 : smallBlindCents}
              onChange={(e) =>
                setBetValueCents(
                  isInCents
                    ? parseFloat(e.target.value).toFixed(2) * 100
                    : e.target.value
                )
              }
              value={getBetValue()}
              onKeyPress={enterPressedForBet}
              ref={betInputElement}
              placeholder={formatCurrency(minBetCents, isInCents)}
              onFocus={() => setBetFocus(true)}
              onBlur={() => setBetFocus(false)}
            />
            <KeyboardChar
              char="e"
              color={colors.grey}
              onClick={() => betInputElement.current.focus()}
              style={{
                position: "absolute",
                left: "10px",
                top: "0px",
                transform: "translate(0,-50%)",
              }}
            />
            <Slider
              onChange={(e) => setBetValueCents(parseInt(e.target.value))}
              value={betValueCents ? betValueCents : minBetSliderCents}
              smallBlindCents={smallBlindCents}
              min={minBetSliderCents}
              max={maxBetSliderCents}
              style={isMobile && { marginBottom: 0, marginRight: "15px" }}
              setValue={setBetValueCents}
              betFocus={betFocus}
              potFocus={potFocus}
            />
          </BottomRaiseViewOptions>
        </RaiseOptionsWrapper>
        <RightWrapper>
          <PrimaryButton
            keyboardChar="R"
            onClick={onBetClick}
            style={{ marginRight: "10px" }}
            dataTestId="bet-raise-button"
          >
            {betOnTableCents ? `Raise to` : `Bet`}
          </PrimaryButton>
          <PrimaryButton
            keyboardChar="B"
            secondaryColor
            onClick={() => setShowRaiseView(false)}
          >
            Back
          </PrimaryButton>
        </RightWrapper>
      </FullViewWrapper>
    </>
  );
};

const FullViewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RaiseOptionsWrapper = styled.div``;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 5px;
`;

const TopRaiseViewOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    height: 40px;
  }
`;

const BottomRaiseViewOptions = styled.div`
  position: relative;
  background: ${({ theme }) => theme.foreground};
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 10px;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    height: 40px;
  }
`;

const BetTextField = styled(InputText)`
  position: relative;
  font-weight: bold;
  text-align: center;
  width: 100px;
  height: 60px;
  box-sizing: border-box;
  border-radius: 5px;
  margin-right: 0;

  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    height: 25px;
  }
`;

const FlatBetTextField = styled(BetTextField)`
  height: 30px;
`;

const InfoQuestionMark = styled(Icon)`
  margin-left: 3px;
  color: ${colors.grey};
  size: sm;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

export default RaiseView;
