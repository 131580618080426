import React, { useHistory, useContext } from "react";
import Modal from "../../../shared/Modal";
import { SmallBodyText } from "../../../shared/Text";
import styled from "styled-components";
import { GameContext } from "../../../context/GameContext";
import { emit } from "../../../helpers/socket";

const MTTDistributeModal = (props) => {
  // const history = useHistory();
  const {
    username,
    gameId,
    stack,
    MTTId,
    sessionKey,
    updateDisplay,
    totalBuyinCents,
    autoBuyinCents,
  } = useContext(GameContext);

  const roundEndText = (
    <Label>
      Redirecting you to a table in 5 seconds...
      <br />
      {}
    </Label>
  );

  const joinGame = () => {
    emit("join", {
      username: username,
      game_id: gameId,
      key: sessionKey,
    });

    updateDisplay("play");
  };

  return (
    <Modal
      show
      title="Redirecting you to a table"
      //   onOverlayClick={props._cancelPopup}
      btnText="Enter Table"
      onBtnClick={() => joinGame()}
      dataTestId="mtt-distribute-button"
    >
      <div>{roundEndText}</div>
    </Modal>
  );
};

const Label = styled(SmallBodyText)`
  color: ${({ theme }) => theme.text.label};
`;

export default MTTDistributeModal;
