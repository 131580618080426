import React from "react";
import { SmallHeaderText, LargeBodyText } from "../../shared/Text";
import { colors, font } from "../../helpers/styles"; // ${font}
import { Icon } from "../../shared/FormElements";
import {
  faCheckSquare,
  faSquare,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../helpers/constants";
import { CHAT_STATE } from "./utils";
import styled from "styled-components";

const ChatTopBar = ({
  toggleShowGameLog,
  showGameLog,
  chatState,
  setChatState,
  numUnreadMessages,
  isMobile,
  isMTTLobby,
}) => {
  return (
    <ChatTopBarWrapper
      className="chat-top-bar"
      onClick={(e) => {
        if (e.target.classList && e.target.classList.contains("chat-top-bar")) {
          setChatState(
            chatState === CHAT_STATE.NORMAL
              ? CHAT_STATE.EXPANDED
              : CHAT_STATE.NORMAL
          );
        }
      }}
    >
      <ChatHeaderText align="left">
        Chat{" "}
        {chatState === CHAT_STATE.COLLAPSED && numUnreadMessages !== 0 && (
          <UnreadNumber big={numUnreadMessages > 99}>
            <span>{numUnreadMessages <= 99 ? numUnreadMessages : "99+"}</span>
          </UnreadNumber>
        )}
      </ChatHeaderText>
      {!isMTTLobby && (
        <GameMessageToggle onClick={toggleShowGameLog} disable={!showGameLog}>
          <Checkbox>
            <Icon
              icon={showGameLog ? faCheckSquare : faSquare}
              color={showGameLog ? ({ theme }) => theme.primary : colors.white}
              isLeft
            />
          </Checkbox>
          <SmallHeaderText style={{ marginTop: "5px" }}>
            Game Log
          </SmallHeaderText>
        </GameMessageToggle>
      )}
      <IconWrapper
        onClick={() =>
          setChatState(
            chatState === CHAT_STATE.COLLAPSED
              ? CHAT_STATE.NORMAL
              : CHAT_STATE.COLLAPSED
          )
        }
      >
        <Icon
          icon={faTimes}
          color={
            chatState === CHAT_STATE.COLLAPSED
              ? `rgba(255,255,255,0.3)`
              : `white`
          }
        />
      </IconWrapper>
    </ChatTopBarWrapper>
  );
};

const ChatTopBarWrapper = styled.div`
  background: ${({ theme }) => colors.darkGrey};
  width: 100%;
  height: 40px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.secondaryLighter};
  }
`;

const ChatHeaderText = styled(LargeBodyText)`
  margin-left: 10px;
  font-weight: bold;
  color: white;
  width: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
`;

const UnreadNumber = styled.div`
  background: ${({ theme }) => theme.tertiary};
  border-radius: 50%;
  height: 20px;
  width: 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  span {
    margin-left: -1px;
  }
  ${(props) =>
    props.big &&
    `
    height: 30px;
    width: 30px;
  `}
`;

const GameMessageToggle = styled.div`
  background: white;
  border-radius: 5px;
  font-family: ${font.header};
  font-size: 16px;
  padding: 8px;
  background: none;
  letter-spacing: 1px;
  color: ${colors.white};
  cursor: pointer;
  display: flex;
  margin-right: 10px;
  width: 105px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 105px;
  align-items: center;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    padding: 4px;
  }
`;

const Checkbox = styled.div`
  background: white;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ChatTopBar;
