import App from "./components/App";
import classnames from "classnames";
import ReactDOM from "react-dom";
import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./index.css";
import "typeface-rambla";
import { GameProvider, GameConsumer } from "./context/GameContext";
import { BrowserProvider, BrowserConsumer } from "./context/BrowserContext";
import { initSentry } from "./helpers/logger";
initSentry();

ReactDOM.render(
  <GameProvider>
    <BrowserProvider>
      <Router>
        <GameConsumer>
          {(context) => (
            <BrowserConsumer>
              {(browserContext) => (
                <div className={classnames("router-wrapper")}>
                  <Route
                    exact
                    path="/"
                    render={(props) => (
                      <App
                        { ...props}
                        context={context}
                        browserContext={browserContext}
                        iframeClientName={"game"}
                      />
                    )}
                  />

                  <Route
                    path="/game/:gameId?"
                    render={(props) => (
                      <App
                        {...props}
                        context={context}
                        browserContext={browserContext}
                        iframeClientName={"game"}
                      />
                    )}
                  />
                  <Route
                    path="/mtt/:MTTId?"
                    render={(props) => (
                      <App
                        {...props}
                        context={context}
                        browserContext={browserContext}
                        iframeClientName={"game"}
                      />
                    )}
                  />
                  <Route
                    path="mtt/:MTTId?/game/:gameId?"
                    render={(props) => (
                      <App
                        {...props}
                        context={context}
                        browserContext={browserContext}
                        iframeClientName={"game"}
                      />
                    )}
                  />
                  <Route
                    path="/gather_town/:gameId?"
                    render={(props) => (
                      <App
                        {...props}
                        context={context}
                        browserContext={browserContext}
                        iframeClientName={"gather_town"}
                      />
                    )}
                  />
                  <Route
                    path="/sample_id/:gameId?"
                    render={(props) => (
                      <App
                        {...props}
                        context={context}
                        browserContext={browserContext}
                        iframeClientName={"sample_id"}
                      />
                    )}
                  />
                  <Route
                    path="/revirt_space/:gameId?"
                    render={(props) => (
                      <App
                        {...props}
                        context={context}
                        browserContext={browserContext}
                        iframeClientName={"revirt_space"}
                      />
                    )}
                  />
                  <Route
                    path="/springworks/:gameId?"
                    render={(props) => (
                      <App
                        {...props}
                        context={context}
                        browserContext={browserContext}
                        iframeClientName={"springworks"}
                      />
                    )}
                  />
                  <Route
                    path="/nooks/:gameId?"
                    render={(props) => (
                      <App
                        {...props}
                        context={context}
                        browserContext={browserContext}
                        iframeClientName={"nooks"}
                      />
                    )}
                  />
                  <Route
                    path="/dive/:gameId?"
                    render={(props) => (
                      <App
                        {...props}
                        context={context}
                        browserContext={browserContext}
                        iframeClientName={"dive"}
                      />
                    )}
                  />
                  <Route
                    path="/backyard/:gameId?"
                    render={(props) => (
                      <App
                        {...props}
                        context={context}
                        browserContext={browserContext}
                        iframeClientName={"backyard"}
                      />
                    )}
                  />
                  <Route
                    path="/mixr/:gameId?"
                    render={(props) => (
                      <App
                        {...props}
                        context={context}
                        browserContext={browserContext}
                        iframeClientName={"mixr"}
                      />
                    )}
                  />
                  <Route
                    path="/kumospace/:gameId?"
                    render={(props) => (
                      <App
                        {...props}
                        context={context}
                        browserContext={browserContext}
                        iframeClientName={"kumospace"}
                      />
                    )}
                  />
                  <Route
                    path="/mixup/:gameId?"
                    render={(props) => (
                      <App
                        {...props}
                        context={context}
                        browserContext={browserContext}
                        iframeClientName={"mixup"}
                      />
                    )}
                  />
                  <Route
                    path="/doozy/:gameId?"
                    render={(props) => (
                      <App
                        {...props}
                        context={context}
                        browserContext={browserContext}
                        iframeClientName={"doozy"}
                      />
                    )}
                  />
                  <Route
                    path="/portals/:gameId?"
                    render={(props) => (
                      <App
                        {...props}
                        context={context}
                        browserContext={browserContext}
                        iframeClientName={"portals"}
                      />
                    )}
                  />
                  <Route
                    path="/a8a8a8a8a8a8a8a8/:gameId?"
                    render={(props) => (
                      <App
                        {...props}
                        context={context}
                        browserContext={browserContext}
                        iframeClientName={"a8a8a8a8a8a8a8a8"}
                      />
                    )}
                  />
                </div>
              )}
            </BrowserConsumer>
          )}
        </GameConsumer>
      </Router>
    </BrowserProvider>
  </GameProvider>,
  document.getElementById("root")
);
