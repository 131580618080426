import { createGlobalStyle, css } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto|Bebas+Neue');
`;

const font = {
  header: "Bebas Neue, sans-serif",
  body: "Rambla, sans-serif",
};

const colors = {
  red: "#fd5855",
  lightRed: `#E04000`,
  darkRed: "#a01000",
  white: "#FFFFFF",
  black: "#000000",
  lightGrey: "#f0f0f0",
  lightGrey2: "#dedede",
  grey: "#c4c4c4",
  mediumGrey: "#888888",
  darkGrey: "#333",
  darkGrey2: "#9a9a9a",
  green: "#17c58f",
  blue: "#509ebc",
  darkBlue: "#40465a",
  darkBlueLighter: "#4e566d",
  lightBlue: "#67cff7",
};

const styles = {
  boxShadow: "0 4px 6px 0px #bdbdbdbf",
  boxShadowHighlight: `0 0 4px 4px ${colors.red}`,
  boxShadowHighlight2: `0 0 4px 4px ${colors.grey}`,
  largeBoxShadow: "0 0 20px 0px rgba(0, 0, 0, 0.08)",
  largeBoxShadowDark: "0 0 20px 0px rgba(0, 0, 0, 0.2)",
};

const gradientScrollStyles = (gradientColor, backgroundColor) => {
  return css`
    background:
      /* Shadow covers */ linear-gradient(
        180deg ${backgroundColor} rgba(0, 0, 0, 0)
      ),
      linear-gradient(0deg ${backgroundColor} rgba(0, 0, 0, 0)) 0 100%,
      /* Shadows */ linear-gradient(180deg ${gradientColor} rgba(0, 0, 0, 0)),
      linear-gradient(0deg ${gradientColor} rgba(0, 0, 0, 0)) 0 100%;
    background:
      /* Shadow covers */ linear-gradient(
        180deg ${backgroundColor} rgba(0, 0, 0, 0)
      ),
      linear-gradient(0deg ${backgroundColor} rgba(0, 0, 0, 0)) 0 100%,
      /* Shadows */ linear-gradient(180deg ${gradientColor} rgba(0, 0, 0, 0)),
      linear-gradient(0deg ${gradientColor} rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 60px, 100% 60px, 100% 30px, 100% 30px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
  `;
};

export { GlobalStyle, font, styles, colors, gradientScrollStyles };
