import React from "react";
import { SmallBodyText } from "../../../shared/Text";
import Switch from "../../../shared/FormElements/Switch";
import {
  StyledDiv,
  StyledRadio,
  StyledRadioGroup,
  StyledRadioLabel,
} from "../../../shared/FormElements/RadioButton";
import { InputText } from "../../../shared/FormElements";
import { Wrapper, SettingsOption, SettingsText } from "./shared";
import CollapsibleNotice from "../../../shared/CollapsibleNotice";
import styled from "styled-components";
import { blinds, timer } from "../../../helpers/constants";

const GameSettingsView = ({
  smallBlindSettings,
  setSmallBlindSettings,
  isTimerEnabledSettings,
  setIsTimerEnabledSettings,
  timerLengthSecondsSettings,
  setTimerLengthSecondsSettings,
  isTournament,
  isAdmin,
  handTimerLengthSecondsSettings,
  setHandTimerLengthSecondsSettings,
  useCentsSettings,
  setUseCentsSettings,
  adminPlayers,
}) => {
  const stringifyPlayerNames = () => {
    const playerNames = adminPlayers.map((player) => player.playerName);
    return playerNames.join(", ");
  };

  return (
    <Wrapper>
      <CollapsibleNotice
        text={
          isAdmin
            ? "As an admin, you can change game settings"
            : `Only admin${
                adminPlayers.length > 1 ? "s" : ""
              } ${stringifyPlayerNames()} can change settings`
        }
        uniqueId="admin-settings-notice"
      />
      <SettingsOption disabled={!isAdmin || isTournament}>
        <SettingsLabel>
          <SettingsText align="left">Small Blind</SettingsText>
          <SmallBodyText>
            Change will go into effect on the next hand.
          </SmallBodyText>
        </SettingsLabel>
        <BigBlindInputText
          value={smallBlindSettings}
          onChange={(e) => setSmallBlindSettings(e.target.value)}
          placeholder={blinds.DEFAULT_SMALL_BLIND_CENTS}
          modal
        />
        {useCentsSettings && <SettingsText>cents</SettingsText>}
        {!useCentsSettings && <SettingsText>chips</SettingsText>}
      </SettingsOption>
      <SettingsOption disabled={!isAdmin}>
        <SettingsLabel>
          <SettingsText align="left">Use Money Denomination</SettingsText>
        </SettingsLabel>
        <Switch
          isOn={useCentsSettings}
          handleToggle={() => setUseCentsSettings(!useCentsSettings)}
        />
      </SettingsOption>
      <SettingsOption disabled={!isAdmin || isTournament}>
        <SettingsLabel>
          <SettingsText align="left">Timer</SettingsText>
          <SmallBodyText>
            Change will go into effect on the next hand.
          </SmallBodyText>
        </SettingsLabel>
        <Switch
          isOn={isTimerEnabledSettings}
          handleToggle={() =>
            setIsTimerEnabledSettings(!isTimerEnabledSettings)
          }
        />
      </SettingsOption>
      <SettingsOption
        disabled={!isTimerEnabledSettings || !isAdmin || isTournament}
      >
        <SettingsLabel>
          <SettingsText align="left">Timer (seconds)</SettingsText>
          <SmallBodyText>
            Change will go into effect on the next hand.
          </SmallBodyText>
        </SettingsLabel>
        <BigBlindInputText
          value={timerLengthSecondsSettings}
          onChange={(e) => setTimerLengthSecondsSettings(e.target.value)}
          placeholder={timer.DEFAULT_TIMER_SECONDS}
          modal
        />
      </SettingsOption>
      <SettingsOption disabled={!isAdmin}>
        <SettingsLabel>
          <SettingsText align="left">Delay Between Hands</SettingsText>
        </SettingsLabel>
        <StyledDiv>
          <StyledRadioGroup
            name="handTimer"
            onChange={setHandTimerLengthSecondsSettings}
            selectedValue={handTimerLengthSecondsSettings}
          >
            <StyledRadioLabel>
              <StyledRadio value={timer.SLOW_HAND_TIMER_SECONDS} />
              Slow (14)
            </StyledRadioLabel>
            <StyledRadioLabel>
              <StyledRadio value={timer.MEDIUM_HAND_TIMER_SECONDS} />
              Medium (8)
            </StyledRadioLabel>
            <StyledRadioLabel>
              <StyledRadio value={timer.FAST_HAND_TIMER_SECONDS} />
              Fast (2)
            </StyledRadioLabel>
          </StyledRadioGroup>
        </StyledDiv>
      </SettingsOption>
    </Wrapper>
  );
};

const SettingsLabel = styled.div`
  margin-right: 20px;
  width: 100%;
  color: ${({ theme }) => theme.text.label};
`;

const BigBlindInputText = styled(InputText)`
  height: 45px;
  text-align: right;
  font-size: 22px;
  width: 30%;
  margin-right: 5px;
`;

export default GameSettingsView;
