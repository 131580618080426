import axios from "axios";
import FormData from "form-data";
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + "/api",
});

const checkAlive = (resolve, reject) => {
  api.get("is_alive", {}).then(resolve, reject);
};

const sendFeedback = (
  gameId,
  username,
  email,
  rating,
  feedbackText,
  betaInterest,
  provider
) => {
  let form = new FormData();
  form.append("game_id", gameId);
  form.append("email", email);
  form.append("username", username);
  form.append("rating", rating);
  form.append("beta_interest", betaInterest);
  form.append("feedback_text", feedbackText);
  form.append("provider", provider);
  return api.post("/send_feedback", form, {
    headers: { "Content-Type": "application/json" },
  });
};

export { checkAlive, sendFeedback };
export default api;
