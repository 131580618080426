import io from "socket.io-client";
import { logActionEvent } from "./logger";
import { timer } from "./constants";

const socketUrl = process.env.REACT_APP_SOCKET_URL;

let socket = null;

const initSocket = (gameId = null, MTTId = null, cb = null) => {
  let queryParams = '';
  if (gameId) queryParams += `gameId=${gameId}&`;
  if (MTTId) queryParams += `MTTId=${MTTId}&`;

  // Remove the last '&' if it exists
  if (queryParams.endsWith('&')) {
    queryParams = queryParams.slice(0, -1);
  }

  console.log(`${socketUrl}?${queryParams}`);
  socket = io.connect(
    `${socketUrl}?${queryParams}`,
    {
      path: "/socketapi",
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionDelayMax: 500,
      randomizationFactor: 0,
      transports: ["websocket"],
    }
  );
  cb(socket);
};

const emit = (messageType, message) => {
  console.log("Emitting: ", messageType, message);
  socket.emit(messageType, message);
};

const socketOff = (messageType) => {
  if (messageType) {
    socket.off(messageType);
  } else {
    socket.off();
  }
};

const socketIsConnected = () => {
  return socket.connected;
};

const emitEnsureInRoom = (username, game_id, key, is_spectator, cb) => {
  socket.emit(
    "ensure_in_room",
    {
      username: username,
      game_id: game_id,
      key: key,
      is_spectator: is_spectator,
    },
    (result) => {
      if (cb) cb(result);
    }
  );
};

//.off.on hack disabled, would make sure only one socket listener exists at any time due to disconnects/reconnects for instance
const setOnConnect = (cb) => {
  socket.on("connect", (result) => {
    if (cb) cb(result);
  });
};

const setOnDisconnect = (cb) => {
  socket.on("disconnect", (result) => {
    if (cb) cb(result);
  });
};

const setOnStateChange = (cb) => {
  socket.on("state", (state) => {
    cb(state);
  });
};

const setOnMTTStateChange = (cb) => {
  socket.on("mtt_state", (state) => {
    cb(state);
  });
};

const setOnLeaderboard = (cb) => {
  socket.on("leaderboard", (users) => {
    cb(users);
  });
};

const setOnSound = (cb) => {
  socket.on("sound", (data) => {
    cb(data);
  });
};

const setOnDistributeChat = (cb) => {
  socket.on("distribute_chat", (obj) => {
    cb(obj);
  });
};

const setOnDifferentLogIn = (cb) => {
  socket.on("different_log_in", (obj) => {
    cb(obj);
  });
};

const setOnSettingsChange = (cb) => {
  socket.on("settings", (data) => {
    cb(data);
  });
};

const setOnPlayerStateChange = (cb) => {
  socket.on("players", (data) => {
    cb(data);
  });
};

const setOnMTTUsersState = (cb) => {
  socket.on("mtt_users", (data) => {
    cb(data);
  });
};

const setOnSpectatorStateChange = (cb) => {
  socket.on("spectators", (data) => {
    cb(data);
  });
};

const setOnPlayerFieldsChange = (cb) => {
  socket.on("player_fields", (data) => {
    cb(data);
  });
};

const setBootInactive = (username, game_id, cb) => {
  socket.on("set_boot_inactive_timer", (data) => {
    cb(data.username);
    setTimeout(() => {
      if (socket) {
        socket.emit("boot_inactive", {
          username: username,
          game_id: game_id,
        }); // handle the async on the frontend LMAO
      }
    }, data["client_timeout_seconds"] * 1000 + 1000); // seconds + 1 of leeway due to server lag
  });
};

const setAutoCheckFoldEmit = (
  username,
  game_id,
  key,
  cur_turn_username,
  turn_nonce,
  cb
) => {
  let timeout = timer.TIMER_END_GRACE_PERIOD_PEERS_SECONDS * 1000;
  if (username === cur_turn_username) {
    timeout = timer.TIMER_END_GRACE_PERIOD_SELF_SECONDS * 1000;
  }
  let autoCheckFoldTimeoutObject = setTimeout(() => {
    if (socket) {
      socket.emit("check_fold_on_timer_expire", {
        username: username,
        game_id: game_id,
        key: key,
        cur_turn_username: cur_turn_username,
        turn_nonce: turn_nonce,
      }); // handle the async on the frontend LMAO
    }
  }, timeout); // seconds + 1 of leeway due to server lag
  cb(autoCheckFoldTimeoutObject);
};

const setActionSuccess = (cb) => {
  socket.on("action_success", (data) => {
    cb(data);
  });
};

const setMTTGameEnd = (cb) => {
  socket.on("mtt_game_end", (data) => {
    cb(data);
  });
};

const setMTTPlayers = (cb) => {
  socket.on("mtt_players", (data) => {
    cb(data);
  });
};

// remove all listeners then close the socket
// will not run setOnDisconnect
const forceDisconnect = () => {
  if (socket) {
    socket.off();
    socket.close();
    return true;
  }
  return false;
};

const emitSocketAction = (actionType, betValue = -1, key, gameId, username) => {
  if (!key) {
    console.error("Cannot do action, no key!");
    return;
  }

  setActionSuccess((_) => {
    socketOff("action_success");
  });

  emit("action", {
    game_id: gameId,
    username: username,
    action: actionType,
    key: key,
    amount: betValue,
  });

  logActionEvent(actionType, betValue);
};

// connect to game variables. Needed temporarily because of the way other code was written.
const connectEmitSocketAction = (key, gameId, username) => (
  actionType,
  betValue = -1
) => emitSocketAction(actionType, betValue, key, gameId, username);

const emitSocketMessage = (messageType, extraData, key, gameId, username) => {
  let message = {
    username: username,
    game_id: gameId,
    key: key,
  };

  Object.assign(message, extraData);
  emit(messageType, message);
};

// connect to game variables. Needed temporarily because of the way other code was written.
const connectEmitSocketMessage = (key, gameId, username) => (
  messageType,
  extraData
) => emitSocketMessage(messageType, extraData, key, gameId, username);

export {
  initSocket,
  emit,
  socketOff,
  forceDisconnect,
  setOnDisconnect,
  setOnConnect,
  emitEnsureInRoom,
  setOnStateChange,
  setOnMTTStateChange,
  setOnLeaderboard,
  setOnSound,
  setOnDistributeChat,
  setBootInactive,
  setOnDifferentLogIn,
  socketIsConnected,
  setActionSuccess,
  setOnSettingsChange,
  setOnPlayerStateChange,
  setOnSpectatorStateChange,
  setOnPlayerFieldsChange,
  emitSocketAction,
  emitSocketMessage,
  connectEmitSocketAction,
  connectEmitSocketMessage,
  setAutoCheckFoldEmit,
  setMTTGameEnd,
  setMTTPlayers,
  setOnMTTUsersState,
};
export default socket;
