import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MediumHeaderText } from "./Text";

const TabView = ({ tabNamesToViews, defaultView, onTabChange }) => {
  const [currentView, setCurrentView] = useState(defaultView);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setCurrentView(defaultView);
  }, [defaultView]);

  const tabsList = Object.keys(tabNamesToViews);
  return (
    <>
      <TabBar>
        {tabsList.map((tabName, i) => {
          return (
            <TabItem
              key={tabName}
              onClick={() => {
                setCurrentView(tabName);
                onTabChange(tabName);
                setActiveIndex(i);
              }}
              isActive={currentView === tabName}
            >
              <MediumHeaderText>{tabName}</MediumHeaderText>
            </TabItem>
          );
        })}
        <ActiveBar
          style={{
            width: `${100.0 / tabsList.length}%`,
            left: `${(activeIndex * 100.0) / tabsList.length}%`,
          }}
        >
          <InnerBar />
        </ActiveBar>
      </TabBar>
      {tabNamesToViews[currentView]}
    </>
  );
};

const TabBar = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.modal.divider};
`;

const TabItem = styled.div`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.tertiary};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
  ${(props) =>
    !props.isActive &&
    `
      opacity: 0.3;
    `}
`;

const ActiveBar = styled.div`
  position: absolute;
  height: 5px;
  bottom: 0;
  transform: translate(0, 100%);
  transition: 0.3s ease left;
  padding: 0 6%;
`;

const InnerBar = styled.div`
  background: ${({ theme }) => theme.tertiary};
  width: 100%;
  height: 100%;
`;

export default TabView;
