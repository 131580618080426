import React from "react";
import DropdownOptionsPicker from "../../../shared/DropdownOptionsPicker";
import { Icon } from "../../../shared/FormElements";
import { faArrowLeft, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faPatreon } from "@fortawesome/free-brands-svg-icons";
import { dropdownPositioning, mobileDropdownPositioning } from "./shared";

const socialMedia = [
  {
    name: "twitter",
    link: "https://twitter.com/lipoker_io",
    icon: faTwitter,
    color: "#1E9DEA",
  },
  {
    name: "patreon",
    link: "https://www.patreon.com/lipoker",
    icon: faPatreon,
    color: "#EF6551",
  },
  {
    name: "blog",
    link: "https://www.notion.so/Lipoker-Blog-5ba5c59a84234bd1a4343f5880780ea0",
    icon: faPencilAlt,
    color: "#FFFFFF",
  },
];

const SocialMedia = ({ show, isMobile, close }) => {
  const generateSocialMediaList = () => {
    return socialMedia.map((obj) => {
      return {
        name: obj.name,
        inner: (
          <a href={obj.link} target="_blank" rel="noopener noreferrer">
            <Icon icon={obj.icon} color={obj.color} />
          </a>
        ),
        showLabel: false,
      };
    });
  };

  const socialMediaList = generateSocialMediaList();

  return (
    <DropdownOptionsPicker
      show={show}
      dataTestId="social-media-dropdown"
      optionsList={socialMediaList}
      style={isMobile ? mobileDropdownPositioning : dropdownPositioning}
      includeOverlay={false}
      initialExpanded={true}
      onCloseOverride={close}
      toggleInner={<Icon icon={faArrowLeft} color="white" />}
    />
  );
};

export default SocialMedia;
