import React, { Fragment } from "react";
import Notification from "../shared/Notification";
import { MediumBodyText } from "../shared/Text";
import { mobileBreakpoint, mobileHeightBreakpoint } from "../helpers/constants";
import styled from "styled-components";

const Widget = ({
  onClick,
  left,
  style,
  text,
  showNotif,
  setShowNotif,
  notification,
  dataTestId,
}) => {
  return (
    <Fragment>
      <WidgetWrapper
        onClick={onClick}
        left={left}
        style={style}
        data-testid={dataTestId}
      >
        <WidgetText>{text}</WidgetText>
      </WidgetWrapper>
      <Notification show={showNotif} onNotifEnd={() => setShowNotif(false)}>
        {notification}
      </Notification>
    </Fragment>
  );
};

const WidgetText = styled(MediumBodyText)`
  color: ${({ theme }) => theme.text.widget};
  font-size: 14px;
  font-weight: bold;
  text-align: center;
`;

const WidgetWrapper = styled.div`
  display: flex;
  z-index: 3;
  margin-left: ${(props) => (props.left ? props.left : "0px")};
  cursor: pointer;
  transition: 0.3s ease padding;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background: ${({ theme }) => theme.foreground};
  padding: 12px 20px;
  height: 100%;
  &:hover {
    padding-top: 20px;
    opacity: 0.7;
  }
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    padding: 5px 10px;
  }
`;

export default Widget;
