import React, { Fragment, useContext, useState, useEffect } from "react";
import Player, { MainContent } from "./Player";
import { playerStyles } from "./player_styles";
import { GameContext } from "../../../context/GameContext";
import { BrowserContext } from "../../../context/BrowserContext";
import { colors } from "../../../helpers/styles";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
  roles,
} from "../../../helpers/constants";
import { setAutoCheckFoldEmit } from "../../../helpers/socket";
import { SmallHeaderText } from "../../../shared/Text";
import { test_players } from "./test_players";
import styled from "styled-components";
import { posFromClientPOV } from "../../../helpers/utils";

const IS_TESTING = false;
const PlayersAndPots = ({ emitSocketMessage, winningPlayers }) => {
  const {
    username,
    activePlayers,
    faceUpHoleCards,
    dealerPosition,
    isTimerEnabled,
    timerLengthSeconds,
    gameId,
    sessionKey,
    turnNonce,
    isSpectating,
    maxPlayerNumber,
    isGodModeEnabled,
    isInCents,
  } = useContext(GameContext);
  const { isMobile, isFourColorDeck } = useContext(BrowserContext);

  const [turnIndex, setTurnIndex] = useState(-1);
  const [turnName, setTurnName] = useState("");
  const [isAnimating, setIsAnimating] = useState(false);
  const [restartingAnimation, setRestartingAnimation] = useState(false);
  const [holeCardCount, setHoleCardCount] = useState(0);
  const [checkFoldTimeoutObject, setCheckFoldTimeoutObject] = useState(null);
  const [timeLeft, setTimeLeft] = useState(timerLengthSeconds * 1000);
  const [endsAt, setEndsAt] = useState(Date.now() + 1000 * timerLengthSeconds);

  // does it make sense to have these variables outside useeffect
  const playersListOrigin = IS_TESTING ? test_players : activePlayers;
  const playersList = playersListOrigin.filter((player) => player.isAtTable);
  const clientPlayer = !isSpectating
    ? activePlayers.filter((player) => player.playerName === username)[0]
    : { playerRole: roles.ROLE_SPECTATOR };
  let emptySeats = new Array(maxPlayerNumber).fill(true);
  let newTurnIndex = -1;
  playersList.map((player, i) => {
    if (player.playerIsTurn) {
      newTurnIndex = player.pos;
      if (turnName !== player.playerName) {
        setTurnName(player.playerName);
      }
    }
  });
  let newHoleCardCount = faceUpHoleCards.length;
  let winningPlayersExist = winningPlayers && winningPlayers.length > 0;

  let onAnimationEnd = () => {
    setAutoCheckFoldEmit(
      username,
      gameId,
      sessionKey,
      turnName,
      turnNonce,
      (checkFoldTimeoutObject) => {
        setCheckFoldTimeoutObject(checkFoldTimeoutObject);
      }
    );
  };

  useEffect(() => {
    let shouldResetTimer =
      (newTurnIndex >= 0 && newTurnIndex !== turnIndex) ||
      holeCardCount !== newHoleCardCount;
    if (shouldResetTimer) {
      setIsAnimating(false);
      if (checkFoldTimeoutObject) {
        clearTimeout(checkFoldTimeoutObject);
      }
      setCheckFoldTimeoutObject(null);
    }
    if (newTurnIndex >= 0 && shouldResetTimer) {
      setRestartingAnimation(true);
    }
    if (restartingAnimation && newTurnIndex >= 0) {
      setRestartingAnimation(false);
      setIsAnimating(true);
      setTimeLeft(timerLengthSeconds * 1000);
      setEndsAt(Date.now() + 1000 * timerLengthSeconds);
    }
    setTurnIndex(newTurnIndex);
    setHoleCardCount(newHoleCardCount);
  }, [
    newTurnIndex,
    newHoleCardCount,
    restartingAnimation,
    isTimerEnabled,
    checkFoldTimeoutObject,
    winningPlayersExist,
  ]);

  useEffect(() => {
    if (isTimerEnabled && isAnimating) {
      if (timeLeft > 0) {
        let remainingTime = endsAt - Date.now();
        const id = setTimeout(() => setTimeLeft(remainingTime), 500);
        return () => clearTimeout(id);
      } else {
        onAnimationEnd();
      }
    }
  }, [timeLeft, isAnimating]);

  let clientPos =
    !isSpectating && activePlayers.length > 0
      ? (
          activePlayers.find((player) => {
            return player.playerName === username;
          }) || {}
        ).pos
      : 0;

  let players = (
    <Fragment>
      {
        /** Players */
        playersList.map((player, i) => {
          if (player) {
            emptySeats[player.pos] = false;
            return (
              <Fragment key={`${player.playerName}-${i}`}>
                <Player
                  isClient={!isSpectating && username === player.playerName}
                  clientRole={clientPlayer.playerRole}
                  playerRole={player.playerRole}
                  isWinner={player.isWinner}
                  isDealer={dealerPosition === Number.parseInt(player.pos)}
                  bestHand={player.bestHand}
                  pos={player.pos}
                  clientPos={clientPos}
                  isTurn={player.playerIsTurn}
                  isInHand={player.playerIsInHand}
                  balance={player.playerBalance}
                  name={player.playerName}
                  pot={player.playerPot}
                  cards={player.cards}
                  connected={player.connected}
                  requestedBuyIn={player.requestedBuyIn}
                  pendingBuyIn={player.pendingBuyIn}
                  isMobile={isMobile}
                  queuedAction={player.queuedAction}
                  isFourColorDeck={isFourColorDeck}
                  emitSocketMessage={emitSocketMessage}
                  isSpectating={isSpectating}
                  maxPlayerNumber={maxPlayerNumber}
                  isGodModeEnabled={isGodModeEnabled}
                  isGod={player.isGod}
                  showCardsToGod={player.showCardsToGod}
                  isInCents={isInCents}
                />
              </Fragment>
            );
          }
        })
      }
      {
        /** Open seats */
        emptySeats.map((isEmpty, i) => {
          if (isEmpty) {
            return (
              <OpenSeat
                key={`open-seat-${i}`}
                style={
                  playerStyles.playerPos[
                    posFromClientPOV(i, clientPos, maxPlayerNumber)
                  ]
                }
              >
                <SmallHeaderText>OPEN</SmallHeaderText>
              </OpenSeat>
            );
          }
          return <Fragment key={`open-seat-${i}`} />;
        })
      }
    </Fragment>
  );

  return (
    <Fragment>
      {players}
      {turnIndex !== -1 &&
        (isTimerEnabled ? (
          <TurnShadowAnimated
            style={
              playerStyles.playerPos[
                posFromClientPOV(turnIndex, clientPos, maxPlayerNumber)
              ]
            }
            ratio={timeLeft / (1000 * timerLengthSeconds)}
          />
        ) : (
          <TurnShadow
            style={
              playerStyles.playerPos[
                posFromClientPOV(turnIndex, clientPos, maxPlayerNumber)
              ]
            }
          />
        ))}
    </Fragment>
  );
};

const TurnShadow = styled(MainContent)`
  transition: 0.6s ease all;
  position: absolute;
  z-index: -1;
  border: 2px dashed white;
  background: ${({ theme }) => theme.primary};
  border-radius: 30px;
  width: 180px;
  height: 90px;
  transform: translate(-90px, -50%);
  margin-top: 50px;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    width: 110px;
    height: 40px;
    margin-top: 27px;
    border-radius: 15px;
    transform: translate(-55px, -50%);
  }
`;

const TurnShadowAnimated = styled(TurnShadow)`
  z-index: 4;
  transition: left 0.6s ease, top 0.6s ease, width 0.5s linear;
  padding: 0;
  ${(props) =>
    props.ratio &&
    `
    width: ${props.ratio > 0 ? 180 * props.ratio : 0}px;
    border: ${props.ratio > 0 ? 1 : 0}px solid white;
    height: 13px;
    transform: translate(-90px, 240%);
  `}
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    ${(props) =>
      props.ratio &&
      `
        width: ${props.ratio > 0 ? 110 * props.ratio : 0}px;
        border: ${props.ratio > 0 ? 1 : 0}px solid white;
        height: 15px;
        transform: translate(-55px, 220%);
      `}
  }
`;

const OpenSeat = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.openSeat.background};
  color: ${colors.darkGrey2};
  border: 2px dashed ${({ theme }) => theme.openSeat.borderColor};
  width: 70px;
  height: 40px;
  border-radius: 20px;
  margin-top: 40px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    height: 30px;
    width: 50px;
    z-index: -1;
  }
`;

export default PlayersAndPots;
