import React, { Fragment, useContext } from "react";
import { GameContext } from "../../context/GameContext";
import { Icon } from "../../shared/FormElements";
import { faRandom } from "@fortawesome/free-solid-svg-icons";
import Widget from "../../shared/Widget";
import { colors } from "../../helpers/styles";
import { roles } from "../../helpers/constants";

const RandomSeatsButton = ({ emitSocketMessage, isSpectating }) => {
  const { activePlayers, username, ongoing } = useContext(GameContext);
  const adminPlayer = activePlayers.filter(
    (player) => player.playerRole === roles.ROLE_ADMIN
  )[0];

  const randomizeSeats = () => {
    emitSocketMessage("randomize_seats", {});
  };
  if (
    ongoing ||
    activePlayers.length < 2 ||
    (adminPlayer && adminPlayer.playerName !== username) ||
    isSpectating
  ) {
    return <></>;
  }

  return (
    <Fragment>
      <Widget
        onClick={() => randomizeSeats()}
        text={
          <>
            {"Shuffle Seats"} <Icon icon={faRandom} />
          </>
        }
        style={RandomSeatButtonPosition}
      />
    </Fragment>
  );
};

const RandomSeatButtonPosition = {
  position: "absolute",
  zIndex: 3,
  top: "120px",
  right: "20px",
  height: "40px",
  borderTopLeftRadius: "7px",
  borderTopRightRadius: "7px",
  backgroundColor: colors.grey,
  paddingTop: "10px",
};

export default RandomSeatsButton;
