// stolen from https://github.com/facebook/react/issues/15344
// used as a hack for useReducer dispatch callback
import { useRef, useEffect } from "react";

export default function useNext(value) {
  const valueRef = useRef(value);
  const resolvesRef = useRef([]);
  useEffect(() => {
    if (valueRef.current !== value) {
      for (const resolve of resolvesRef.current) {
        resolve(value);
      }
      resolvesRef.current = [];
      valueRef.current = value;
    }
  }, [value]);
  return () =>
    new Promise((resolve) => {
      resolvesRef.current.push(resolve);
    });
}
