import React from "react";
import { SmallBodyText, MediumBodyText } from "../../../shared/Text";
import styled from "styled-components";

export const Mersenne = () => {
  return (
    <AnswerWrapper>
      We use the Mersenne Twister, one of the most reliable and widely used
      random number generators (also certified and recommended by iTechLabs in
      2017 for online gaming). Moreover, it is the default PRNG for many
      software systems, including Python, Microsoft Excel, MATLAB, R, Ruby, and
      more. For a more in-depth explanation about the Mersenne Twister, check
      out this{" "}
      <a
        href={
          "https://www.sciencedirect.com/topics/computer-science/mersenne-twister"
        }
        target="_blank"
      >
        link
      </a>
      .
    </AnswerWrapper>
  );
};

export const ShowHand = () => {
  return (
    <AnswerWrapper>
      The winners, players who have went all-in, and the last aggressor (or
      first person left of dealer if everyone checks) along with players who
      have increasingly better hands going clockwise-- show their cards. For the
      rest of the players who have not folded, they have the option to show
      their hands or not.
    </AnswerWrapper>
  );
};

export const Philosophy = () => {
  return (
    <AnswerWrapper>
      Unlike other sites that present a complicated interface for you to play
      on, Lipoker is the only site built for making your online poker experience
      with friends as simple and enjoyable as possible. From video chat to
      customizable themes, our commitment is to create the best platform for you
      to play poker.
    </AnswerWrapper>
  );
};

export const ContactUs = () => {
  return (
    <AnswerWrapper>
      Should you run into problems or have any questions, please contact us at{" "}
      <u>lipoker.team@gmail.com</u>!
    </AnswerWrapper>
  );
};

export const Patreon = () => {
  return (
    <AnswerWrapper>
      We would love your support to further our commitment to providing you the
      best platform play online poker. This includes covering costs for our
      servers, development time for new features, and many other initiatives. If
      you are interested in making a donation, you can do so here:{" "}
      <a href={"https://www.patreon.com/lipoker"} target="_blank">
        Lipoker@Patreon
      </a>
    </AnswerWrapper>
  );
};

// rough draft
export const GamePlay = () => {
  return (
    <SmallBodyText>
      <p>
        We currently host No Limit Hold ‘em, the most popular variant of poker.
      </p>
      <p>
        Definitions: <br />
        Table Positions: <br />
        Dealer - <br />
        Big blind - <br />
        Small blind - <br />
        $$$:
        <br />
        - Pot: total sum wagered by players in a single hand
        <br />
        5 Different Play Actions :<br />
        Fold - player gives up their cards and takes no further part in the hand
        <br />
        Bet - person who first wages a positive amount of money
        <br />
        Check - players have the option to defer their bet (only if NO bet has
        occurred)
        <br />
        Call - player matches the most recent bet
        <br />
        Raise - player raises amount, forcing other players to match. Their
        raise amount must at least double the most recent bet.
        <br />
        If you don’t have enough money to raise or call, you must go All-In
        (player places all his money in the pot) to remain in the hand.
        <br />
      </p>
      $$$: - Pot: total sum wagered by players in a single hand 5 Different Play
      Actions : Fold - player gives up their cards and takes no further part in
      the hand Bet - person who first wages a positive amount of money Check -
      players have the option to defer their bet (only if NO bet has occurred)
      Call - player matches the most recent bet Raise - player raises amount,
      forcing other players to match. Their raise amount must at least double
      the most recent bet. If you don’t have enough money to raise or call, you
      must go All-In (player places all his money in the pot) to remain in the
      hand. Gameplay: Clockwise If at any point in time, all players in the hand
      decide to All-in, the gameplay jumps straight into showdown (described in
      post-flop section). Pre-flop: - This is the stage before any cards show up
      on the table. - After everyone has finished checking or calling, the game
      proceeds to the post-flop stage. Post-flop: - Community cards (3 cards): -
      Turn, river: 4th and 5th card dealt - If two or more players are still in
      the hand after the river, the gameplay proceeds to the showdown stage. -
      Showdown - All the remaining table cards are dealt - In general, the
      person with the best hand is declared the winner and collects the entire
      pot. - However, alternative endings may happen: - Tie - All-in’s -
      Side-pots
    </SmallBodyText>
  );
};

const AnswerWrapper = styled(MediumBodyText)`
  align-content: center;
  color: ${({ theme }) => theme.text.label};
  font-size: 17px;
`;
