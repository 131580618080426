import React, { useState, useEffect } from "react";
import { SmallBodyText } from "../../../shared/Text";
import Notification from "../../../shared/Notification";

const PatreonModal = (props) => {

  const [startTransition, setStartTransition] = useState(true);

  useEffect(() => {
    // if showing again, reset
    if (props.show) {
      setStartTransition(false);
    }
  }, [props.show]);


  return (
      <Notification
        show={props.show}
        notifDuration={10000}
        onNotifEnd={props.hideModal}
        clickable={true}
      >
        <SmallBodyText>
          Support us on{" "}
          <a
            href="https://www.patreon.com/lipoker"
            target="_blank"
          >
            Patreon
          </a>
          !
        </SmallBodyText>
      </Notification>
  );
};

export default PatreonModal;
