import React from "react";
import { colors } from "../../helpers/styles";
import styled from "styled-components";

const CardEmpty = (props) => {
  return <CardEmptyContent />;
};

const CardEmptyContent = styled.div`
  height: 100%;
  width: 100%;
  border: 2px dashed ${colors.white};
`;

export default CardEmpty;
