import React from "react";
import DropdownOptionsPicker from "../../../shared/DropdownOptionsPicker";
import themesDict from "../../../helpers/theme";
import { Icon } from "../../../shared/FormElements";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  ThemeCircle,
  dropdownPositioning,
  mobileDropdownPositioning,
} from "./shared";

const ThemePicker = ({ show, isMobile, theme, setTheme, close }) => {
  const generateThemesList = () => {
    return Object.keys(themesDict).map((themeName) => {
      return {
        name: themeName,
        inner: (
          <ThemeCircle
            color1={themesDict[themeName].swatch[0]}
            color2={themesDict[themeName].swatch[3]}
            color3={themesDict[themeName].swatch[2]}
          />
        ),
        onClick: () => setTheme(themeName),
        showLabel: false,
        selected: theme === themeName,
      };
    });
  };

  const themesList = generateThemesList();

  return (
    <DropdownOptionsPicker
      show={show}
      dataTestId="theme-picker-dropdown"
      optionsList={themesList}
      style={isMobile ? mobileDropdownPositioning : dropdownPositioning}
      includeOverlay={false}
      initialExpanded={true}
      onCloseOverride={close}
      toggleInner={<Icon icon={faArrowLeft} color="white" />}
    />
  );
};

export default ThemePicker;
