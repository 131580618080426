export const LEADERBOARD = "LEADERBOARD";
export const DISPLAY = "DISPLAY";
export const JOIN_GAME = "JOIN_GAME";
export const SPECTATE_GAME = "SPECTATE_GAME";
export const CREATE_GAME = "CREATE_GAME";
export const CREATE_MTT = "CREATE_MTT";
export const GAME_STATE_CHANGE = "GAME_STATE_CHANGE";
export const MTT_STATE_CHANGE = "MTT_STATE_CHANGE";
export const RECEIVE_CHAT = "RECEIVE_CHAT";
export const IS_RUNNING_DELAY = "IS_RUNNING_DELAY";
export const CHECK_GAME_EXISTS = "CHECK_GAME_EXISTS";
export const SET_USER_DISCONNECTED = "SET_USER_DISCONNECTED";
export const SET_GAME_SETTINGS = "SET_GAME_SETTINGS";
export const SET_PLAYERS = "SET_PLAYERS";
export const SET_SPECTATORS = "SET_SPECTATORS";
export const QUEUED_ACTION = "QUEUED_ACTION";
export const UPDATE_PLAYER_FIELDS = "UPDATE_PLAYER_FIELDS";
export const GET_MTT_PLAYERS = "GET_MTT_PLAYERS";
export const CHECK_MTT_EXISTS = "CHECK_MTT_EXISTS";
export const JOIN_MTT = "JOIN_MTT";
export const UPDATE_MTT_STATE = "UPDATE_MTT_STATE";
export const SET_MTT_USERS = "SET_MTT_USERS";
