import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { GameContext } from "../context/GameContext";
import { SmallHeaderText, MediumHeaderText, LargeHeaderText } from "./Text";
import { timer } from "../helpers/constants";
import { formatCurrency } from "../helpers/utils";

// hardcoded for now
const blindCoefficients = [1, 2, 3, 5, 10, 15, 25, 50, 75, 100, 150, 200];

const BlindStructureScroll = ({
  initialBlindCents,
  blindIncreaseMinutes,
  showTitle,
  showCurrentBlinds,
  isInCents,
}) => {
  const { smallBlindCents, gameStartTime } = useContext(GameContext);

  const [gameTimeSeconds, setGameTimeSeconds] = useState(0);

  useEffect(() => {
    if (gameStartTime) {
      setTimeout(() => {
        setGameTimeSeconds(
          Math.round(
            (Date.now() - gameStartTime - timer.INITIAL_TIME_MILLISECONDS) /
              1000
          )
        );
      }, 1000);
    }
  });

  const parsePixels = (translate) => {
    if (translate === "") return 0;
    return parseFloat(translate.substring(11, translate.length - 3));
  };

  const scrollLeft = () => {
    const container = document.getElementById("blinds-container");
    container.style.transform = `translateX(${Math.min(
      0,
      parsePixels(container.style.transform) + 400
    )}px)`;
  };

  const scrollRight = () => {
    const container = document.getElementById("blinds-container");
    container.style.transform = `translateX(${Math.max(
      -(container.scrollWidth - container.offsetWidth),
      parsePixels(container.style.transform) - 400
    )}px)`;
  };

  const formatSeconds = (seconds) => {
    if (seconds < 10) {
      return `0:0${seconds}`;
    } else if (seconds < 60) {
      return `0:${seconds}`;
    } else {
      return `${Math.floor(seconds / 60)}:${
        seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60
      }`;
    }
  };

  let items = blindCoefficients.map((blind, idx) => {
    if (
      showCurrentBlinds &&
      gameStartTime &&
      blind * initialBlindCents === smallBlindCents
    ) {
      return (
        <CurrentBlindWrapper key={blind}>
          <SmallHeaderText>{idx * blindIncreaseMinutes} min</SmallHeaderText>
          <SmallHeaderText>
            {formatCurrency(blind * initialBlindCents, isInCents)}/
            {formatCurrency(2 * blind * initialBlindCents, isInCents)}
          </SmallHeaderText>
        </CurrentBlindWrapper>
      );
    } else {
      return (
        <BlindWrapper key={blind}>
          <SmallHeaderText>{idx * blindIncreaseMinutes} min</SmallHeaderText>
          <SmallHeaderText>
            {formatCurrency(blind * initialBlindCents, isInCents)}/
            {formatCurrency(2 * blind * initialBlindCents, isInCents)}
          </SmallHeaderText>
        </BlindWrapper>
      );
    }
  });
  items.push(<BlindWrapper key="extra" style={{ width: "0" }}></BlindWrapper>);

  return (
    <StructureWrapper>
      {showTitle && <MediumHeaderText>Blind Structure</MediumHeaderText>}
      {showCurrentBlinds && (
        <MediumHeaderText>
          {gameStartTime
            ? `Current Game Time: ${formatSeconds(gameTimeSeconds)}`
            : "Game has not started"}
        </MediumHeaderText>
      )}
      <ScrollWrapper>
        <Arrow onClick={scrollLeft}>{"<"}</Arrow>
        <BlindsWrapper>
          <BlindsContainer id="blinds-container">{items}</BlindsContainer>
        </BlindsWrapper>
        <Arrow onClick={scrollRight}>{">"}</Arrow>
      </ScrollWrapper>
    </StructureWrapper>
  );
};

const StructureWrapper = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.text.label};
  .disabled {
    display: none;
  }
  .active {
    display: block;
  }
`;

const ScrollWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BlindsWrapper = styled.div`
  width: 80%;
  overflow: hidden;
`;

const BlindsContainer = styled.div`
  display: flex;
  transform: translateX(0px);
  transition: 0.5s all ease-in-out;
`;

const BlindWrapper = styled.div`
  text-align: center;
  padding: 5px 15px;
  border-left: 1px solid ${({ theme }) => theme.text.label};
`;

const CurrentBlindWrapper = styled.div`
  text-align: center;
  padding: 5px 15px;
  border-left: 1px solid ${({ theme }) => theme.text.label};
  color: ${({ theme }) => theme.tertiary};
`;

const Arrow = styled(LargeHeaderText)`
  text-align: center;
  width: 50px;
  height: 20px;
  cursor: pointer;
`;

export default BlindStructureScroll;
