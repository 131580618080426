import React from "react";
import { SmallBodyText } from "../../../shared/Text";
import { Icon } from "../../../shared/FormElements";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { colors } from "../../../helpers/styles";

export const feedbackNotif = (
  <SmallBodyText>
    <Icon icon={faCheckCircle} isLeft={1} color={colors.green} />
    Thank you for your feedback!
  </SmallBodyText>
);
