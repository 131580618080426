import React, { useState, useEffect } from "react";
import { font, colors } from "../../../helpers/styles";
import { PrimaryButton } from "../../../shared/FormElements";
import styled from "styled-components";

const ShowHandAction = ({
  emitSocketMessage,
  clientPlayer,
  numWinners,
  stillInHand,
  showingHand,
  isSpectating,
  handTimerLengthSeconds,
}) => {
  const [actionDone, setActionDone] = useState(false);
  const [hideContent, setHideContent] = useState(false);
  const [seconds, setSeconds] = useState(handTimerLengthSeconds);
  const [endsAt, dummyEndsAt] = useState(
    Date.now() + 1000 * handTimerLengthSeconds
  );

  const sendSocketShowHand = () => {
    emitSocketMessage("player_fields", {
      fields: { cards: clientPlayer.cards },
    });
    setActionDone(true);
  };

  const onShowHandClick = (e) => {
    sendSocketShowHand();
  };

  useEffect(() => {
    if (seconds > 0) {
      let remainingTime = Math.floor((endsAt - Date.now()) / 1000);
      const timer = setTimeout(() => setSeconds(remainingTime), 1000);
      return () => clearTimeout(timer);
    } else {
      setSeconds(null);
      setHideContent(true);
    }
  }, [seconds]);

  if (hideContent) {
    return <></>;
  }

  let showHandButton;
  let newHandText = (
    <ShowHandTextWrapper>
      <BoldText>{seconds}</BoldText> seconds until new hand
    </ShowHandTextWrapper>
  );
  if (isSpectating) {
    return <RightAlign>{newHandText}</RightAlign>;
  }

  let showHandText = <></>;

  if (clientPlayer.showingHand || actionDone) {
    showHandText = (
      <ShowHandTextWrapper2>Your cards are showing</ShowHandTextWrapper2>
    );
  }
  if (!actionDone && !showingHand && numWinners > 0 && clientPlayer.cards) {
    showHandButton = (
      <PrimaryButton
        dataTestId="show-hand-btn"
        onClick={onShowHandClick}
        color={colors.darkBlue}
        keyboardChar="S"
      >
        Show Cards
      </PrimaryButton>
    );
  } else {
    showHandButton = <></>;
  }

  return (
    <>
      {showHandButton}
      <RightAlign>
        {showHandText}
        {newHandText}
      </RightAlign>
    </>
  );
};

const RightAlign = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const BoldText = styled.div`
  display: flex;
  margin-left: 5px;
  margin-right: 3px;
  color: ${({ theme }) => theme.primary};
`;

const ShowHandTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${font.header};
  font-size: 22px;
  margin-right: 24px;
  margin-bottom: 10px;
  color: ${colors.darkGrey2};
  letter-spacing: 0.3px;
  font-weight: bold;
`;

const ShowHandTextWrapper2 = styled(ShowHandTextWrapper)`
  color: ${({ theme }) => theme.tertiary};
`;

export default ShowHandAction;
