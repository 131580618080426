import React, { useEffect, useState } from "react";
import CardBack from "./CardBack";
import CardEmpty from "./CardEmpty";
import { Heart, Spade, Diamond, Club } from "./suits";
import sizes from "./sizes";
import styled, { withTheme } from "styled-components";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../helpers/constants";
import { colors, font } from "../../helpers/styles";

const Card = ({
  isFourColorDeck,
  theme,
  size,
  enforceCornerSuit,
  isEmpty,
  isBack,
  number,
  suit,
  handCardNumber,
  border,
  isFlat,
  isHighlighted,
  isFolded,
  style,
}) => {
  const [color, setColor] = useState("");
  const allSuits = ["spade", "club", "diamond", "heart"];

  useEffect(() => {
    const dark = ["spade", "club"];
    if (isFourColorDeck) {
      setColor(theme.card[suit]);
    } else if (dark.includes(suit)) {
      setColor(theme.card.dark);
    } else {
      setColor(theme.card.light);
    }
  }, [isFourColorDeck, theme]);

  const getSuit = () => {
    if (color.length === 0) {
      return <></>;
    }
    const sizeObj = sizes[size || "md"];
    let style = {
      height: sizeObj.iconHeight,
    };
    if (size !== "mobile" || enforceCornerSuit) {
      Object.assign(style, {
        bottom: sizeObj.iconBottom,
        right: "5px",
        position: "absolute",
      });
    }

    if (suit === "spade") {
      return <Spade color={color} style={style} />;
    } else if (suit === "diamond") {
      return <Diamond color={color} style={style} />;
    } else if (suit === "club") {
      return <Club color={color} style={style} />;
    } else {
      return <Heart color={color} style={style} />;
    }
  };

  let cardInner;
  if (isEmpty) {
    cardInner = <CardEmpty />;
  } else if (isBack) {
    cardInner = <CardBack />;
  } else {
    cardInner = (
      <CardBase isFolded={isFolded}>
        <h1>{number}</h1>
        {allSuits.includes(suit) && getSuit()}
      </CardBase>
    );
  }

  return (
    <CardWrapper
      size={size || "md"}
      color={color}
      handCardNumber={handCardNumber}
      border={border}
      style={style}
      isFlat={isFlat}
      isHighlighted={isHighlighted}
      isFolded={isFolded}
    >
      {cardInner}
    </CardWrapper>
  );
};

const CardBase = styled.div`
  background: ${colors.white};
  ${(props) =>
    props.isFolded &&
    props.theme &&
    `
    background: ${props.theme.foreground};
  `}
  width: 100%;
  height: 100%;
  h1 {
    position: absolute;
    top: 3px;
    left: 8px;
  }
  img {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1 {
      position: inherit;
      line-height: 22px;
      margin: 0;
    }
    img {
      position: inherit;
    }
  }
`;

const CardWrapper = styled.div`
  position: relative;
  font-family: ${font.header};
  border-radius: 5px;
  overflow: hidden;
  ${(props) =>
    props.size &&
    `
    height: ${sizes[props.size]["height"]};
    width: ${sizes[props.size]["width"]};
    ${CardBase} img {
      height: ${sizes[props.size]["iconHeight"]};
    }
    ${CardBase} h1 {
      font-size: ${sizes[props.size]["fontSize"]};
    }
  `}
  ${(props) =>
    props.color &&
    `
    color: ${props.color}
  `}
  ${(props) =>
    props.handCardNumber === "0" &&
    `
    position: absolute;
    transform: rotate(-6deg);
    left: 17px;
    z-index: 2;
  `}
  ${(props) =>
    props.handCardNumber === "1" &&
    `
    position: absolute;
    transform: rotate(6deg);
    right: 17px;
    z-index: 1;
  `}
  ${(props) =>
    !props.isFlat && props.theme
      ? `
    border: 1px solid ${props.theme.foreground}
  `
      : "margin: 5px;"}
  ${(props) =>
    props.border &&
    props.theme &&
    `
    border: 1px solid ${props.theme.foreground}
  `}
  ${(props) =>
    props.isHighlighted &&
    props.theme &&
    `border: 3px solid ${props.theme.tertiary};`}
  ${(props) =>
    props.isFolded &&
    `
    opacity: 0.6;
  `}
  @media screen and (max-width: ${mobileBreakpoint}), screen and (max-height: ${mobileHeightBreakpoint}) {
    height: ${sizes["mobile"]["height"]};
    width: ${sizes["mobile"]["width"]};
    ${CardBase} img {
      height: ${sizes["mobile"]["iconHeight"]};
    }
    ${CardBase} h1 {
      font-size: ${sizes["mobile"]["fontSize"]};
    }
    ${(props) =>
      props.handCardNumber === "0" &&
      `
      position: absolute;
      transform: rotate(-6deg);
      left: 12px;
      z-index: 2;
    `}
    ${(props) =>
      props.handCardNumber === "1" &&
      `
      position: absolute;
      transform: rotate(6deg);
      right: 12px;
      z-index: 1;
    `}
    ${(props) =>
      props.isFlat &&
      `
      margin: 2px;
    `}
  }
`;

const cardHeightRaw = 55;
const mobileCardHeightRaw = 40;
const cardHeight = `${cardHeightRaw}px`;
const mobileCardHeight = `${mobileCardHeightRaw}px`;

export { cardHeight, mobileCardHeight, cardHeightRaw, mobileCardHeightRaw };
export default withTheme(Card);
