import React, { Fragment } from "react";
import { Icon } from "../../shared/FormElements";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Widget from "../../shared/Widget";

const ShowCardsToGodButton = ({
  emitSocketMessage,
  isGodModeEnabled,
  isGod,
  showCardsToGod,
  god,
  isSpectating,
}) => {
  const showCardsToGodRequest = () => {
    emitSocketMessage("show_cards_to_god", { show_cards: !showCardsToGod });
  };

  if (!isGodModeEnabled || isGod || isSpectating) {
    return <></>;
  }

  return (
    <Fragment>
      <Widget
        onClick={() => showCardsToGodRequest()}
        text={
          showCardsToGod ? (
            <>
              {"Hide Cards from " + god} <Icon icon={faEyeSlash} />
            </>
          ) : (
            <>
              {"Show Cards to " + god} <Icon icon={faEye} />
            </>
          )
        }
        style={ShowCardsGodButtonPosition}
      />
    </Fragment>
  );
};

const ShowCardsGodButtonPosition = {
  position: "absolute",
  zIndex: 3,
  top: "70px",
  right: "20px",
  height: "40px",
  borderTopLeftRadius: "7px",
  borderTopRightRadius: "7px",
  paddingTop: "10px",
};

export default ShowCardsToGodButton;
