const sizes = {
  lg: {
    height: "95px",
    width: "72px",
    iconHeight: "30px",
    iconBottom: "5px",
    fontSize: "52px",
  },
  md: {
    height: "78px",
    width: "58px",
    iconHeight: "26px",
    iconBottom: "5px",
    fontSize: "44px",
  },
  sm: {
    height: "70px",
    width: "50px",
    iconHeight: "22px",
    iconBottom: "5px",
    fontSize: "42px",
  },
  mobile: {
    height: "50px",
    width: "35px",
    iconHeight: "14px",
    iconBottom: "5px",
    fontSize: "26px",
  },
};
export default sizes;
