/**
 * Modal Conductor should be at the root level,
 * and ensures that only one modal is shown at a time,
 * using a single variable to switch on
 * TODO: migrate all modals to use ModalConductor
 */
import React from "react";
import { modals } from "../../../helpers/constants";
import { refreshPage } from "../../../helpers/utils";
import {
  BuyInModal,
  FeedbackModal,
  RaisePercentInfoModal,
  DisconnectedModal,
  NoInternetModal,
  DifferentLogInModal,
  PatreonModal,
} from "../../Modals";
import ModalNotifWrapper from "../../../shared/Modal/ModalNotifWrapper";
import { feedbackNotif } from "./notifications";
import { Offline, Online } from "react-detect-offline";

const ModalConductor = ({
  currentModal,
  setCurrentModal,
  emitSocketMessage,
  ownStackCents,
  gameId,
  username,
  isMobile,
  setExistsModal,
  isSpectating,
  provider,
  isInCents,
}) => {
  return (
    <>
      <BuyInModal
        show={currentModal === modals.BUY_IN}
        hideModal={() => setCurrentModal(null)}
        emitSocketMessage={emitSocketMessage}
        ownStackCents={ownStackCents}
        isInCents={isInCents}
      />
      <PatreonModal
        show={currentModal === modals.PATREON}
        hideModal={() => {
          setCurrentModal(null);
        }}
      />
      <Online>
        <DisconnectedModal
          show={currentModal === modals.DISCONNECTED_SERVER_NOT_IN_GAME}
          onBtnClick={refreshPage}
          hasJoinedGame={false}
        />
        <DisconnectedModal
          show={currentModal === modals.DISCONNECTED_SERVER_IN_GAME}
          onBtnClick={refreshPage}
          hasJoinedGame={true}
        />
      </Online>
      <Offline>
        <NoInternetModal
          show={
            currentModal === modals.DISCONNECTED_SERVER_NOT_IN_GAME ||
            currentModal === modals.DISCONNECTED_SERVER_IN_GAME
          }
          onBtnClick={refreshPage}
        />
      </Offline>
      <DifferentLogInModal
        show={currentModal === modals.DIFFERENT_LOG_IN}
        onBtnClick={refreshPage}
        isSpectating={isSpectating}
      />
      <ModalNotifWrapper
        modalConstant={modals.FEEDBACK}
        setModal={(modal) => setCurrentModal(modal)}
        setExistsModal={setExistsModal}
        notification={feedbackNotif}
      >
        <FeedbackModal
          show={currentModal === modals.FEEDBACK}
          gameId={gameId}
          username={username}
          isMobile={isMobile}
          provider={provider}
        />
      </ModalNotifWrapper>
      <RaisePercentInfoModal
        show={currentModal === modals.RAISE_INFO}
        hideModal={() => setCurrentModal(null)}
      />
    </>
  );
};

export default ModalConductor;
