import React, { useState, useEffect } from "react";
import { mobileBreakpoint, mobileHeightBreakpoint } from "../helpers/constants";
import styled from "styled-components";

const Notification = (props) => {
  const [show, setShow] = useState(false);

  const showNotification = () => {
    const notifShowDuration = props.notifDuration || 1700;
    setShow(true);
    setTimeout(() => {
      setShow(false);
      props.onNotifEnd();
    }, notifShowDuration);
  };

  useEffect(() => {
    if (props.show && !show) {
      showNotification();
    }
  }, [props.show]);

  return (
    <NotificationWrapper show={show} clickable={props.clickable}>
      {props.children}
    </NotificationWrapper>
  );
};

const NotificationWrapper = styled.div`
  position: absolute;
  z-index: 4;
  background: white;
  padding: 10px;
  border: 2px solid ${({ theme }) => theme.foreground};
  border-radius: 5px;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s ease all;
  pointer-events: none;

  ${(props) => {
    let s = "";
    if (!props.show) {
      s += `
    bottom: 0;
    opacity: 0;
  `;
    }

    if (props.clickable) {
      s += `
      white-space: nowrap;
      pointer-events: auto;
      `
    }

    return s
  }}}

  @media screen and (max-width: ${mobileBreakpoint}), screen and (max-height: ${mobileHeightBreakpoint}) {
    bottom: 20px;
  }
`;

export default Notification;
