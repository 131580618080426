import React, { useState } from "react";
import { Icon } from "./FormElements";
import { MainOverlay } from "./Overlay";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { colors, font } from "../helpers/styles";
import styled from "styled-components";

const DropDownOptionsPicker = ({
  show,
  initialExpanded,
  optionsList,
  style,
  includeOverlay,
  dataTestId,
  onCloseOverride,
  toggleInner,
}) => {
  const [expanded, _setExpanded] = useState(initialExpanded || false);

  const setExpanded = (expanded) => {
    if (onCloseOverride && !expanded) {
      onCloseOverride();
    } else {
      _setExpanded(expanded);
    }
  };

  return (
    <Wrapper style={style} hide={!show}>
      <OptionsCircleWrapper data-testid={dataTestId}>
        <OptionCircle
          hide={!show}
          delay={0}
          onClick={() => setExpanded(!expanded)}
          toggled={expanded}
        >
          {toggleInner ? (
            toggleInner
          ) : (
            <span className="rotate">
              <Icon icon={faPlus} color={colors.white} />
            </span>
          )}
        </OptionCircle>
      </OptionsCircleWrapper>
      {expanded && (
        <DropDownList>
          {optionsList.map((action, i) => (
            <OptionsCircleWrapper
              isLoading={action.isLoading}
              key={action.name}
            >
              <OptionCircle
                hide={!show}
                data-testid={`${action.dataTestId}-btn`}
                onClick={action.onClick}
                selected={action.selected}
                delay={(i + 1) * 20}
                disabled={action.disabled}
              >
                {action.inner}
              </OptionCircle>
              {action.showLabel && <OptionLabel>{action.name}</OptionLabel>}
            </OptionsCircleWrapper>
          ))}
        </DropDownList>
      )}
      {includeOverlay && (
        <MainOverlay
          zIndex={-1}
          opacity="0.4"
          hide={!expanded}
          onClick={() => setExpanded(false)}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${(props) => props.hide && `pointer-events: none;`}
`;

const DropDownList = styled.div`
  margin-top: 10px;
`;

const OptionCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ theme }) => theme.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${font.body};
  font-weight: bold;
  color: ${colors.white};
  cursor: pointer;
  transition: transform 400ms cubic-bezier(0.26, 0.76, 0.4, 1.12)
      ${(props) => props.delay || 0}ms,
    opacity 200ms ease 0ms;
  span {
    transition: 0.2s ease transform;
  }
  &:hover {
    background: ${({ theme }) => theme.primary};
  }
  ${(props) =>
    props.toggled &&
    `
    background: ${colors.darkGrey2};
    &:hover {
      background: ${colors.darkGrey2};
    }
    span.rotate {
      transform: rotate(45deg);
    }
  `}
  ${(props) =>
    props.selected &&
    props.theme &&
    `
    background: ${props.theme.primary};
  `}
  ${(props) =>
    props.hide &&
    `
    transform: translateX(-100px);
  `}
  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    cursor: not-allowed;
  `}
`;

const OptionLabel = styled.div`
  display: none;
  margin-left: 10px;
  pointer-events: none;
  background: ${({ theme }) => theme.foreground};
  color: ${({ theme }) => theme.text.label};
  font-weight: bold;
  font-family: ${font.body};
  font-size: 12px;
  border-radius: 5px;
  height: fit-content;
  min-width: max-content;
  padding: 2px 5px;
`;

const OptionsCircleWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 10px;
  &:hover {
    cursor: pointer;
    ${OptionLabel} {
      display: block;
    }
  }
  ${(props) =>
    props.isLoading &&
    `
    pointer-events: none;
    opacity: 0.5;
  `}
`;

export default DropDownOptionsPicker;
