import React, { useState, useContext, useEffect } from "react";
import { OverlayTrigger } from "react-bootstrap";
import styled, { css } from "styled-components";
import Modal from "../../../shared/Modal";
import GameSettingsChoice from "./GameSettingsChoice";
import { TournamentInfoOverlay, GodModeOverlay } from "./InfoOverlay";
import BlindStructureScroll from "../../../shared/BlindStructureScroll";
import { MediumHeaderText, SmallBodyText } from "../../../shared/Text";
import {
  InputText,
  InputField,
  InputLabel,
} from "../../../shared/FormElements";
import { InfoIcon } from "../../../shared/Overlay";
import {
  Accordion,
  AccordionItem,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { isInvalidOrEmpty, formatCurrency } from "../../../helpers/utils";
import {
  removeNumberCommas,
  insertNumberCommas,
} from "../../../helpers/calculations";
import { colors } from "../../../helpers/styles";
import { blinds, timer, buyin } from "../../../helpers/constants";
import Switch from "../../../shared/FormElements/Switch";
import { GameContext } from "../../../context/GameContext";
import { BrowserContext } from "../../../context/BrowserContext";

const GameSettingsModal = (props) => {
  const [smallBlindCents, setSmallBlindCents] = useState(
    blinds.DEFAULT_SMALL_BLIND_CENTS
  );
  const [bigBlindCents, setBigBlindCents] = useState(
    blinds.DEFAULT_BIG_BLIND_CENTS
  );
  const [blindIncreaseMinutes, setBlindIncreaseMinutes] = useState(
    blinds.DEFAULT_BLIND_INCREASE_MINUTES
  );
  // timers don't exist yet
  // const [timer, setTimer] = useState(-1);
  const [isTimerEnabled, setIsTimerEnabled] = useState(false);
  const [timerLengthSeconds, setTimerLengthSeconds] = useState(
    timer.DEFAULT_TIMER_SECONDS
  );
  const [isGodModeEnabled, setIsGodModeEnabled] = useState(false);
  const [isTournament, setIsTournament] = useState(false);
  const [autoBuyInCents, setAutoBuyInCents] = useState(
    buyin.DEFAULT_AUTO_BUYIN_CENTS
  );
  const [gamePassword, setGamePassword] = useState("");
  const { updateDisplay } = useContext(GameContext);
  const { isMobile } = useContext(BrowserContext);
  const [handTimerLengthSeconds, setHandTimerLengthSeconds] = useState(
    timer.MEDIUM_HAND_TIMER_SECONDS
  );
  const [isInCents, setIsInCents] = useState(true);
  const [autoBuyInFocus, setAutoBuyInFocus] = useState(false);

  useEffect(() => {
    if (!autoBuyInFocus) {
      // round betValueCents
      if (autoBuyInCents) {
        const autoBuyInCentsInt = parseInt(autoBuyInCents);
        setAutoBuyInCents(autoBuyInCentsInt);
      }
    }
  }, [autoBuyInFocus]);

  const onSaveButtonClick = () => {
    if (props.title === "Create New Game") {
      props._createGame(
        smallBlindCents,
        gamePassword,
        null,
        "game",
        isTimerEnabled,
        timerLengthSeconds,
        handTimerLengthSeconds,
        isGodModeEnabled,
        isTournament,
        autoBuyInCents,
        blindIncreaseMinutes * 60,
        isInCents
      );
    } else {
      // TODO, this is where you would make settings save during the game
      // this block represents the "Game Settings" modal as opposed to the "Create New Game" modal
      // for now just close it
      updateDisplay("play");
    }
    return new Promise(() => true);
  };

  const onNumberInputChange = (e, cb) => {
    const val = removeNumberCommas(e.target.value);
    if (!isInvalidOrEmpty(val)) {
      cb(val);
    }
  };

  const getAutoBuyInValue = () => {
    const autoBuyInDollarsFloat = parseFloat(autoBuyInCents) / 100;
    if (isNaN(autoBuyInDollarsFloat) || autoBuyInDollarsFloat === null) {
      return "";
    }
    if (!isInCents) {
      return Math.round(autoBuyInCents, 0);
    }
    return autoBuyInFocus
      ? parseFloat(autoBuyInCents) / 100
      : autoBuyInDollarsFloat.toFixed(2);
  };

  const enterPressedForBet = (event) => {
    if (!isInCents && !/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const password = (
    <PasswordElement fullWidth disableCustomStyles={props.disableModalStyles}>
      <InputLabel
        htmlFor="game-password"
        value={gamePassword}
        id="game-password-label"
      >
        Game password (optional)
      </InputLabel>
      <InputText
        id="game-password"
        onChange={(e) => setGamePassword(e.target.value)}
        type="password"
        placeholder="Game password (optional)"
        contrast
      />
    </PasswordElement>
  );

  return (
    <Modal
      show
      onOverlayClick={props._cancelPopup}
      title={
        props.title ? props.title : props.disableTitle ? null : "Game Settings"
      }
      btnText={props.buttonTitle ? props.buttonTitle : "Save"}
      onBtnClick={onSaveButtonClick}
      dataTestId="settings-save-button"
      disableModalStyles={props.disableModalStyles}
      fullWidthInput={props.fullWidthInput}
      align={props.align}
      ctaContent={password}
      margin
      scrollMiddle={!isMobile}
    >
      <SettingsWrapper disableModalStyles={props.disableModalStyles}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <SettingsText align={props.align ? props.align : "left"} themeable>
            Blinds
          </SettingsText>
          <SettingsSubText color={colors.grey}>
            {isTournament
              ? "Set your initial small/big blinds"
              : "Set your small/big blinds"}
            <br />
            {isTournament ? "Blind structure shown below" : ""}
          </SettingsSubText>
        </div>
        <GameSettingsChoice
          id={"5c10c"}
          smallBlindCents={smallBlindCents}
          bigBlindCents={bigBlindCents}
          setSmallBlindCents={(val) => setSmallBlindCents(val)}
          setBigBlindCents={(val) => setBigBlindCents(val)}
          isInCents={isInCents}
          setIsInCents={setIsInCents}
        />
      </SettingsWrapper>
      <SettingsWrapper>
        <SettingsLabel>
          <SettingsText align="left">Timer</SettingsText>
        </SettingsLabel>
        <Switch
          isOn={isTimerEnabled}
          handleToggle={() => {
            if (!isTournament) {
              setIsTimerEnabled(!isTimerEnabled);
            }
          }}
        />
      </SettingsWrapper>
      <SettingsWrapper hidden={!isTimerEnabled}>
        <SettingsLabel>
          <SettingsText align="left">Timer (seconds)</SettingsText>
          <SettingsSubText color={colors.grey} style={{ width: "100%" }}>
            Set amount of time per turn
          </SettingsSubText>
        </SettingsLabel>
        <BigBlindInputText
          value={insertNumberCommas(timerLengthSeconds.toString())}
          onChange={(e) => onNumberInputChange(e, setTimerLengthSeconds)}
          placeholder={timer.DEFAULT_TIMER_SECONDS}
          modal
        />
      </SettingsWrapper>
      <AccordionWrapper>
        <AccordionItem dangerouslySetExpanded={isTournament}>
          <SettingsWrapper>
            <SettingsLabel style={{ flexWrap: "nowrap" }}>
              <SettingsText align="left">Tournament Mode</SettingsText>
              <OverlayTrigger
                placement={isMobile ? "top" : "right"}
                overlay={TournamentInfoOverlay}
                style={{ float: "left" }}
              >
                <InfoIcon>?</InfoIcon>
              </OverlayTrigger>
            </SettingsLabel>
            <Switch
              isOn={isTournament}
              handleToggle={() => {
                setIsTournament(!isTournament);
                setIsTimerEnabled(true);
              }}
              dataTestId={"tournament-mode-switch"}
            />
          </SettingsWrapper>
          <AccordionItemPanel>
            <SettingsWrapper>
              <SettingsLabel>
                {isInCents && (
                  <SettingsText align="left">
                    Automatic Buy-in (dollars)
                  </SettingsText>
                )}
                {!isInCents && (
                  <SettingsText align="left">
                    Automatic Buy-in (chips)
                  </SettingsText>
                )}
                <SettingsSubText color={colors.grey}>
                  Set automatic buy-in amount
                </SettingsSubText>
              </SettingsLabel>
              <AutoBuyInInputText
                type="number"
                onWheel={(e) => e.target.blur()}
                value={getAutoBuyInValue()}
                onChange={(e) =>
                  setAutoBuyInCents(
                    isInCents
                      ? parseFloat(e.target.value).toFixed(2) * 100
                      : e.target.value
                  )
                }
                onKeyPress={enterPressedForBet}
                placeholder={formatCurrency(
                  buyin.DEFAULT_AUTO_BUYIN_CENTS,
                  isInCents
                )}
                onFocus={() => setAutoBuyInFocus(true)}
                onBlur={() => setAutoBuyInFocus(false)}
                modal
              />
            </SettingsWrapper>
            <SettingsWrapper>
              <SettingsLabel>
                <SettingsText align="left">
                  Increasing Blinds (minutes)
                </SettingsText>
                <SettingsSubText color={colors.grey}>
                  Set increasing blinds time interval
                </SettingsSubText>
              </SettingsLabel>
              <BigBlindInputText
                value={insertNumberCommas(blindIncreaseMinutes.toString())}
                onChange={(e) =>
                  onNumberInputChange(e, setBlindIncreaseMinutes)
                }
                placeholder={buyin.DEFAULT_BLIND_INCREASE_MINUTES}
                modal
              />
            </SettingsWrapper>
            <SettingsWrapper>
              <BlindStructureScroll
                initialBlindCents={smallBlindCents}
                blindIncreaseMinutes={blindIncreaseMinutes}
                currentGameTimeSeconds={-1}
                showTitle={true}
                showCurrentBlinds={false}
                isInCents={isInCents}
              />
            </SettingsWrapper>
          </AccordionItemPanel>
        </AccordionItem>
      </AccordionWrapper>
      <SettingsWrapper>
        <SettingsLabel>
          <SettingsText align="left">God Mode</SettingsText>
          <OverlayTrigger
            placement={isMobile ? "top" : "right"}
            overlay={GodModeOverlay}
            style={{ float: "left" }}
          >
            <InfoIcon>?</InfoIcon>
          </OverlayTrigger>
        </SettingsLabel>
        <Switch
          isOn={isGodModeEnabled}
          handleToggle={() => setIsGodModeEnabled(!isGodModeEnabled)}
        />
      </SettingsWrapper>
      {props.disableModalStyles && password}
    </Modal>
  );
};

const SettingsWrapper = styled.div`
  margin: 5px 0 10px 0;
  width: 100%;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.disabled &&
    `
  opacity: 0.2;
  pointer-events: none;
  `}
`;

const SettingsText = styled(MediumHeaderText)`
  width: auto;
  display: inline-block;
`;

const SettingsLabel = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 20px;
  width: 100%;
  color: ${({ theme }) => theme.text.label};
`;

const SettingsSubText = styled(SmallBodyText)`
  width: 100%;
`;

const BigBlindInputText = styled(InputText)`
  height: 50px;
  text-align: right;
  font-size: 22px;
  width: 30%;
`;

const AccordionWrapper = styled(Accordion)`
  width: 100%;
`;

export const PasswordElement = styled(InputField)`
  input {
    transition: border 0.3s ease;
  }
  ${(props) =>
    !props.disableCustomStyles &&
    css`
      display: flex;
      input {
        width: 90%;
      }
    `}

  ${(props) =>
    props.invalid &&
    css`
      input {
        border: 1px solid ${colors.red};
      }
    `}
  ${(props) =>
    !props.disablePadding &&
    `
    padding-right: 10px;
  `}
`;

const AutoBuyInInputText = styled(BigBlindInputText)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export default GameSettingsModal;
