import React, { useState, useEffect } from "react";
import { sendFeedback } from "../../../helpers/api";
import Modal, { transitionTime } from "../../../shared/Modal";
import { MediumBodyText, SmallBodyText } from "../../../shared/Text";
import { InputText, Icon, TextAreaInput } from "../../../shared/FormElements";
import { optionsOrderedByRating } from "./options";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../../helpers/constants";
import styled from "styled-components";
import { logError } from "../../../helpers/logger";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

/**
 * Required to submit feedback:
 * rating
 * OR
 * rating + text feedback + email (if you add text feedback, email required)
 * OR
 * rating + beta interested + email (if you heart react, email required)
 */
const FeedbackModal = (props) => {
  const [rating, setRating] = useState(-1);
  const [email, setEmail] = useState("");
  const [textareaText, setTextareaText] = useState("");
  const [betaInterest, setBetaInterest] = useState(-1);
  const [isLoadingAfterSend, setIsLoadingAfterSend] = useState(false);
  const [startTransition, setStartTransition] = useState(true);

  const _reset = () => {
    setRating(-1);
    setTextareaText("");
    setBetaInterest(-1);
  };

  const submitFeedback = () => {
    if (rating !== -1) {
      setIsLoadingAfterSend(true);
      return sendFeedback(
        props.gameId,
        props.username,
        email,
        rating + 1,
        textareaText,
        betaInterest,
        props.provider
      )
        .then((_) => {
          setStartTransition(true);
          setIsLoadingAfterSend(false);
          setTimeout(() => {
            props.hideModal(true);
            _reset();
          }, transitionTime);
          return false;
        })
        .catch((err) => {
          logError(err);
          return false;
        });
    }
    return Promise.resolve(false);
  };

  const close = () => {
    setStartTransition(true);
    setTimeout(() => {
      props.hideModal(false);
    }, transitionTime);
  };

  useEffect(() => {
    // if showing again, reset
    if (props.show) {
      setStartTransition(false);
    }
  }, [props.show]);

  return (
    <Modal
      show={true}
      title="Share Your Feedback"
      btnText="Send"
      onBtnClick={submitFeedback}
      onOverlayClick={close}
      dataTestId="feedback-submit-button"
      isInvalid={
        rating === -1 ||
        (rating !== -1 && textareaText.length !== 0 && email.length === 0) ||
        (rating !== -1 && betaInterest === 1 && email.length === 0) ||
        isLoadingAfterSend
      }
      transitionOut={startTransition}
    >
      <FeedbackModalInner disable={isLoadingAfterSend}>
        <FeedbackHeader>
          <Label>How happy are you with LiPoker?</Label>
          <OptionPicker>
            {optionsOrderedByRating.map(({ icon }, i) => (
              <Option
                key={i}
                index={i}
                icon={icon}
                size={props.isMobile ? "2x" : "3x"}
                selected={i === rating}
                onClick={() => setRating(i)}
              />
            ))}
          </OptionPicker>
        </FeedbackHeader>
        {rating !== -1 && (
          <>
            <TextAreaInput
              rows="5"
              placeholder={optionsOrderedByRating[rating].textResponse}
              value={textareaText}
              onChange={(e) => setTextareaText(e.target.value)}
            />
            <br />
            <InputLine
              id="user-email"
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder={textareaText.length === 0 ? "Email" : "Email *"}
            />
            {textareaText.length !== 0 && (
              <SmallLabel>{`* required: We'd love to let you know once we implement your suggestion!`}</SmallLabel>
            )}
            {textareaText.length === 0 && betaInterest === 1 && (
              <SmallLabel>{`* required: We'd love to let you know once we address your feedback!`}</SmallLabel>
            )}
            <HeartWrapper>
              <Option
                icon={faHeart}
                size={props.isMobile ? "lg" : "lg"}
                selected={betaInterest === 1}
                onClick={() => setBetaInterest(-betaInterest)}
              />
              <HeartLabel>{`Heart react if you'd be open to working with us to beta test and provide feedback!`}</HeartLabel>
            </HeartWrapper>
            {rating >= 3 && (
              <HeartWrapper>
                <HeartLabel>
                  {
                    "Like every site, Lipoker needs resources. Please consider making a donation "
                  }
                  <a href={"https://www.patreon.com/lipoker"} target="_blank">
                    here
                  </a>
                  {". Thank you for your tremendous support."}
                </HeartLabel>
              </HeartWrapper>
            )}
          </>
        )}
      </FeedbackModalInner>
    </Modal>
  );
};

const Label = styled(MediumBodyText)`
  color: ${({ theme }) => theme.text.label};
`;

const SmallLabel = styled(SmallBodyText)`
  color: ${({ theme }) => theme.text.placeholder};
  margin-left: 2px;
  margin-top: 3px;
`;

const FeedbackModalInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 80px;
  ${(props) =>
    props.disable &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
  @media screen and (max-width: ${mobileBreakpoint}), screen and (max-height: ${mobileHeightBreakpoint}) {
    padding: 0 50px;
  }
`;

const FeedbackHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OptionPicker = styled.div`
  display: flex;
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
`;

const Option = styled(Icon)`
  color: ${(props) =>
    props.selected
      ? ({ theme }) => theme.tertiary
      : ({ theme }) => theme.foreground};
  ${(props) => props.index === 0 && `margin-left: 0px;`}
  transition: 0.2s ease all;
  &:hover {
    transform: scale(1.1);
  }
`;

const HeartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  align-items: center;
`;

const HeartLabel = styled(SmallLabel)`
  margin-left: 6px;
`;

const InputLine = styled(InputText)`
  width: 100%;
  margin-right: 10px;
`;

export default FeedbackModal;
