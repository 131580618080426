import React from "react";
import { SmallBodyText } from "./Text";
import { Icon } from "./FormElements";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useStateWithLocalStorageBoolean } from "../hooks/useStateWithLocalStorage";
import styled, { withTheme } from "styled-components";

const CollapsibleNotice = ({ uniqueId, text, theme }) => {
  const [show, setShow] = useStateWithLocalStorageBoolean(uniqueId, true);

  if (!show) {
    return <></>;
  }

  return (
    <Wrapper>
      {text}
      <CancelIcon
        icon={faTimes}
        color={theme.text.label}
        onClick={() => setShow(false)}
      />
    </Wrapper>
  );
};

const Wrapper = styled(SmallBodyText)`
  position: relative;
  text-align: center;
  color: ${({ theme }) => theme.text.label};
  border: 1px dashed ${({ theme }) => theme.text.label};
  padding: 10px 0;
`;

const CancelIcon = styled(Icon)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
`;

export default withTheme(CollapsibleNotice);
