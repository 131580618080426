import React from "react";

const Diamond = ({ color = "#000000", style }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
      xmlSpace="preserve"
      style={style}
    >
      <polyline
        style={{ fill: color }}
        points="172.436,498.05 501.834,1002 827.564,498.05 500,3.333 "
      />
    </svg>
  );
};

export default Diamond;
