import {
  LEADERBOARD,
  DISPLAY,
  JOIN_GAME,
  SPECTATE_GAME,
  GAME_STATE_CHANGE,
  MTT_STATE_CHANGE,
  RECEIVE_CHAT,
  IS_RUNNING_DELAY,
  CREATE_GAME,
  CREATE_MTT,
  CHECK_GAME_EXISTS,
  SET_USER_DISCONNECTED,
  SET_GAME_SETTINGS,
  SET_PLAYERS,
  SET_SPECTATORS,
  QUEUED_ACTION,
  UPDATE_PLAYER_FIELDS,
  GET_MTT_PLAYERS,
  CHECK_MTT_EXISTS,
  JOIN_MTT,
  UPDATE_MTT_STATE,
  SET_MTT_USERS,
} from "../actions/types";
import { queuedActionReducer } from "./queuedActionReducer";
import { playerReducer } from "./playerReducer";
import { mttUsersReducer } from "./mttUsersReducer";
import { spectatorReducer } from "./spectatorReducer";
import { stateReducer } from "./stateReducer";
import { mttStateReducer } from "./mttStateReducer";
import { logState } from "../helpers/logger";
import { timer } from "../helpers/constants";

export const INITIAL_STATE = {
  gameId: "", // stores url parameter
  MTTId: "",
  display: "loading", // can be loading, play, login, settings, newGame, initial, none (instantly converts into newGame on top of empty game)
  // changed on line 76 at the end of login() to simulate different states
  username: "Anonymous",
  ownStackCents: 0,
  sessionKey: null,
  ownPotCents: 0,
  allTimeBalance: 0,
  maxPotCents: 0, // max of all player's pots at table
  betsOnTableSumCents: 0, // sum of all player's pots/bets at table
  activePlayers: [],
  faceUpHoleCards: {},
  chats: [],
  usernameToHandsWon: {}, // {"jack":10, "michael":4} for instance
  mainPotCents: 0,
  sidePotCents: 0, // only the side pot that is relevant to the current player, only displayed in UI if > 0
  smallBlindCents: 5,
  // TODO @dmitri big blind is always going to be 2x small blind, maintaining 2 variables will cause bugs
  bigBlindCents: 10,
  mostRecentChatTimestamp: -1, //only update chats after this timestamp
  stillInHand: false,
  stillInGame: false,
  hasGamePassword: false,
  prefilledGamePassword: "",
  showBuyIn: false,
  minRaise: 0,
  statesQueued: [], // DEPRECATED
  sessionLeaderboard: [],
  leaderboard: [],
  isRunningDelay: false,
  dealerPosition: 0,
  allowKeyboardShortCuts: true,
  queuedAction: null,
  willExecuteQueuedAction: false,
  isTimerEnabled: false,
  timerLengthSeconds: 30,
  handTimerLengthSeconds: timer.MEDIUM_HAND_TIMER_SECONDS,
  turnNonce: null, // the # of turns that have happened in the game so far
  ongoing: false,
  isSpectating: false,
  maxPlayerNumber: 9,
  isGodModeEnabled: false,
  isGod: false,
  showCardsToGod: false,
  god: null,
  isTournament: false,
  autoBuyinCents: 1000,
  totalBuyinCents: 0,
  initialBlindCents: 5,
  blindIncreaseMinutes: 10,
  gameStartTime: null,
  paused: false,
  sessionReset: false,
  isInCents: true,
  MTTPlayers: [],
  MTTUsers: [],
  MTTOngoing: false,
};

export default function (state = INITIAL_STATE, action) {
  if (action.type === LEADERBOARD) {
    return {
      ...state,
      sessionLeaderboard: action.payload.sessionLeaderboard,
      leaderboard: action.payload.leaderboard,
    };
  } else if (action.type === IS_RUNNING_DELAY) {
    return { ...state, isRunningDelay: action.payload.isRunningDelay };
  } else if (action.type === DISPLAY) {
    return { ...state, display: action.payload.display };
  } else if (action.type === UPDATE_MTT_STATE) {
    return { ...state, gameId: action.payload.gameId };
  } else if (action.type === RECEIVE_CHAT) {
    return {
      ...state,
      chats: [...state.chats, action.payload],
    };
  } else if (action.type === JOIN_GAME) {
    return {
      ...state,
      display: "play",
      username: action.payload.username,
      sessionKey: action.payload.sessionKey,
      smallBlindCents: action.payload.smallBlindCents,
      bigBlindCents: action.payload.smallBlindCents * 2,
      gameId: action.payload.gameId,
      isGod: action.payload.isGod,
      god: action.payload.god,
      isTournament: action.payload.isTournament,
      autoBuyinCents: action.payload.autoBuyinCents,
      totalBuyinCents: action.payload.totalBuyinCents,
      initialBlindCents: action.payload.initialBlindCents,
      blindIncreaseMinutes: action.payload.blindIncreaseMinutes,
    };
  } else if (action.type === JOIN_MTT) {
    return {
      ...state,
      display: "lobby",
      username: action.payload.username,
      MTTId: action.payload.MTTId,
      sessionKey: action.payload.key,
      //TODO MTT
    };
  } else if (action.type === SPECTATE_GAME) {
    return {
      ...state,
      display: "play",
      username: action.payload.username,
      sessionKey: action.payload.sessionKey,
      gameId: action.payload.gameId,
      isSpectating: true,
      isGod: action.payload.isGod,
      god: action.payload.god,
    };
  } else if (action.type === CREATE_GAME) {
    return {
      ...state,
      // reset chats in case you created a new game while playing a game
      chats: [],
      display: "login",
      gameId: action.payload.gameId,
      smallBlindCents: action.payload.smallBlindCents,
      bigBlindCents: action.payload.smallBlindCents * 2,
      hasGamePassword: action.payload.hasGamePassword,
      prefilledGamePassword: action.payload.prefilledGamePassword,
      isTimerEnabled: action.payload.isTimerEnabled,
      timerLengthSeconds: parseInt(action.payload.timerLengthSeconds),
      handTimerLengthSeconds: parseInt(action.payload.handTimerLengthSeconds),
      isGodModeEnabled: action.payload.isGodModeEnabled,
      isGod: action.payload.isGodModeEnabled,
      god: action.payload.god,
      isTournament: action.payload.isTournament,
      autoBuyinCents: parseInt(action.payload.autoBuyinCents),
    };
  } else if (action.type === CREATE_MTT) {
    return {
      ...state,
      display: "login",
      MTTId: action.payload.MTTId,
      smallBlindCents: action.payload.smallBlindCents,
      bigBlindCents: action.payload.smallBlindCents * 2,
      hasMTTPassword: action.payload.hasMTTPassword,
      prefilledMTTPassword: action.payload.prefilledMTTPassword,
      timerLengthSeconds: parseInt(action.payload.timerLengthSeconds),
      startingStack: parseInt(action.payload.startingStack),
    };
  } else if (action.type === GET_MTT_PLAYERS) {
    return {
      ...state,
      MTTPlayers: action.payload.MTTPlayers,
    };
  } else if (action.type === CHECK_GAME_EXISTS) {
    return {
      ...state,
      display: action.payload.display,
      hasGamePassword: action.payload.hasGamePassword,
    };
  } else if (action.type === CHECK_MTT_EXISTS) {
    return {
      ...state,
      display: action.payload.display,
      hasMTTPassword: action.payload.hasMTTPassword,
    };
  } else if (action.type === GAME_STATE_CHANGE) {
    const stateUpdate = stateReducer(state, action);
    logState(stateUpdate);
    return {
      ...state,
      ...stateUpdate,
    };
  } else if (action.type === MTT_STATE_CHANGE) {
    const stateUpdate = mttStateReducer(state, action);
    return {
      ...state,
      ...stateUpdate,
    };
  } else if (action.type === SET_USER_DISCONNECTED) {
    const disconnectedUserName = action.payload.username;

    const newActivePlayers = state.activePlayers.map((player) => {
      if (player.playerName === disconnectedUserName) {
        return Object.assign({}, player, {
          connected: false,
        });
      }
      return player;
    });

    return {
      ...state,
      activePlayers: newActivePlayers,
    };
  } else if (action.type === SET_GAME_SETTINGS) {
    const smallBlindCentsValue = parseInt(action.payload.smallBlindCents);
    const isTimerEnabledValue = action.payload.isTimerEnabled;
    const timerLengthSecondsValue = parseInt(action.payload.timerLengthSeconds);
    const handTimerLengthSecondsValue = parseInt(
      action.payload.handTimerLengthSeconds
    );
    const sessionReset = action.payload.sessionReset;
    return {
      ...state,
      smallBlindCents: smallBlindCentsValue,
      bigBlindCents: smallBlindCentsValue * 2,
      isTimerEnabled: isTimerEnabledValue,
      timerLengthSeconds: timerLengthSecondsValue,
      handTimerLengthSeconds: handTimerLengthSecondsValue,
      sessionReset: sessionReset,
    };
  } else if (action.type === SET_PLAYERS) {
    const playerUpdate = playerReducer(state, action);
    const queuedActionUpdate = queuedActionReducer(state, action, playerUpdate);

    return {
      ...state,
      ...playerUpdate,
      ...queuedActionUpdate,
    };
  } else if (action.type === SET_MTT_USERS) {
    const mttUsersUpdate = mttUsersReducer(state, action);

    return {
      ...state,
      ...mttUsersUpdate,
    };
  } else if (action.type === SET_SPECTATORS) {
    const spectatorUpdate = spectatorReducer(state, action);

    return {
      ...state,
      ...spectatorUpdate,
    };
  } else if (action.type === QUEUED_ACTION) {
    return {
      ...state,
      queuedAction: action.payload.queuedAction,
    };
  } else if (action.type === UPDATE_PLAYER_FIELDS) {
    const playerUserName = action.payload.username;
    const newActivePlayers = state.activePlayers.map((player) => {
      if (player.playerName === playerUserName) {
        return Object.assign({}, player, action.payload.fields);
      }
      return player;
    });
    return {
      ...state,
      activePlayers: newActivePlayers,
    };
  } else {
    return state;
  }
}
