import React, { useState, useEffect, useRef } from "react";
import Modal, { transitionTime } from "../../../shared/Modal";
import { MediumBodyText } from "../../../shared/Text";
import { InputText } from "../../../shared/FormElements";
import { removeNumberCommas } from "../../../helpers/calculations";
import { formatCurrency } from "../../../helpers/utils";
import styled from "styled-components";

const BuyInModal = ({
  show,
  hideModal,
  emitSocketMessage,
  ownStackCents,
  isInCents,
}) => {
  const [startTransition, setStartTransition] = useState(true);
  const [buyInDollars, setBuyInAmount] = useState("");
  const [subtractDollars, setSubtractAmount] = useState("");
  const buyInInputElement = useRef(null);
  const subtractInputElement = useRef(null);

  useEffect(() => {
    // if showing again, reset
    if (show) {
      setStartTransition(false);
      buyInInputElement.current.focus();
    }
  }, [show]);

  const close = () => {
    setStartTransition(true);
    setTimeout(() => {
      hideModal(false);
    }, transitionTime);
  };

  const _isInvalidOrEmpty = (val) => {
    if (val === "") {
      setBuyInAmount("");
      return true;
    }
    return isNaN(val);
  };

  const _isInvalidOrEmptySubtract = (val) => {
    if (val === "") {
      setSubtractAmount("");
      return true;
    }
    return isNaN(val);
  };

  const onBuyInChange = (e) => {
    const val = removeNumberCommas(e.target.value);
    if (!_isInvalidOrEmpty(val)) {
      setBuyInAmount(val);
    }
  };

  const onSubtractChange = (e) => {
    const val = removeNumberCommas(e.target.value);
    if (!_isInvalidOrEmptySubtract(val)) {
      setSubtractAmount(val);
    }
  };

  const getTotalStackAmount = () => {
    let amountToAdd = 0;
    if (buyInDollars.length !== 0) {
      amountToAdd = parseFloat(buyInDollars);
    }
    if (subtractDollars.length !== 0) {
      amountToAdd -= parseFloat(subtractDollars);
    }
    if (isInCents) {
      amountToAdd *= 100;
    }
    return Math.round(ownStackCents + amountToAdd);
  };

  const sendSocketBuyIn = () => {
    let buyInNumber, subtractNumber;
    if (buyInDollars.length === 0) buyInNumber = 0;
    else buyInNumber = parseFloat(buyInDollars);
    if (subtractDollars.length === 0) subtractNumber = 0;
    else subtractNumber = parseFloat(subtractDollars);

    if (isInCents) {
      buyInNumber *= 100;
      subtractNumber *= 100;
    }

    emitSocketMessage("request_buy_in", {
      amount: Math.round(buyInNumber - subtractNumber),
    });
    setBuyInAmount("");
    setSubtractAmount("");
    close();
    return Promise.resolve(false);
  };

  const onBuyInKeypress = (isInCents) => (event) => {
    if (!isInCents && !/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
    var code = event.keyCode || event.which;
    if (
      code === 13 &&
      (buyInDollars.length !== 0 || subtractDollars.length !== 0)
    ) {
      sendSocketBuyIn();
    }
  };

  return (
    <Modal
      show={true}
      onOverlayClick={close}
      isInvalid={
        (buyInDollars.length === 0 && subtractDollars.length === 0) ||
        getTotalStackAmount() < 0
      }
      transitionOut={startTransition}
      margin={false}
      onBtnClick={sendSocketBuyIn}
      btnText="Buy in"
    >
      <BuyInWrapper>
        <Row>
          <LabelText>Your current stack</LabelText>
          <NumbersText>{formatCurrency(ownStackCents, isInCents)}</NumbersText>
        </Row>
        <Row>
          <LabelText>Buy in for more</LabelText>
          <MediumBodyText>
            <InputWrapper>
              <span>+</span>
              <BuyInInput
                value={buyInDollars}
                onChange={onBuyInChange}
                ref={buyInInputElement}
                onKeyPress={onBuyInKeypress(isInCents)}
                modal
              />
            </InputWrapper>
          </MediumBodyText>
        </Row>
        <Row>
          <LabelText>Subtract from stack</LabelText>
          <MediumBodyText>
            <InputWrapper>
              <span>-</span>
              <BuyInInput
                value={subtractDollars}
                onChange={onSubtractChange}
                ref={subtractInputElement}
                onKeyPress={onBuyInKeypress(isInCents)}
                modal
              />
            </InputWrapper>
          </MediumBodyText>
        </Row>
        <Row strong>
          <LabelText>Your new stack would be</LabelText>
          <NumbersText>
            {formatCurrency(getTotalStackAmount(), isInCents)}
          </NumbersText>
        </Row>
      </BuyInWrapper>
    </Modal>
  );
};

const BuyInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0 60px;
  color: ${({ theme }) => theme.text.label};
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0;
  ${(props) =>
    props.strong &&
    `
    font-weight: bold;
  `}
`;

const LabelText = styled(MediumBodyText)`
  width: 100%;
`;

const NumbersText = styled(MediumBodyText)`
  margin-right: 15px;
`;

const InputWrapper = styled.div`
  display: flex;
  span {
    margin-right: 10px;
    font-weight: bold;
  }
`;

const BuyInInput = styled(InputText)`
  width: 120px;
  text-align: right;
`;

export default BuyInModal;
