import React, { Fragment, useState } from "react";
import { colors } from "../../../helpers/styles";
import { BackgroundButton, Icon } from "../../../shared/FormElements";
import { faCopy, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { copyTextToClipboard } from "../../../helpers/utils";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../../helpers/constants";
import styled from "styled-components";

const DURATION = 1000;
const CopyJoinLink = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFail, setShowFail] = useState(false);

  const copyGameInfo = (_) => {
    const url = window.location.href;
    copyTextToClipboard(url).then((success) => {
      _showCopyResult(success);
    });
  };

  const _showCopyResult = (success) => {
    if (success) {
      setShowSuccess(true);
    } else {
      setShowFail(true);
    }
    setTimeout(() => {
      if (success) {
        setShowSuccess(false);
      } else {
        setShowFail(false);
      }
    }, DURATION);
  };

  let inner;
  if (showSuccess) {
    inner = (
      <Fragment>
        <Icon icon={faCheck} isLeft={1} color={colors.darkGrey2} />
        <span>COPIED</span>
      </Fragment>
    );
  } else if (showFail) {
    inner = (
      <Fragment>
        <Icon icon={faTimes} isLeft={1} color={colors.darkGrey2} />
        <span>FAILED</span>
      </Fragment>
    );
  } else {
    inner = (
      <Fragment>
        <Icon icon={faCopy} isLeft={1} color={colors.darkGrey2} />
        <span>COPY GAME LINK</span>
      </Fragment>
    );
  }

  return (
    <JoinLinkButton onClick={copyGameInfo} actionDone={showSuccess || showFail}>
      {inner}
    </JoinLinkButton>
  );
};

const JoinLinkButton = styled(BackgroundButton)`
  color: ${({ theme }) => theme.text.widget};
  background: ${({ theme }) => theme.foreground};
  width: 165px;
  ${(props) =>
    props.actionDone &&
    `
    pointer-events: none;
  `}
  @media screen and (max-width: ${mobileBreakpoint}), screen and (max-height: ${mobileHeightBreakpoint}) {
    width: auto;
  }
  &:hover {
    background: ${({ theme }) => theme.background};
  }
`;
export default CopyJoinLink;
