import { generateCardHighlights } from "../helpers/utils";

export const mttUsersReducer = (state, action) => {
  const users = action.payload.mtt_users;
  let newMTTUsers = [];
  console.log(action);
  for (let pos in users) {
    let newMTTUser = {
      userName: users[pos].name,
      userRole: users[pos].role,
      userBalance: users[pos].stack,
      userPot: users[pos].pot,
      userIsInGame: users[pos].is_in_game,
      connected: users[pos].connected,
      isAtTable: users[pos].is_at_table,
    };
    newMTTUsers.push(newMTTUser);
  }
  return {
    MTTUsers: newMTTUsers,
  };
};
