import React, { useContext } from "react";
import styled from "styled-components";
import Card from "../../../shared/Card";
import FlipCard from "../../../shared/Card/FlipCard";

import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../../helpers/constants";
import { convertCardStringToValues } from "../../../helpers/utils";
import { GameContext } from "../../../context/GameContext";
import { BrowserContext } from "../../../context/BrowserContext";
import CopyJoinLink from "./CopyJoinLink";
import CentralPot from "../CentralPotView";

const maxCardsNumber = 5;

const Table = ({ playersAreInHand, isMobile, iframeClientName }) => {
  const { faceUpHoleCards } = useContext(GameContext);
  const { isFourColorDeck } = useContext(BrowserContext);

  const emptyCards = new Array(
    maxCardsNumber - Object.keys(faceUpHoleCards).length
  ).fill();

  const innerTable = playersAreInHand ? (
    <>
      {Object.keys(faceUpHoleCards).map((_, i) => {
        const cardObject = convertCardStringToValues(faceUpHoleCards[i].card);
        return (
          <FlipCard
            key={`card-${i}`}
            index={i}
            suit={cardObject.suit}
            number={cardObject.number}
            isHighlighted={faceUpHoleCards[i].isHighlighted}
            isFourColorDeck={isFourColorDeck}
            size={isMobile ? "mobile" : "lg"}
            isFlat
          />
        );
      })}
      {emptyCards.map((_, i) => (
        <Card
          key={`empty-card-${i}`}
          isFlat
          isEmpty
          size={isMobile ? "mobile" : "lg"}
        />
      ))}
    </>
  ) : (
    iframeClientName === "game" && <CopyJoinLink />
  );

  return (
    <TableWrapper>
      <TableOuter>
        <TableItems>
          <PotWrapper>
            <CentralPot></CentralPot>
          </PotWrapper>
          <CardsWrapper>{innerTable}</CardsWrapper>
        </TableItems>
      </TableOuter>
    </TableWrapper>
  );
};

const PotWrapper = styled.div`
  display: flex;
  // top: 30px;
  cursor: pointer;
  position: absolute;
  width: auto;
  transform: translateY(-100%);
  top: -10px;
  justify-content: space-around;
`;

const TableWrapper = styled.div`
  height: 100%;
`;

const TableOuter = styled.div`
  position: absolute;
  width: 70%;
  height: 70%;
  left: 50%;
  top: 5%;
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  transform: translate(-50%);
  text-align: center;
  z-index: 0;
  background: ${({ theme }) => theme.table.inner};
  border: 10px solid ${({ theme }) => theme.table.outer};
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    top: 3%;
  }
`;

const TableItems = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const CardsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export default Table;
