import React, { useState } from "react";
import PlayerActions from "./PlayerActions";
import ThemePicker from "./themePicker";
import SocialMedia from "./SocialMedia";

const ActionsDropdown = ({
  queuedAction,
  emitSocketMessage,
  isStanding,
  setCurrentModal,
  isMobile,
  theme,
  setTheme,
  isInCents,
}) => {
  const [showThemePicker, setShowThemePicker] = useState(false);

  const [showSocialMedia, setShowSocialMedia] = useState(false);

  return (
    <>
      <PlayerActions
        show={!(showThemePicker || showSocialMedia)}
        queuedAction={queuedAction}
        emitSocketMessage={emitSocketMessage}
        isStanding={isStanding}
        setCurrentModal={setCurrentModal}
        isMobile={isMobile}
        setShowThemePicker={setShowThemePicker}
        setShowSocialMedia={setShowSocialMedia}
        isInCents={isInCents}
      />
      <ThemePicker
        show={showThemePicker}
        isMobile={isMobile}
        theme={theme}
        setTheme={setTheme}
        close={() => setShowThemePicker(false)}
      />
      <SocialMedia
        show={showSocialMedia}
        isMobile={isMobile}
        close={() => setShowSocialMedia(false)}
      />
    </>
  );
};

export default ActionsDropdown;
