import React, { Fragment, useContext } from "react";
import { BrowserContext } from "../../context/BrowserContext";
import Leaderboard from "./Leaderboard";
import PlayerActionsDropdown from "./PlayerActionsDropdown";
import BlindStructureWidget from "./BlindStructureWidget";
import FAQWidget from "../../shared/Widgets/FAQWidget";
import HandRankingsWidget from "../../shared/Widgets/HandRankingsWidget";
import SettingsWidget from "./SettingsWidget";
import ShowCardsToGodButton from "./ShowCardsToGodButton";
// import SocialIcons from "./SocialIcons";
import RandomSeatsButton from "./RandomSeatsButton";
import styled from "styled-components";
import { roles } from "../../helpers/constants";
import { formatCurrency } from "../../helpers/utils";
import { MediumHeaderText, SmallHeaderText } from "../../shared/Text";
import { GameContext } from "../../context/GameContext";

const FixedWidgets = ({
  emitSocketMessage,
  gameId,
  username,
  smallBlindCents,
  isTimerEnabled,
  timerLengthSeconds,
  handTimerLengthSeconds,
  activePlayers,
  isSpectating,
  isTournament,
  initialBlindCents,
  blindIncreaseMinutes,
}) => {
  const {
    isMobile,
    setAllowKeyboardShortcuts,
    setExistsModal,
    sound,
    setSound,
    showVideoChat,
    setShowVideoChat,
    videoIsOnRight,
    setVideoIsOnRight,
    betButtonsOnRight,
    setBetButtonsOnRight,
    joinCall,
    setJoinCall,
    setCurrentModal,
    theme,
    setTheme,
    isFourColorDeck,
    setIsFourColorDeck,
  } = useContext(BrowserContext);
  const {
    isGodModeEnabled,
    isGod,
    showCardsToGod,
    god,
    isInCents,
  } = useContext(GameContext);
  const clientPlayer = activePlayers.filter(
    (player) => player.playerName === username
  )[0];

  const adminPlayers = activePlayers.filter(
    (player) => player.playerRole === roles.ROLE_ADMIN
  );

  return (
    <Fragment>
      {!isSpectating && clientPlayer && (
        <PlayerActionsDropdown
          queuedAction={clientPlayer.queuedAction}
          emitSocketMessage={emitSocketMessage}
          isStanding={!clientPlayer.playerIsInGame}
          setCurrentModal={setCurrentModal}
          isMobile={isMobile}
          theme={theme}
          setTheme={setTheme}
          isInCents={isInCents}
        />
      )}
      {isSpectating && (
        <PlayerActionsDropdown
          setCurrentModal={setCurrentModal}
          isMobile={isMobile}
          theme={theme}
          setTheme={setTheme}
          isInCents={isInCents}
        />
      )}
      <HeaderWrapper isMobile={isMobile}>
        <MediumHeaderText style={{ textAlign: "left" }}>
          Lipoker {isGodModeEnabled ? "God Mode" : ""}
        </MediumHeaderText>
        <SmallHeaderText>{`Blinds: ${formatCurrency(
          smallBlindCents,
          isInCents
        )}/${formatCurrency(2 * smallBlindCents, isInCents)}`}</SmallHeaderText>
      </HeaderWrapper>
      <Leaderboard
        isAdmin={clientPlayer && clientPlayer.playerRole === roles.ROLE_ADMIN}
      />
      <WidgetWrapper>
        {isTournament && (
          <BlindStructureWidget
            isMobile={isMobile}
            setAllowKeyboardShortcuts={setAllowKeyboardShortcuts}
            setExistsModal={setExistsModal}
            initialBlindCents={initialBlindCents}
            blindIncreaseMinutes={blindIncreaseMinutes}
          />
        )}
        <HandRankingsWidget
          isMobile={isMobile}
          setAllowKeyboardShortcuts={setAllowKeyboardShortcuts}
          setExistsModal={setExistsModal}
          isFourColorDeck={isFourColorDeck}
          playerHand={clientPlayer ? clientPlayer.bestHand : null}
        />
        <FAQWidget
          isMobile={isMobile}
          setAllowKeyboardShortcuts={setAllowKeyboardShortcuts}
          setExistsModal={setExistsModal}
          isFourColorDeck={isFourColorDeck}
        />
        <SettingsWidget
          gameId={gameId}
          username={username}
          sound={sound}
          setSound={setSound}
          showVideoChat={showVideoChat}
          setShowVideoChat={setShowVideoChat}
          videoIsOnRight={videoIsOnRight}
          setVideoIsOnRight={setVideoIsOnRight}
          betButtonsOnRight={betButtonsOnRight}
          setBetButtonsOnRight={setBetButtonsOnRight}
          joinCall={joinCall}
          setJoinCall={setJoinCall}
          isMobile={isMobile}
          setAllowKeyboardShortcuts={setAllowKeyboardShortcuts}
          setExistsModal={setExistsModal}
          smallBlindCents={smallBlindCents}
          isTimerEnabled={isTimerEnabled}
          timerLengthSeconds={timerLengthSeconds} //    timer.DEFAULT_TIMER_SECONDS
          handTimerLengthSeconds={handTimerLengthSeconds} //    timer.MEDIUM_HAND_TIMER_SECONDS
          isFourColorDeck={isFourColorDeck}
          setIsFourColorDeck={setIsFourColorDeck}
          isSpectating={isSpectating}
          isTournament={isTournament}
          isAdmin={clientPlayer && clientPlayer.playerRole === roles.ROLE_ADMIN}
          isInCents={isInCents}
          adminPlayers={adminPlayers}
        />
      </WidgetWrapper>
      <ShowCardsToGodButton
        emitSocketMessage={emitSocketMessage}
        isGodModeEnabled={isGodModeEnabled}
        isGod={isGod}
        showCardsToGod={showCardsToGod}
        god={god}
        isSpectating={isSpectating}
      />
      <RandomSeatsButton
        emitSocketMessage={emitSocketMessage}
        isSpectating={isSpectating}
      />
    </Fragment>
  );
};

// a temporary fix, I think the positioning of all of these elements should be refactored to not be absolute positioned
const WidgetWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
`;

const HeaderWrapper = styled.div`
  position: absolute;
  ${(props) =>
    props.isMobile ? "top: 5px; left: 13px;" : "top: 5px; left: 20px;"};
  color: ${({ theme }) => theme.secondary};
`;

export default FixedWidgets;
