import React from "react";
import styled from "styled-components";
import { colors, font } from "../../../helpers/styles";
import { isInvalidOrEmpty } from "../../../helpers/utils";
import {
  removeNumberCommas,
  insertNumberCommas,
} from "../../../helpers/calculations";
import { InputText, InputField } from "../../../shared/FormElements";
import { SmallHeaderText } from "../../../shared/Text";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../../helpers/constants";

const GameSettingsChoice = ({
  setSmallBlindCents,
  setBigBlindCents,
  smallBlindCents,
  bigBlindCents,
  isInCents,
  setIsInCents,
}) => {
  const onSmallBlindChange = (e) => {
    const val = removeNumberCommas(e.target.value);
    if (!isInvalidOrEmpty(val)) {
      setSmallBlindCents(val);
      setBigBlindCents(val * 2);
    }
  };

  return (
    <ChoiceWrapper>
      <BlindsWrapper>
        <SmallBlind
          id="small-blind"
          data-testid="small-blind"
          onChange={onSmallBlindChange}
          value={insertNumberCommas(smallBlindCents.toString())}
          type="text"
          placeholder="5c"
          modal
        />
      </BlindsWrapper>
      <BigBlindsWrapper>
        <SmallHeaderText>
          /{bigBlindCents ? insertNumberCommas(bigBlindCents.toString()) : "10"}{" "}
        </SmallHeaderText>
        <CentsSwitch isInCents={isInCents} onClick={() => setIsInCents(true)}>
          <SmallHeaderText>cents</SmallHeaderText>
        </CentsSwitch>
        <ChipsSwitch isInCents={isInCents} onClick={() => setIsInCents(false)}>
          <SmallHeaderText>chips</SmallHeaderText>
        </ChipsSwitch>
      </BigBlindsWrapper>
    </ChoiceWrapper>
  );
};

const ChoiceWrapper = styled.div`
  display: flex;
  border-radius: 5px;
  overflow: hidden;
`;

const BlindsWrapper = styled(InputField)`
  margin-right: -5px;
  text-align: auto;
  width: auto;
`;

const BigBlindsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  padding: 0 8px;
  text-align: left;
  color: ${colors.grey};
`;

const SmallBlind = styled(InputText)`
  width: 130px;
  border-radius: 0;
  text-align: right;
  font-family: ${font.header};
  letter-spacing: 1px;
  font-size: 18px;
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    width: 50px;
    margin: 0;
  }
`;

const CentsSwitch = styled.div`
  margin-left: 4px;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.foreground};
  padding: 3px 4px;
  opacity: 30%;
  cursor: pointer;
  ${(props) =>
    props.isInCents &&
    `
    background: ${props.theme.foreground};
    opacity: 100%;
  `}
`;

const ChipsSwitch = styled.div`
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.foreground};
  padding: 3px 4px;
  opacity: 30%;
  cursor: pointer;
  ${(props) =>
    !props.isInCents &&
    `
    background: ${props.theme.foreground};
    opacity: 100%;
  `}
`;

export default GameSettingsChoice;
