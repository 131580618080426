import React from "react";

const Spade = ({ color = "#000000", style }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
      xmlSpace="preserve"
      style={style}
    >
      <g>
        <path
          style={{ fill: color }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M539.206,698.188C582.13,839.098,665.84,933.418,793.353,990.945
          c-2.023,2.689-4.045,5.382-6.066,8.071c-190.525,0-381.049,0-571.569,0c-2.658-3.003-5.319-6.009-7.977-9.022
          c130.853-58.805,212.088-158.082,250.684-282.886c-41.617,20.021-84.912,52.1-132.734,61.756
          C182.555,797.782,63.737,687.195,70.706,539.957c4.203-88.788,36.494-167.404,86.603-238.896
          C241.963,180.294,351.531,85.949,476.804,9.637c11.121-6.776,33.158-8.161,43.607-1.623
          c142.94,89.365,270.306,195.942,353.42,345.902c42.179,76.104,67.191,157.795,50.558,247.018
          c-27.548,147.793-204.184,222.562-328.633,138.841C579.111,728.583,563.374,716.021,539.206,698.188z"
        />
      </g>
    </svg>
  );
};

export default Spade;
