import React, { useState } from "react";
import ActionBar from "../../../shared/ActionBar";
import {
  mobileBreakpoint,
  mobileHeightBreakpoint,
} from "../../../helpers/constants";
import { Icon } from "../../../shared/FormElements";
import {
  faSync,
  faBan,
  faCoins,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { colors, font } from "../../../helpers/styles";
import { formatCurrency } from "../../../helpers/utils";
import styled from "styled-components";

const actionBarPositioning = {
  position: "absolute",
  height: "auto",
  top: "-15px",
  right: 0,
  transform: "translate(50%,-100%)",
};

const BuyInActionBar = ({
  emitSocketMessage,
  name,
  pos,
  requestedBuyIn,
  pendingBuyIn,
  isInCents,
}) => {
  const [showActionBar, setShowActionBar] = useState(false);
  const [actionBarHoverTimeout, setActionBarHoverTimeout] = useState(null);
  const [isApproved, setApproveBuyIn] = useState(false);

  const handleMouseEnter = () => {
    clearTimeout(actionBarHoverTimeout);
    setShowActionBar(true);
  };

  const handleMouseLeave = () => {
    setActionBarHoverTimeout(setTimeout(() => setShowActionBar(false), 1400));
  };

  const approveBuyIn = () => {
    setApproveBuyIn(true);
    setShowActionBar(false);
    emitSocketMessage("approve_buy_in", {
      name: name,
      amount: parseInt(requestedBuyIn),
    });
  };

  const declineBuyIn = () => {
    setApproveBuyIn(true);
    setShowActionBar(false);
    emitSocketMessage("approve_buy_in", {
      name: name,
      amount: parseInt(pendingBuyIn),
    });
  };

  const actionsList = [
    {
      name: "Accept",
      icon: faSync,
      inner: (
        <>
          {pendingBuyIn > 0 ? (
            <Icon icon={faSync} color={colors.white} size="xs" />
          ) : (
            "+"
          )}
          <BuyInWrapper>
            {formatCurrency(requestedBuyIn, isInCents)}
          </BuyInWrapper>
        </>
      ),
      dataTestId: `accept-buyin${pos}`,
      onClick: () => approveBuyIn(),
    },
    {
      name: "Decline",
      icon: faBan,
      inner: <Icon icon={faBan} color={colors.white} size="xs" />,
      dataTestId: `decline-buyin${pos}`,
      onClick: () => declineBuyIn(),
    },
  ];

  console.log("BuyInActionBar", name, pos, requestedBuyIn, pendingBuyIn)
  return (
    <>
      <ActionBarToggleButton
        onClick={() => setShowActionBar(!showActionBar)}
        onMouseEnter={handleMouseEnter}
        selected={showActionBar}
        data-testid={`buyin-toggle${pos}`}
      >
        {!isInCents && <Icon icon={faCoins} color={colors.white} size="xs" />}
        {isInCents && (
          <Icon icon={faDollarSign} color={colors.white} size="xs" />
        )}
      </ActionBarToggleButton>
      <ActionBar
        show={showActionBar}
        actionsList={actionsList}
        style={actionBarPositioning}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
      />
    </>
  );
};

const ActionBarToggleButton = styled.div`
  width: 27px;
  height: 27px;
  font-family: ${font.header};
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.tertiary};
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  font-weight: bold;
  border: 1px solid ${colors.lightGrey2};
  ${(props) =>
    props.selected &&
    `
    background: ${colors.grey};
    color: ${colors.white};
  `}
  @media screen and (max-width: ${mobileBreakpoint}),
    screen and (max-height: ${mobileHeightBreakpoint}) {
    width: 16px;
    height: 16px;
    font-size: 0.5em;
  }
  user-select: none;
`;

const BuyInWrapper = styled.div`
  margin-left: 1.5px;
`;

export default BuyInActionBar;
