import React, { Fragment, useState, useEffect } from "react";
import { sounds } from "../../helpers/constants";

const AllAudio = ({ setAudioToSoundMap }) => {
  const [audioInitialized, setAudioInitialized] = useState(false);

  let callAudio;
  let checkAudio;
  let dingAudio;
  let foldAudio;
  let raiseAudio;
  let winAudio;

  const _audioToSoundMap = {
    [sounds.CALL]: callAudio,
    [sounds.CHECK]: checkAudio,
    [sounds.DING]: dingAudio,
    [sounds.FOLD]: foldAudio,
    [sounds.RAISE]: raiseAudio,
    [sounds.WIN]: winAudio,
  };

  useEffect(() => {
    // initialize each sound
    Object.keys(_audioToSoundMap).map((soundName) => {
      _audioToSoundMap[soundName] = new Audio(
        `${process.env.PUBLIC_URL}/assets/sounds/${soundName}.mp3`
      );
    });

    // the first time the user does an interaction, "mount/unlock" the audio
    // to get around the fact that certain browsers require user interaction
    // to auto-play audio
    document.addEventListener("click", unlockAudio);

    return () => {
      document.removeEventListener("click", (e) => unlockAudio(e));
    };
  }, []);

  const unlockAudio = async (e) => {
    e.preventDefault();
    setTimeout(() => {
      if (!audioInitialized) {
        Object.keys(_audioToSoundMap).map((soundName) => {
          // play and immediately stop
          const audio = _audioToSoundMap[soundName];
          audio.volume = 0;
          audio.play();
          audio.pause();
          audio.volume = 1;
          audio.currentTime = 0;
        });
        setAudioToSoundMap(_audioToSoundMap);
        setAudioInitialized(true);
        document.removeEventListener("click", unlockAudio);
      }
    }, 0);
  };

  return <Fragment />;
};

export default AllAudio;
