import React, { useState, useContext, useEffect } from "react";
import { GameContext } from "../../context/GameContext";
import { BrowserContext } from "../../context/BrowserContext";
import ActionButtons from "./ActionButtons";
import styled from "styled-components";
import Table from "../../shared/Table";
import { mobileBreakpoint } from "../../helpers/constants";
import { PrimaryButton } from "../../shared/FormElements";
import { emit } from "../../helpers/socket";

const header = ["name", "stack", "game id"];

const MTTLobby = ({ data, getPlayers, MTTUsers }) => {
  const { username, MTTId, MTTOngoing } = useContext(GameContext);
  console.log("MTT Ongoing", MTTOngoing);
  const { isMobile } = useContext(BrowserContext);

  const onUserClick = (index) => {
    const gameId = data[index].metadata.gameId;
    const currentURL = window.location.href;
    window.location.href = `${currentURL}/game/${gameId}?username=${username}&autojoin=true&spectate=true`;
  };

  const onStartClick = () => {
    emit("start_mtt_round", {
      mtt_id: MTTId,
    });
  };
  const clientUser = MTTUsers.filter((user) => user.userName === username)[0];
  const MTTAdminUser = MTTUsers.filter(
    (user) => user.userRole === "ROLE_MTT_ADMIN"
  )[0];

  return (
    <LobbyOuter>
      <LobbyWrapper>
        <Table
          header={header}
          rowData={data}
          italicizeCols={[]}
          emptyText={"No active players right now."}
          onRowClick={onUserClick}
          isMobile={isMobile}
          getRows={getPlayers}
          isMTTAdmin={clientUser && clientUser.userRole === "ROLE_MTT_ADMIN"}
        />
      </LobbyWrapper>
      <ActionButtons clientUser={clientUser} />
    </LobbyOuter>
  );
};

const LobbyOuter = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  display: flex;
`;

const LobbyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  padding-left: 50px;
  @media screen and (max-width: ${mobileBreakpoint}) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0;
    padding-top: 100px;
    height: 95%;
  }
`;

export default MTTLobby;
