import React, { useEffect } from "react";
import styled from "styled-components";
import { MediumHeaderText } from "../Text";
import { font, gradientScrollStyles } from "../../helpers/styles";
import { mobileBreakpoint } from "../../helpers/constants";
import RefreshButton from "./RefreshButton";
import Loading from "../Image/Loading";
import Card from "../Card";

const Table = ({
  isMobile,
  header,
  rowData,
  italicizeCols,
  emptyText,
  onRowClick,
  selectedRow,
  getRows,
  isMTTAdmin,
}) => {
  useEffect(() => {
    getRows();
  }, []);

  return (
    <TableOuter>
      <TableWrapper>
        <TableMain>
          <TableHeader>
            <tr>
              {header.map((item, i) => (
                <TableHeaderCell key={`header-${i}`}>{item}</TableHeaderCell>
              ))}
              <TableHeaderCell style={{ width: "10px" }}>
                <RefreshButton refreshAction={getRows} />
              </TableHeaderCell>
            </tr>
          </TableHeader>
          <TableBody>
            {rowData &&
              rowData.map((row, i) => {
                console.log(Object.keys(row));
                return (
                  <TableRow
                    key={`row-${i}`}
                    onClick={() => onRowClick(i)}
                    selected={selectedRow === i}
                  >
                    {header.map((colName, j) => (
                      <TableCell
                        key={`row-${i}-item-${j}`}
                        italicize={italicizeCols.includes(j)}
                      >
                        {colName === "game id" && !row[colName]
                          ? "Not in game"
                          : row[colName]}
                      </TableCell>
                    ))}
                    <TableCell key={`row-${i}-item-${row.length}`}>
                      {isMTTAdmin && "EDIT"}
                    </TableCell>
                    {/* Empty column for refresh button */}
                  </TableRow>
                );
              })}
          </TableBody>
        </TableMain>
        {(rowData === null || rowData.length === 0) && (
          <EmptyText>
            {rowData === null && <Loading size="lg" />}
            {rowData && rowData.length === 0 && (
              <>
                <div style={{ display: "flex" }}>
                  <Card
                    suit="heart"
                    number="2"
                    size={isMobile ? "mobile" : "lg"}
                    isFlat
                  />
                  <Card
                    suit="spade"
                    number="7"
                    size={isMobile ? "mobile" : "lg"}
                    isFlat
                  />
                </div>
                <MediumHeaderText>{emptyText}</MediumHeaderText>
              </>
            )}
          </EmptyText>
        )}
      </TableWrapper>
    </TableOuter>
  );
};

const TableOuter = styled.div`
  position: relative;
  @media screen and (max-width: ${mobileBreakpoint}) {
    width: 90%;
    height: 100%;
  }
`;

const TableWrapper = styled.div`
  position: relative;
  width: 50vw;
  height: -webkit-calc(100vh - 200px);
  height: -moz-calc(100vh - 200px);
  height: -o-calc(100vh - 200px);
  height: calc(100vh - 200px);
  border-radius: 5px;
  overflow: scroll;
  ${(props) =>
    props.theme &&
    `
    ${gradientScrollStyles("rgba(0,0,0,0.3)", props.theme.secondary)}
    background-color: ${props.theme.secondary};
    border-bottom: 1px solid ${props.theme.secondary};
  `}
  box-shadow: ${({ theme }) => theme.boxShadow};
  @media screen and (max-width: ${mobileBreakpoint}) {
    width: 100%;
    height: 100%;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const TableMain = styled.table`
  color: white;
  font-family: ${font.body};
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.thead``;

const TableHeaderCell = styled.th`
  background: ${({ theme }) => theme.primary};
  z-index: 2;
  top: 0;
  left: 0;
  position: sticky;
  padding: 8px 16px;
  padding-top: 12px;
  font-weight: bold;
  text-transform: uppercase;
`;

const TableBody = styled.tbody`
  display: table-row-group;
`;

const TableCell = styled.td`
  padding: 8px 16px;
  ${(props) => props.italicize && `font-style: italic;`}
`;

const TableRow = styled.tr`
  transform: scale(1); /* position: relative doesn't work in tr, workaround */
  border: 1px dashed ${({ theme }) => theme.secondaryLighter};
  cursor: pointer;
  ${(props) =>
    props.selected &&
    props.theme &&
    `
    background: ${props.theme.secondaryLighter};
  `}
  &:hover {
    background: ${({ theme }) => theme.secondaryLighter};
  }
`;

const EmptyText = styled.div`
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -45px;
  flex-direction: column;
`;

export default Table;
